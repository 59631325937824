import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material';

const CustomIcon = styled(EditIcon)(() => ({
  fontSize: '20px',
  color: 'grey',
  '@media print': {
    display: 'none',
  },
}));

export const CustomEditIcon = () => {
  return <CustomIcon />;
};
