import { DailyCheckInReason } from 'types';

export const dailyCheckInReason: DailyCheckInReason[] = [
  { name: 'Social media', icon: '' },
  { name: 'Family', icon: '' },
  { name: 'Work', icon: '' },
  { name: 'Sleep', icon: '' },
  { name: 'Finance', icon: '' },
  { name: 'Friends', icon: '' },
  { name: 'Weather', icon: '' },
  { name: 'Health', icon: '' },
  { name: 'Partner', icon: '' },
  { name: 'The news', icon: '' },
  { name: 'Music', icon: '' },
  { name: 'Traveling', icon: '' },
  { name: 'Hobbies', icon: '' },
  { name: 'Self-image', icon: '' },
  { name: 'Other', icon: '' },
];
