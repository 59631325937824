import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  error: string;
  minHeight?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookieAlert: {
      '& .MuiAlert-icon': {
        fontSize: 65,
      },
    },
  }),
);

export const ErrorAlert: React.FC<Props> = ({ error, minHeight = '50vh' }) => {
  const classes = useStyles();
  const { t } = useTranslation('errors');
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight={minHeight}>
      <Alert
        severity="error"
        className={classes.cookieAlert}
        sx={{ fontSize: 20, borderRadius: 5 }}
      >
        <AlertTitle sx={{ fontSize: 20 }}>{t('error')}</AlertTitle>
        {error}
      </Alert>
    </Box>
  );
};
