import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CustomLoader } from 'components';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import { nuggetTypeData, NUGGET_TYPES } from 'constants/mock';
import { createNugget, fetchNuggets, updateNugget } from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { IGoal, Nugget, NuggetType } from 'types';

type CreateParams = { id: string };

export const CreateNugget: React.FC = () => {
  const { nuggets, loading, error } = useSelector(createSessionSelector);
  const { goalsTypes } = useSelector(goalsSliceSelector);
  const { id } = useParams<CreateParams>();
  const { t } = useTranslation('createNugget');

  const isEditMode = Number.isInteger(parseInt(id));
  const selectedNugget = nuggets.results.find((nugget) => nugget.id === parseInt(id));

  useEffect(() => {
    if (!selectedNugget && isEditMode) dispatch(fetchNuggets({ id: parseInt(id) }));
  }, [id]);

  const schema = yup
    .object({
      goal: yup.object().shape({ name: yup.string().required() }).required(),
      description: yup.string().required(),
      type: yup.string().required(),
    })
    .required();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Nugget>({
    defaultValues: {
      id: undefined,
      description: '',
      goal: { id: 0, name: '', focusArea: 'COMMUNICATIONS' },
      type: 'INSPIRATIONAL_STORY',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEditMode) {
      if (selectedNugget) {
        const { id, description, goal, type } = selectedNugget;
        setValue('id', id);
        setValue('description', description);
        setValue('goal', goal);
        setValue('type', type);
      }
    }
  }, [id, isEditMode, nuggets, selectedNugget, setValue]);

  const onSubmit = handleSubmit((data: Nugget) => {
    const newNugget = {
      goal: data.goal.id,
      description: data.description,
      type: data.type,
    };
    if (isEditMode) {
      dispatch(updateNugget({ id, nugget: newNugget }));
    } else {
      dispatch(createNugget(newNugget));
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, loading]);

  if (loading) return <CustomLoader />;

  if (error) return <ShowError error={error} />;
  if (isEditMode && !selectedNugget) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Typography data-testid="titleCreateNugget" variant="h2" gutterBottom>
          {isEditMode ? t('editNugget') : t('createNugget')}
        </Typography>
        <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
          <InputLabel id="description-label">{t('description')}</InputLabel>
          <TextareaAutosize
            aria-label="description"
            minRows={4}
            {...register('description')}
            placeholder={t('description')}
            style={{
              width: '100%',
              fontSize: '16px',
              padding: '6px 20px 7px 20px',
              fontFamily: 'MarkPro',
            }}
          />
          <FormHelperText error>{errors.description?.message}</FormHelperText>
        </Box>
        <FormControl fullWidth margin="normal">
          <InputLabel id="type-label">{t('type')}</InputLabel>
          <Controller
            control={control}
            name="type"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const id = e.target.value;
                    setValue('type', id as NuggetType);
                  }}
                  defaultValue={value}
                  value={value}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="type-select-label"
                  id="type-select"
                >
                  {NUGGET_TYPES.map((nugget) => (
                    <MenuItem key={nugget.name} value={nugget.name}>
                      {nuggetTypeData[nugget.name].label}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>{errors.type?.message}</FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="goal-label">{t('createSession:goal')}</InputLabel>
          <Controller
            control={control}
            name="goal"
            render={({ field: { value } }) => {
              return (
                <Select
                  data-testid="pathwaySelectInputTip"
                  onChange={(e) => {
                    const id = e.target.value;
                    const selectedGoal = goalsTypes.results.find((goal) => goal.name === id);
                    setValue('goal', selectedGoal as IGoal);
                  }}
                  defaultValue={value?.name}
                  value={value?.name}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="goal-select-label"
                  id="goal-select"
                >
                  {goalsTypes.results.map((goal) => (
                    <MenuItem key={goal.id} value={goal.name}>
                      {goal.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>
            {errors.goal && t('createSession:goal') + t('createSession:isRequired')}
          </FormHelperText>
        </FormControl>

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button
            data-testid="submitButtonCreateNugget"
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginLeft: '20px' }}
          >
            {t(isEditMode ? 'updateNugget' : 'createNugget')}
          </Button>
        </Box>
      </form>
    </div>
  );
};
