import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import React from 'react';
import { CellProps } from 'react-table';

import { pillCategoriesData } from 'constants/mock';
import { IPill } from 'types';
import { secondsToHms } from 'utils/helperFunctions';

export const PILLS_COLUMN = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '10%',
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '60%',
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: '10%',
    Cell: (props: CellProps<IPill>): string =>
      pillCategoriesData[props.row.original.category].label,
  },
  {
    Header: 'Duration',
    accessor: 'duration',
    width: '10%',
    Cell: (props: CellProps<IPill>): string => secondsToHms(props.row.original.duration),
  },

  {
    Header: 'Options',
    accessor: 'test',
    width: '10%',
    Cell: (props: CellProps<IPill>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
          onClick={() => props.onEdit(id)}
        >
          <EditIcon fontSize="large" />
        </Button>
      );
    },
  },
];
