import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  BadgesCard,
  CurrentGoalsCard,
  FullPageLoader,
  PersonalityChart,
  SessionCard,
  TrophiesCard,
  UserProfile,
  WeekStreak,
} from 'components';
import { fetchUserBadges } from 'redux/badges/badgesActions';
import {
  fetchUserDailyTracking,
  fetchUserProfiles,
  fetchUserQualities,
  fetchUser,
} from 'redux/users/usersActions';
import { usersSelector } from 'redux/users/usersSlice';
import { fetchUserSessions } from 'redux/userSessions/UserSessionsAction';
import { userSessionsSelector } from 'redux/userSessions/UserSessionsSlice';
import { fetchUsersGoal } from 'redux/usersGoals/userGoalsActions';
import { userGoalsSelector } from 'redux/usersGoals/userGoalsSlice';
import { MainWrapper } from 'wrappers';

type UserDetailsPageParams = { id: string };

const useStyles = makeStyles({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '350px 1fr 300px 300px',
    gridTemplateRows: ' 150px 300px  1fr ',
    gap: '20px',
    maxWidth: '1600px',
    margin: '0 auto',
    padding: '0 40px',
  },
  card: {
    margin: '20px 0',
  },
  details: {
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
  },
});

export const UserDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams<UserDetailsPageParams>();
  const { isLoading } = useSelector(userGoalsSelector);
  const { isLoading: sessionLoading } = useSelector(userSessionsSelector);
  const { t } = useTranslation(['errors', 'weeklyStreak']);
  const { user, userDailyTracking, isLoading: userLoading } = useSelector(usersSelector);

  const numberID = Number(id);
  const activeUser = user[numberID];

  const { currentStreak, currentWeekStreak } = userDailyTracking.slice(-1)?.[0] || [];

  useEffect(() => {
    if (!activeUser) {
      batch(() => {
        dispatch(fetchUsersGoal(id));
        dispatch(fetchUserSessions(id));
        dispatch(fetchUser({ id }));
        dispatch(fetchUserBadges(id));
        dispatch(fetchUserProfiles({ id: numberID }));
        dispatch(fetchUserDailyTracking(id));
        dispatch(fetchUserQualities(id));
      });
    }
  }, [dispatch, numberID, user, id]);

  if (isLoading || sessionLoading || userLoading) {
    return <FullPageLoader />;
  }

  return (
    <MainWrapper>
      {!activeUser ? (
        <div>{t('noData')}</div>
      ) : (
        <div className={classes.wrapper}>
          <UserProfile activeUser={activeUser} />
          {/* <UserOptions /> */}
          <CurrentGoalsCard />
          <SessionCard />
          <TrophiesCard />
          <BadgesCard />
          <Box sx={{ gridColumn: '4 / 5', gridRow: '2 / 3' }}>
            <WeekStreak currentStreak={currentWeekStreak} title={t('weeklyStreak:weekly')} />
          </Box>
          <Box sx={{ gridColumn: '3 / 4', gridRow: '2 / 3' }}>
            <WeekStreak currentStreak={currentStreak} title={t('weeklyStreak:daily')} />
          </Box>
          <PersonalityChart />
        </div>
      )}
    </MainWrapper>
  );
};
