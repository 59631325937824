import { Theme } from '@material-ui/core';
import { merge } from 'lodash';

import Button from './Button';
import Card from './Card';
import Drawer from './Drawer';
import FormHelperText from './FormHelperText';
import Input from './Input';
import ListItem from './ListItem';

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    Input(theme),
    Drawer(theme),
    ListItem(theme),
    Card(theme),
    FormHelperText(theme),
  );
}
