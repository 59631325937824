import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Alert, AlertColor } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookieAlert: {
      '& .MuiAlert-icon': {
        fontSize: 30,
      },
      '& .MuiAlert-message': {
        fontSize: 18,
      },
      width: '650px',
    },
  }),
);

type Props = {
  description: string;
  severity?: AlertColor;
};

export const CustomAlert: React.FC<Props> = ({ description, severity = 'warning' }) => {
  const classes = useStyles();

  return (
    <div>
      <Alert className={classes.cookieAlert} severity={severity}>
        {description}
      </Alert>
    </div>
  );
};
