import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authorizeGoogle, refreshGoogleToken, signInUser } from './userAuthActions';
import { RootState } from 'redux/store';

export interface IUserAuthState {
  username: string;
  isFetching: boolean;
  isSingIn: boolean;
  errorMessage: string;
  token: string;
  googleToken: string;
  googleRefresh: string;
}

const initialState: IUserAuthState = {
  username: '',
  isFetching: false,
  isSingIn: false,
  errorMessage: '',
  token: '',
  googleToken: '',
  googleRefresh: '',
};

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    clearState: () => initialState,
    setGoogleToken(state, action: PayloadAction<string>) {
      state.googleToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.isFetching = true;
        state.errorMessage = '';
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.token = action.payload.token;
        state.isFetching = false;
        state.isSingIn = true;
      })
      .addCase(signInUser.rejected, (state, action: any) => {
        state.errorMessage = action.payload.message;
        state.isFetching = false;
      })
      .addCase(refreshGoogleToken.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(refreshGoogleToken.fulfilled, (state, action) => {
        state.googleToken = action.payload.access_token;
        state.isFetching = false;
      })
      .addCase(refreshGoogleToken.rejected, (state, action: any) => {
        state.googleToken = '';
        state.googleRefresh = '';
        state.errorMessage = action.payload;
        state.isFetching = false;
      })
      .addCase(authorizeGoogle.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(authorizeGoogle.fulfilled, (state, action) => {
        state.googleToken = action.payload.access_token;
        state.googleRefresh = action.payload.refresh_token;
        state.isFetching = false;
      })
      .addCase(authorizeGoogle.rejected, (state, action: any) => {
        state.googleToken = '';
        state.googleRefresh = '';
        state.errorMessage = action.payload;
        state.isFetching = false;
      });
  },
});

export const { clearState, setGoogleToken } = userAuthSlice.actions;

export const userAuthSelector = (state: RootState) => state.userAuth;
export default userAuthSlice.reducer;
