import axios from 'axios';
import storage from 'redux-persist/lib/storage';

import { APP_URL } from 'constants/auth';
import { ERROR_GOOGLE_UNAUTHENTICATED } from 'constants/errors';
import { store } from 'redux/store';
import { refreshGoogleToken } from 'redux/userAuth/userAuthActions';
import { clearState, setGoogleToken } from 'redux/userAuth/userAuthSlice';
import { GoogleApiResponse } from 'types';

export const usersAPI = axios.create({
  baseURL: APP_URL,
});

export const postRefreshToken = async (refreshToken: string) => {
  const response = await axios.post(`${APP_URL}/api/token/refresh`, {
    refreshToken,
  });
  return response;
};

usersAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      const loggedUser = JSON.parse(token);

      config.headers.authorization = `Bearer ${loggedUser.token}`;
    }
    return config;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  (error) => {},
);

usersAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const state = store().getState();
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if ((error.response.data.error.message = ERROR_GOOGLE_UNAUTHENTICATED)) {
        if (!state.userAuth.googleRefresh) {
          localStorage.removeItem('token');
          storage.removeItem('persist:root');
          store().dispatch(clearState());
          return;
        }
        const refreshToken = await store().dispatch(
          refreshGoogleToken({
            refresh_token: state.userAuth.googleRefresh,
          }),
        );

        if (refreshToken.meta.requestStatus === 'fulfilled') {
          const access_token = (refreshToken.payload as GoogleApiResponse).access_token;
          store().dispatch(setGoogleToken(access_token));
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          return usersAPI(originalRequest);
        }
        if (refreshToken.meta.requestStatus === 'rejected') {
          localStorage.removeItem('token');
          storage.removeItem('persist:root');
          store().dispatch(clearState());
          return;
        }
        return;
      }
      const token = JSON.parse(localStorage.getItem('token') || '{}');

      return postRefreshToken(token.refreshToken)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('token', JSON.stringify(res.data));

            return usersAPI(originalRequest);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem('token');
          }
        });
    }
    return Promise.reject(error);
  },
);
