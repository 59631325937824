import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions, FormControl, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FONT_SIZE_PRIMARY } from 'theme/typography';
import * as yup from 'yup';

import { CustomLoader, Tag } from 'components';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import {
  createInsight,
  createInsightLabel,
  fetchInsightLabels,
  fetchInsights,
  updateInsight,
} from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { AppDispatch } from 'redux/store';
import { Insight, InsightLabel } from 'types';

type CreateParams = { id: string };

const filter = createFilterOptions<InsightLabel>();

export const CreateInsight: React.FC = () => {
  const {
    insights,
    insightLabels,
    error,
    loading: companiesLoading,
  } = useSelector(companiesSelector);

  const { id } = useParams<CreateParams>();
  const selectedInsight = insights.results.find((insight) => insight.id === parseInt(id));
  const { t } = useTranslation('insights');
  const loading = companiesLoading.createInsight || companiesLoading.updateInsight;

  const isEditMode = Number.isInteger(parseInt(id));

  useEffect(() => {
    if (!selectedInsight && isEditMode) dispatch(fetchInsights({ id: parseInt(id) }));
    dispatch(fetchInsightLabels({ pagination: false }));
  }, [id]);

  const schema = yup
    .object({
      labels: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number(),
            label: yup.string().required(),
          }),
        )
        .test('length', (val) => {
          return !!(val && val.length > 0);
        }),
      note: yup.string().required(),
    })
    .required();

  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Insight>({
    defaultValues: { id: undefined, note: '', labels: [] },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEditMode && selectedInsight) {
      const { id, note, labels } = selectedInsight;
      setValue('id', id);
      setValue('note', note);
      setValue('labels', labels);
    }
  }, [id, isEditMode, selectedInsight, setValue]);

  const onSubmit = handleSubmit((data: Insight) => {
    if (isEditMode) {
      dispatch(updateInsight(data));
    } else {
      dispatch(createInsight(data));
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, loading]);

  if (loading) return <CustomLoader />;

  if (error) return <ShowError error={error} />;
  if (isEditMode && !selectedInsight) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" gutterBottom>
          {isEditMode ? t('editInsight') : t('addInsight')}
        </Typography>
        <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
          <InputLabel id="insight-select-label">{t('insight')}</InputLabel>
          <TextareaAutosize
            aria-label="insight"
            minRows={4}
            {...register('note')}
            placeholder={t('insight')}
            style={{
              width: '100%',
              fontSize: '16px',
              padding: '6px 20px 7px 20px',
              fontFamily: 'MarkPro',
            }}
          />
          <FormHelperText error>{errors.note?.message}</FormHelperText>
        </Box>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-label">{t('labels')}</InputLabel>

          <Controller
            control={control}
            name="labels"
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  multiple
                  id="labels"
                  onChange={async (event, newValue, reason) => {
                    const length = newValue.length - 1;
                    const mappedValue = await Promise.all(
                      newValue.map(async (item, index) => {
                        if (item.inputValue) {
                          const lastItem = index === length;
                          if (lastItem && reason !== 'removeOption' && reason !== 'clear') {
                            const data = await dispatch(
                              createInsightLabel(item.inputValue),
                            ).unwrap();
                            return { ...item, id: data.id, label: item.inputValue };
                          }
                          return { ...item, label: item.inputValue };
                        } else {
                          return item;
                        }
                      }),
                    );
                    setValue('labels', mappedValue);
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.label);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        id: 0,
                        label: `Add "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={insightLabels}
                  getOptionLabel={(option) => {
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Tag {...getTagProps({ index })} label={option.label} />
                    ))
                  }
                  renderOption={(props, option) => (
                    <li style={{ fontSize: FONT_SIZE_PRIMARY }} {...props}>
                      {option.label}
                    </li>
                  )}
                  sx={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} error={!!errors.labels} />}
                />
              );
            }}
          />
          <FormHelperText error>{errors.labels && t('labelRequired')}</FormHelperText>
        </FormControl>

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
            {t(isEditMode ? 'editInsight' : 'addInsight')}
          </Button>
        </Box>
      </form>
    </div>
  );
};
