import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextareaAutosize,
  TextField,
  Typography,
  Select,
} from '@material-ui/core';
import { FormControl, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CropImage, CustomLoader, DragAndDrop, HelpIcon } from 'components';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import { REWARD_STATUS } from 'constants/mock';
import {
  createExternalReward,
  fetchExternalRewards,
  updateExternalReward,
  uploadRewardLogo,
} from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { ExternalReward, RewardStatus } from 'types';
import { isValidUrl, removeImgExtension } from 'utils';

type CreateParams = { id: string };

export const CreateReward: React.FC = () => {
  const { externalRewards, loading, error } = useSelector(createSessionSelector);
  const { id } = useParams<CreateParams>();
  const selectedReward = externalRewards.results.find((reward) => reward.id === parseInt(id));
  const { t } = useTranslation('createReward');

  const isEditMode = Number.isInteger(parseInt(id));

  const schema = yup
    .object({
      name: yup.string().required(),
      description: yup.string().required(),
      link: yup
        .string()
        .required()
        .test('is-url-valid', t('validation:invalidUrl'), (value) => {
          return value ? isValidUrl(value) : false;
        }),
      logo: yup.string().required(),
    })
    .required();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    setValue,
    setError,
    control,
    clearErrors,
    watch,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<ExternalReward & { isEditMode: boolean; file: string }>({
    defaultValues: {
      id: undefined,
      name: '',
      description: '',
      logo: '',
      link: '',
      isEditMode: false,
      file: '',
      status: 'active',
    },
    resolver: yupResolver(schema),
  });

  const supportedTypes = ['png'];
  const formValue = watch();

  useEffect(() => {
    if (!selectedReward && isEditMode) dispatch(fetchExternalRewards({ id: parseInt(id) }));
  }, [id]);

  useEffect(() => {
    if (isEditMode) {
      if (selectedReward) {
        const { id, name, description, logo, link, status } = selectedReward;
        setValue('id', id);
        setValue('name', name);
        setValue('description', description);
        setValue('logo', logo);
        setValue('link', link);
        setValue('status', status);
        setValue('isEditMode', true);
      }
    }
  }, [id, isEditMode, selectedReward, setValue]);

  const onSubmit = handleSubmit((data: ExternalReward & { isEditMode: boolean; file: string }) => {
    if (isEditMode) {
      const { file, logo, id } = data;
      dispatch(updateExternalReward(data));
      (file || logo) && dispatch(uploadRewardLogo({ logo, id, isEditMode }));
    } else {
      dispatch(createExternalReward(data));
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, loading]);

  const handleChange = (logo: any) => {
    const r = new FileReader();
    r.readAsDataURL(logo);
    r.onload = function () {
      clearErrors('logo');
      setValue('file', removeImgExtension(r.result as string), {
        shouldValidate: true,
      });
    };
  };

  if (loading) return <CustomLoader />;

  if (error) return <ShowError error={error} />;
  if (isEditMode && !selectedReward) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" gutterBottom>
          {isEditMode ? t('editReward') : t('addReward')}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label={t('rewardName')}
          {...register('name')}
          helperText={errors.name?.message}
        />
        <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
          <InputLabel id="demo-simple-select-label">{t('rewardDescription')}</InputLabel>
          <TextareaAutosize
            aria-label="description"
            minRows={4}
            {...register('description')}
            placeholder={t('rewardDescription')}
            style={{
              width: '100%',
              fontSize: '16px',
              padding: '6px 20px 7px 20px',
              fontFamily: 'MarkPro',
            }}
          />
          <FormHelperText error>{errors.description?.message}</FormHelperText>
        </Box>

        <FormControl margin="normal" style={{ maxWidth: '100%' }}>
          <InputLabel id="select-logo">
            {t('logo')}
            <HelpIcon title={t('tooltip:logo')} style={{ position: 'relative', top: 5 }} />
          </InputLabel>

          {(formValue.file || formValue.logo) && (
            <Box>
              <CropImage
                file={formValue.file}
                onChange={(logo) => {
                  setValue('logo', logo);
                }}
                isEditMode={isEditMode}
                logo={formValue.logo}
                aspect={4.5}
              />
            </Box>
          )}
        </FormControl>

        {isEditMode && <InputLabel id="change-logo">{t('changeLogo')}</InputLabel>}

        <Box
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            width: '300px',
          }}
        >
          {(!formValue.logo || isEditMode) && (
            <FileUploader
              hoverTitle={' '}
              children={<DragAndDrop />}
              handleChange={handleChange}
              name="logo"
              maxSize={0.5}
              types={supportedTypes}
              onSizeError={(err: string) =>
                setError('logo', {
                  type: 'custom',
                  message: err,
                })
              }
              onTypeError={(err: string) =>
                setError('logo', {
                  type: 'custom',
                  message: err + `. Supported types: ${supportedTypes.map((type) => type)}`,
                })
              }
            />
          )}
          <FormHelperText error>{errors.logo?.message}</FormHelperText>
        </Box>

        <TextField
          margin="normal"
          fullWidth
          label={t('link')}
          {...register('link')}
          helperText={errors.link?.message}
          InputProps={{
            endAdornment: formValue.link && (
              <InputAdornment position="end">
                <IconButton edge="end" color="primary" onClick={() => window.open(formValue.link)}>
                  <Typography variant="body1">{t('preview')}</Typography>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="select-status-label">{t('status')}</InputLabel>
          <Controller
            control={control}
            name="status"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => setValue('status', e.target.value as RewardStatus)}
                  labelId="select-status"
                  id="select-status"
                  label="status"
                  MenuProps={{ disableScrollLock: true }}
                  value={value}
                >
                  {REWARD_STATUS.map((status) => (
                    <MenuItem key={status} value={status} sx={{ fontSize: '1.6rem' }}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
            {t(isEditMode ? 'editReward' : 'addReward')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
