import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gridColumn: 'auto /span 6',
      gridRow: '9',
      display: 'grid',
      width: '100%',
      height: '100%',
    },
    root: {
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      display: 'grid',
    },
  }),
);

export const SocialChallenges: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['userOptions', 'widgets', 'errors']);

  return (
    <Box className={classes.wrapper}>
      <Card className={classes.root}>
        <CardHeader title={t('widgets:socialChallenges')} />
        <CardContent className={classes.content}>
          <Typography variant="h3">{t('comingSoon')}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
