import { createStyles, makeStyles, Theme } from '@material-ui/core';
import './font.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        fontSize: '62.5%',
      },
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      body: {
        height: '100%',
        width: '100%',
        fontFamily: '"MarkPro", sans-serif',
        transition: '0.5s',
        backgroundColor: '#fff !important',
      },
      ul: {
        margin: 0,
        padding: 0,
      },

      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
