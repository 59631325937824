import { CardContent, CardHeader, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MostActiveUsersWidgetItem } from './MostActiveUsersWidgetItem';
import { ErrorText } from 'components/ErrorText';
import { usersSelector } from 'redux/users/usersSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridColumn: '3 / 4',
      gridRow: '3 / 3',
      maxWidth: 500,
      maxHeight: 300,
      boxShadow: theme.shadows[24],
      padding: '1rem',
      borderRadius: '10px',
    },
    cardHeader: {
      padding: '0 2rem',
    },
    cardItem: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export const MostActiveUsersWidget = () => {
  const classes = useStyles();
  const { mostActiveUsers } = useSelector(usersSelector);
  const { t } = useTranslation(['mostActiveUsers', 'errors']);

  const threeMostRecentUsers = mostActiveUsers?.slice(0, 3);

  return (
    <Card className={classes.root}>
      <CardHeader title={t('title')} />
      {!threeMostRecentUsers ? (
        <ErrorText>
          <Typography variant="body1">{t('errors:noUsersFound')}</Typography>
        </ErrorText>
      ) : (
        <CardContent>
          {threeMostRecentUsers.map((el) => (
            <MostActiveUsersWidgetItem key={el.id} {...el} />
          ))}
        </CardContent>
      )}
    </Card>
  );
};
