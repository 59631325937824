import { Card, CardHeader, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { Box, CardContent } from '@mui/material';
import Select from '@mui/material/Select';
import React from 'react';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CustomEditIcon } from 'components/Icons/EditIcon';
import { COMPANY_SIZE } from 'constants/mock';
import { updateCompany } from 'redux/companies/companiesActions';
import { Company, CompanySize } from 'types';

const useStyles = makeStyles({
  wrapper: {
    gridColumn: 'auto /span 3',
    gridRow: '1/3',
  },
  content: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailsContainerItem: {
    marginBottom: '40px',
    textAlign: 'center',
  },
  disabled: { borderRadius: 0, borderColor: 'white' },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type Props = { company: Company };

export const CompanyProfile: React.FC<Props> = ({ company }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['userProfile', 'companyProfile', 'errors', 'widgets']);

  const { name, accessCode, email, id, size } = company;

  return (
    <Card className={classes.wrapper}>
      <CardHeader title={'Details'} />
      <CardContent className={classes.content}>
        <Box className={classes.detailsContainerItem}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('companyProfile:companyName')}
          </Typography>
          <Typography variant="h3">{name}</Typography>
        </Box>
        <Box className={classes.detailsContainerItem}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('companyProfile:email')}
          </Typography>

          <Typography variant="h3" style={{ flexDirection: 'row' }}>
            <Box className={classes.textInputContainer}>
              <EditText
                name="email"
                defaultValue={email}
                style={{
                  width: '300px',
                }}
                onSave={({ value }) => {
                  dispatch(updateCompany({ id, data: { email: value } }));
                }}
              />
              <CustomEditIcon />
            </Box>
          </Typography>
        </Box>

        <Box className={classes.detailsContainerItem}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('accessCode')}
          </Typography>
          <Typography variant="h3">{accessCode}</Typography>
        </Box>

        <Box className={classes.detailsContainerItem}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('widgets:companySize')}
          </Typography>
          <Select
            labelId="company-size-select"
            id="company-size-select"
            value={size}
            style={{ width: '200px', height: '50px' }}
            MenuProps={{ disableScrollLock: true }}
            onChange={(e) => {
              const size = e.target.value as CompanySize;
              dispatch(updateCompany({ id, data: { size: size } }));
            }}
          >
            {COMPANY_SIZE.map((size) => (
              <MenuItem key={size} value={size}>
                <Typography variant="h3">{size}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </CardContent>
    </Card>
  );
};
