const PRIMARY = {
  light: '#371A65',
  main: '#371A65',
  dark: '#fffff',
  contrastText: '#fff',
};

const SECONDARY = {
  light: '#fffff',
  main: '#fffff',
  dark: '#fffff',
  contrastText: '#fff',
};

const ERROR = {
  main: '#F45151',
};

const WARNING = {
  main: '#F26424',
};

const GRADIENTS = {
  100: 'transparent linear-gradient(90deg, #E8BACF 0%, #EBCCA5 100%)',
  200: 'transparent linear-gradient(90deg, #DE87AE 0%, #EEB36C 100%)',
  300: 'transparent linear-gradient(90deg, #D96499 0%, #EEA347 100%)',
  400: 'transparent linear-gradient(90deg, #DB5290 0%, #F59928 100%)',
};

const GREY = {
  100: '#F9F9F9',
  200: '#F3F2F1',
  300: '#E1E1E1',
  400: '#A19F9D',
};

const palette = {
  common: { black: '#000', white: '#fff', purple: '#371A65' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  gradients: { ...GRADIENTS },
  error: { ...ERROR },
  warning: { ...WARNING },
  grey: { ...GREY },
  action: {},
};

export const auxiliaryPalette = {
  pink: '#DB5290',
  lightOrange: '#F59928',
};

export default palette;
