function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: `${sm}rem`,
    },
    '@media (min-width:900px)': {
      fontSize: `${md}rem`,
    },
    '@media (min-width:1200px)': {
      fontSize: `${lg}rem`,
    },
  };
}

export const FONT_PRIMARY = 'MarkPro';
export const FONT_MEDIUM = 'MarkProMedium';
export const FONT_BOLD = 'MarkProBold';
export const FONT_SIZE_PRIMARY = `${1.6}rem`;

const typography = {
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_BOLD,
    lineHeight: 80 / 64,
    fontSize: `${2.5}rem`,
    ...responsiveFontSizes({ sm: 3.25, md: 3.6, lg: 4 }),
  },
  h2: {
    fontFamily: FONT_BOLD,
    lineHeight: 64 / 48,
    fontSize: `${2}rem`,
    ...responsiveFontSizes({ sm: 2.5, md: 2.77, lg: 3 }),
  },
  h3: {
    fontFamily: FONT_BOLD,
    lineHeight: 1.5,
    fontSize: `${1.5}rem`,
    ...responsiveFontSizes({ sm: 1.6, md: 1.8, lg: 2 }),
  },
  h4: {
    fontFamily: FONT_BOLD,
    lineHeight: 1.5,
    fontSize: `${1.25}rem`,
    ...responsiveFontSizes({ sm: 1.25, md: 1.3, lg: 1.4 }),
  },
  h5: {
    fontFamily: FONT_BOLD,
    lineHeight: 1.5,
    fontSize: `${1.12}rem`,
    ...responsiveFontSizes({ sm: 1.18, md: 1.19, lg: 1.22 }),
  },
  h6: {
    fontFamily: FONT_BOLD,
    lineHeight: 28 / 18,
    fontSize: `${1.06}rem`,
    ...responsiveFontSizes({ sm: 1.12, md: 1.13, lg: 1.15 }),
  },
  subtitle1: {
    fontFamily: FONT_MEDIUM,
    lineHeight: 1.5,
    fontSize: FONT_SIZE_PRIMARY,
  },
  subtitle2: {
    fontFamily: FONT_MEDIUM,
    lineHeight: 22 / 14,
    fontSize: FONT_SIZE_PRIMARY,
  },
  body1: {
    fontFamily: FONT_PRIMARY,
    lineHeight: 1.5,
    fontSize: FONT_SIZE_PRIMARY,
  },
  body2: {
    fontFamily: FONT_PRIMARY,
    lineHeight: 22 / 14,
    fontSize: FONT_SIZE_PRIMARY,
  },
  caption: {
    lineHeight: 1.5,
    fontSize: `${1.1}rem`,
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: FONT_SIZE_PRIMARY,
    letterSpacing: 1.1,
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: FONT_SIZE_PRIMARY,
  },
};

export default typography;
