import { SidebarProvider } from 'context/SidebarContext';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import GlobalStyles from 'theme/GlobalStyles';
import { ThemeConfig } from 'theme/ThemeConfig';
import i18n from 'translation/i18n';

import { RootNavigator } from 'components';

const App = () => {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <I18nextProvider i18n={i18n}>
        <SidebarProvider>
          <RootNavigator />
        </SidebarProvider>
      </I18nextProvider>
      <ToastContainer />
    </ThemeConfig>
  );
};

export default App;
