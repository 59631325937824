import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: 250,
      width: 300,
      border: `2px dashed ${theme.palette.grey[300]}`,
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
    },
    icon: {
      fontSize: '77px !important',
      color: theme.palette.grey[400],
      marginBottom: '0.35em',
    },
    boldText: {
      fontWeight: 'bold',
      textDecoration: 'underline black 2px',
      textUnderlineOffset: 2,
    },
  }),
);

export const DragAndDrop: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('dragAndDrop');
  return (
    <Box className={classes.wrapper}>
      {/* TODO: Change it to be able to add pills
       {file ? (
        <>
          <CheckCircleOutlineIcon className={classes.icon} />
          <Typography align="center" variant="body1" gutterBottom>
            {t('loadedSucc')}
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            {file.name}
          </Typography>
        </>
      ) : ( */}
      <>
        <PhotoLibraryIcon className={classes.icon} />
        <Typography variant="body1" gutterBottom>
          {t('title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('or')}
        </Typography>
        <Typography className={classes.boldText} variant="body1" gutterBottom>
          {t('browseFile')}
        </Typography>
      </>
    </Box>
  );
};
