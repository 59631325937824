import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { FiAlignLeft } from 'react-icons/fi';

interface IProps {
  isSidebarOpen: boolean;
  handleToggleSidebar: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hamburgerMenu: {
      display: 'none',
      fontSize: '3rem',
      right: '33px',
      top: '41px',
      zIndex: 101,

      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    button: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 'none',
      fontSize: '3.2rem',
    },
  }),
);

export const HamburgerMenu: React.FC<IProps> = ({ handleToggleSidebar }) => {
  const classes = useStyles();
  return (
    <div className={classes.hamburgerMenu}>
      <button className={classes.button}>
        <FiAlignLeft onClick={handleToggleSidebar} />
      </button>
    </div>
  );
};
