import {
  Box,
  Card,
  CardHeader,
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { BaseOptionsChart } from 'components';
import { UJJI_RELEASE_DATE } from 'constants/analytics';
import { chartStateOptions, timeSelectOptions } from 'constants/timeSelectOptions';
import { ActiveUsersState, CompanyActivity, IChartData, IChartValueState } from 'types';
import { dateRange, endDate, formatTime, monthAgo, sevenDaysAgo } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      height: '30px',
    },
  }),
);

const reduceCompanyActivity = (activities: _.Dictionary<CompanyActivity[]>) =>
  _.map(activities, (activity) => activity.reduce((acc, obj) => acc + obj.numberOfLoggedUsers, 0));

type Props = {
  wrapperStyle: string;
  companyActivity: CompanyActivity[];
  height: number | string;
};
export const ActiveUsersCard: React.FC<Props> = ({ wrapperStyle, companyActivity, height }) => {
  const { t } = useTranslation('usersDashboard');
  const classes = useStyles();
  const [chartValue, setChartValue] = useState<IChartValueState>('monthAgo');
  const [chartState, setChartState] = useState<ActiveUsersState>('Daily');
  const [rangeOfDays, setRangeOfDays] = useState<string[]>(dateRange(monthAgo, endDate));
  const isDaily = chartState === 'Daily';

  const userLoginTimeDaily = (companyActivity: CompanyActivity) =>
    format(new Date(companyActivity.date), 'yyyy-MM-dd');
  const userLoginTimeMonthly = (companyActivity: CompanyActivity) =>
    format(new Date(companyActivity.date), 'MMM');

  const groupedByLoginTimeDaily = _(companyActivity).groupBy(userLoginTimeDaily).value();
  const groupedByLoginTimeMonthly = _(companyActivity).groupBy(userLoginTimeMonthly).value();

  const dailyValue = reduceCompanyActivity(groupedByLoginTimeDaily);
  const dailyLabel = _.map(groupedByLoginTimeDaily, (user) =>
    format(new Date(user[0].date), 'yyyy-MM-dd'),
  );

  const monthlyValue = reduceCompanyActivity(groupedByLoginTimeMonthly);
  const monthlyLabel = _.map(groupedByLoginTimeMonthly, (user) =>
    format(new Date(user[0].date), 'yyyy-MM'),
  );

  const dailyData = dailyLabel.map((x, index) => {
    const y = dailyValue[index];
    return { x: format(parseISO(x), 'yyyy-MM-dd'), y };
  });

  const monthlyData = monthlyLabel.map((x, index) => {
    const y = monthlyValue[index];
    return { x: format(new Date(x), 'MMM'), y };
  });

  useEffect(() => {
    const currentDate = new Date();
    switch (chartValue) {
      case 'monthAgo':
        setRangeOfDays(dateRange(monthAgo, currentDate));
        break;
      case 'sevenDaysAgo':
        setRangeOfDays(dateRange(sevenDaysAgo, currentDate));
        break;
      case 'sinceRelease':
        setRangeOfDays(dateRange(UJJI_RELEASE_DATE, currentDate));
        break;
      default:
        throw new Error('You need to choose correct date range');
    }
  }, [chartValue]);

  const data = useMemo(
    () =>
      rangeOfDays.map((date) => {
        const foundDailyData = dailyData.find((x) => formatTime(new Date(x.x)) === date);

        return foundDailyData
          ? {
              x: format(new Date(foundDailyData.x), 'yyyy-MM-dd'),
              y: foundDailyData.y,
            }
          : {
              x: format(new Date(date), 'yyyy-MM-dd'),
              y: 0,
            };
      }),
    [dailyData, rangeOfDays],
  );

  const CHART_DATA: IChartData[] = [
    {
      name: 'Active users',
      data: isDaily ? data : monthlyData,
    },
  ];

  const chartOptions = _.merge(BaseOptionsChart(), {
    xaxis: {
      type: isDaily ? 'datetime' : 'category',
    },
  });

  return (
    <Card className={wrapperStyle}>
      <CardHeader
        title={t('activeUsers')}
        action={
          <Box>
            <Select
              value={chartState}
              onChange={({ target }) => setChartState(target.value as ActiveUsersState)}
              name="states"
              id="states"
              className={classes.select}
              MenuProps={{ disableScrollLock: true }}
              style={{ marginRight: '5px' }}
            >
              {chartStateOptions.map((option) => (
                <MenuItem key={option.text} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
            {isDaily && (
              <Select
                value={chartValue}
                onChange={({ target }) => setChartValue(target.value as IChartValueState)}
                name="dates"
                id="dates"
                className={classes.select}
                MenuProps={{ disableScrollLock: true }}
              >
                {timeSelectOptions.map((option) => (
                  <MenuItem key={option.text} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {isDaily && (
          <Chart type="line" height={height} options={chartOptions} series={CHART_DATA} />
        )}
        {!isDaily && (
          <Chart type="line" height={height} options={chartOptions} series={CHART_DATA} />
        )}
      </Box>
    </Card>
  );
};
