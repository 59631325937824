import {
  Box,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  createStyles,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CustomLoader } from 'components';
import { fetchTrending, updateTrending } from 'redux/trending/trendingActions';
import { trendingSelector } from 'redux/trending/trendingSlice';
import { Trending, TrendingType } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemWrapper: { flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
    itemsWrapper: { display: 'grid', gridTemplateColumns: 'repeat(4,1fr)', padding: 5 },
  }),
);

export const TrendingPage: React.FC = () => {
  const { trending, isLoading } = useSelector(trendingSelector);

  const [trendingCount, setTrendingCount] = useState<number>(0);
  const trendingMax = 20;

  const { t } = useTranslation('trending');
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTrending());
  }, []);

  useEffect(() => {
    if (trending) {
      const { games, goals, pills, tests } = trending;
      const trendingCount =
        games.filter((x) => x.trending).length +
        goals.filter((x) => x.trending).length +
        pills.filter((x) => x.trending).length +
        tests.filter((x) => x.trending).length;
      setTrendingCount(trendingCount);
    }
  }, [trending]);

  const handleChange = (id: number, type: TrendingType, isTrending: boolean) => {
    dispatch(updateTrending({ id, type, isTrending }));
  };

  const renderItem = (
    item: Trending['games'] | Trending['goals'] | Trending['pills'] | Trending['tests'],
    type: TrendingType,
  ) => (
    <Box>
      <Typography variant="h3" gutterBottom>
        {t(type)}
      </Typography>
      {item.map((x) => {
        const isTrending = !!x.trending;
        return (
          <Box className={classes.itemWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={trendingCount === trendingMax && !isTrending}
                  defaultChecked={isTrending}
                  onChange={(_, checked) => handleChange(x.id, type, checked)}
                  sx={{
                    '&.Mui-checked': {
                      color: orange[900],
                    },
                  }}
                />
              }
              label={x.name}
            />
          </Box>
        );
      })}
    </Box>
  );

  if (isLoading) return <CustomLoader />;

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        {t('trending')} {`${trendingCount} / ${trendingMax}`}
      </Typography>
      <Box className={classes.itemsWrapper}>
        {renderItem(trending.pills, 'pills')}
        {renderItem(trending.goals, 'goals')}
        {renderItem(trending.games, 'games')}
        {renderItem(trending.tests, 'tests')}
      </Box>
    </Box>
  );
};
