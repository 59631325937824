import {
  Box,
  Button,
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Pagination } from '@mui/material';
import Grow from '@mui/material/Grow';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { CustomLoader } from './CustomLoader';
import {
  filtersSelector,
  updatePageFilter,
  updateSearchFilter,
  updateSortFilter,
} from 'redux/filters';
import { PaginationOptions, PaginationType, Paths } from 'types';

type Props = {
  columns: any;
  data: { pagination: PaginationType; results: any[] } | any[];
  onEdit: (id: string) => void;
  onInfo?: (id: string) => void;
  createButtonProps: { title: string; onCreate: () => void; disabled?: boolean };
  isLoading?: boolean;
  fetchData?: ({ page, pagination }: PaginationOptions) => void;
  fetchQuestions?: ({ page, search }: PaginationOptions) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaginationItem-root': {
        fontSize: 22,
        '&.Mui-selected': {
          color: 'white',
          background: theme.palette.gradients[400],
        },
      },
      '& .MuiPaginationItem-icon': {
        fontSize: 22,
      },
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        display: 'block',
      },
    },
    paper: {
      maxWidth: 1400,
      boxShadow: theme.shadows[24],
    },
    wrapper: {},
    filterWrapper: {
      padding: '10px 30px',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    pagination: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      paddingTop: 36,
      paddingBottom: 12,
    },
  }),
);

export const CustomTablePagination: React.FC<Props> = ({
  columns,
  data,
  onEdit,
  onInfo,
  isLoading,
  createButtonProps,
  fetchData,
  fetchQuestions,
}) => {
  const { filters } = useSelector(filtersSelector);
  const pathName = window.location.pathname as Paths;
  const currentFiltersIndex = filters?.findIndex((x) => x.path === pathName);
  const currentFilters = currentFiltersIndex >= 0 ? filters[currentFiltersIndex] : undefined;
  const pageState = currentFilters ? currentFilters.page : 0;
  const sortByState = currentFilters && currentFilters.sortBy;

  const [query, setQuery] = useState<string | undefined>(currentFilters?.query);

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: 'results' in data ? data.results : (data as unknown as []),
      manualPagination: 'results' in data ? true : undefined,
      pageCount: 'pagination' in data ? data.pagination.allPages : undefined,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: pageState,
            sortBy: sortByState ?? state.sortBy,
          }),
          [state, pageState, sortByState],
        );
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const debouncedSearch = React.useRef(
    debounce(async (criteria) => {
      fetchData && dispatch(fetchData({ search: criteria.trim() }));
      fetchQuestions && fetchQuestions({ search: criteria.trim() });
      dispatch(updateSearchFilter({ path: pathName, query: criteria.trim() }));
    }, 500),
  ).current;

  React.useEffect(() => {
    fetchData && dispatch(fetchData({ page: pageIndex + 1, search: query }));
    fetchQuestions && fetchQuestions({ page: pageIndex + 1, search: query });
  }, [pageIndex]);

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const { title, onCreate, disabled } = createButtonProps;

  const sortHeaders = (column: any) => {
    if (column.isSortedDesc) {
      dispatch(
        updateSortFilter({
          path: pathName,
          sortBy: [{ id: column.id, desc: false }],
        }),
      );
    } else {
      dispatch(
        updateSortFilter({
          path: pathName,
          sortBy: [{ id: column.id, desc: true }],
        }),
      );
    }
    if (column.isSorted && !column.isSortedDesc) {
      dispatch(
        updateSortFilter({
          path: pathName,
          sortBy: [],
        }),
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.filterWrapper}>
        <TextField
          value={query}
          onChange={(e) => {
            debouncedSearch(e.target.value);
            setQuery(e.target.value);
          }}
          type="text"
          placeholder="Search"
          InputProps={{
            startAdornment: !query && (
              <Grow in={!query} {...(!query ? { timeout: 1000 } : {})}>
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: '26px', color: 'silver' }} />
                </InputAdornment>
              </Grow>
            ),
            endAdornment: query && (
              <Grow in={!!query} {...(query ? { timeout: 1000 } : {})}>
                <IconButton
                  aria-label="clear"
                  onClick={() => {
                    debouncedSearch('');
                    setQuery('');
                    dispatch(updateSearchFilter({ path: pathName, query: '' }));
                  }}
                >
                  <ClearIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Grow>
            ),
          }}
        />
        <Button
          variant="outlined"
          style={{ marginBottom: '10px' }}
          onClick={onCreate}
          disabled={disabled}
        >
          {title}
        </Button>
      </div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <MaUTable className={classes.table} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps({
                        style: {
                          width: column.width,
                          minWidth: column.width,
                          maxWidth: column.width,
                        },
                      })}
                      {...column.getSortByToggleProps()}
                      onClick={() => sortHeaders(column)}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            minWidth: cell.column.width,
                            maxWidth: cell.column.width,
                          },
                        })}
                      >
                        {cell.render('Cell', { onEdit, onInfo })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      )}
      {!isLoading && (
        <Box className={classes.pagination}>
          <Pagination
            className={classes.root}
            count={pageOptions.length}
            page={pageIndex + 1}
            size="large"
            onChange={(event, value) => {
              dispatch(updatePageFilter({ path: pathName, page: value - 1 }));
            }}
          />
        </Box>
      )}
    </div>
  );
};
