import { createSlice } from '@reduxjs/toolkit';
import i18next from 'translation/i18n';

import {
  fetchUser,
  fetchUserDailyTracking,
  fetchUserProfiles,
  fetchUserQualities,
  fetchUsers,
  toggleUserStatus,
} from './usersActions';
import { RootState } from 'redux/store';
import {
  IMostActiveUser,
  IUser,
  IUserDailyTracking,
  IUserQualities,
  PaginationType,
  UserProfile,
} from 'types';

interface IUsers {
  pagination: PaginationType;
  results: IUser[];
  isLoading?: boolean;
}

interface IUsersState {
  user: Record<number, IUser>;
  users: IUsers;
  mostActiveUsers: IMostActiveUser[];
  isLoading: boolean;
  errorMessage: string;
  userDailyTracking: IUserDailyTracking[];
  userQualities: IUserQualities[];
  userProfiles: UserProfile[];
}

const initialState: IUsersState = {
  user: {},
  users: { pagination: { page: 1, allPages: 1 }, results: [], isLoading: false },
  userProfiles: [],
  mostActiveUsers: [],
  userDailyTracking: [],
  userQualities: [],
  isLoading: false,
  errorMessage: '',
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const mappedUsers: IUser[] = action.payload.results.map((item) => ({
          ...item,
          blocked: item.isBlocked ? 1 : 0,
        }));
        state.users.results = mappedUsers;
        state.isLoading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.errorMessage = action.error.message || i18next.t('errors:other');
        state.isLoading = false;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          [action.payload.id]: action.payload,
        };
        state.isLoading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.errorMessage = action.error.message || i18next.t('errors:other');
        state.isLoading = false;
      })
      .addCase(fetchUserDailyTracking.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUserDailyTracking.rejected, (state, action) => {
        state.errorMessage = action.error.message || i18next.t('errors:other');
        state.isLoading = false;
      })
      .addCase(fetchUserDailyTracking.fulfilled, (state, action) => {
        state.userDailyTracking = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserQualities.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUserQualities.rejected, (state, action) => {
        state.errorMessage = action.error.message || i18next.t('errors:other');
        state.isLoading = false;
      })
      .addCase(fetchUserQualities.fulfilled, (state, action) => {
        state.userQualities = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserProfiles.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUserProfiles.rejected, (state, action) => {
        state.errorMessage = action.error.message || i18next.t('errors:other');
        state.isLoading = false;
      })
      .addCase(fetchUserProfiles.fulfilled, (state, action) => {
        state.userProfiles = action.payload.results;
        state.isLoading = false;
      })
      .addCase(toggleUserStatus.pending, (state, action) => {
        state.users.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(toggleUserStatus.rejected, (state, action) => {
        state.errorMessage = action.error.message || i18next.t('errors:other');
        state.users.isLoading = false;
      })
      .addCase(toggleUserStatus.fulfilled, (state, action) => {
        const { id, isBlocked } = action.payload;
        const foundUserId = state.users.results.findIndex((x) => x.id === id);
        const mappedUser: IUser = { ...action.payload, blocked: isBlocked ? 1 : 0 };

        state.users.results[foundUserId] = mappedUser;
        state.users.isLoading = false;
      });
  },
});

export const usersSelector = (state: RootState) => state.users;
export default usersSlice.reducer;
