import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'translation/i18n';

import { createGoal, fetchGoals, fetchTypesOfGoals, updateGoal } from './goalsActions';
import { RootState } from 'redux/store';
import { IGoalType, IUserGoals, PaginationType } from 'types';

export interface IUserGoalsState {
  usersGoals: IUserGoals[];
  isLoading: boolean;
  errorMessage: string;
  goalsTypes: { pagination: PaginationType; results: IGoalType[] };
}

const initialState: IUserGoalsState = {
  usersGoals: [],
  goalsTypes: { pagination: { page: 1, allPages: 1 }, results: [] },
  isLoading: false,
  errorMessage: '',
};

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoals.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.usersGoals = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchGoals.rejected, (state, action: any) => {
        state.errorMessage = action.error;
        state.isLoading = false;
      })
      .addCase(fetchTypesOfGoals.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchTypesOfGoals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.goalsTypes = action.payload;
      })
      .addCase(fetchTypesOfGoals.rejected, (state, action: any) => {
        state.isLoading = false;
        state.errorMessage = action.error;
      })
      .addCase(updateGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(updateGoal.fulfilled, (state, action) => {
        const { id } = action.payload;
        const foundId = state.goalsTypes.results.findIndex((x) => x.id === id);
        state.goalsTypes.results[foundId] = action.payload;

        state.isLoading = false;
      })
      .addCase(updateGoal.rejected, (state, action: any) => {
        state.errorMessage = action.error;
        state.isLoading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.errorMessage =
          action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(createGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(createGoal.fulfilled, (state, action) => {
        state.goalsTypes.results.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createGoal.rejected, (state, action: any) => {
        state.errorMessage = action.error;
        state.isLoading = false;
      });
  },
});

export const goalsSliceSelector = (state: RootState) => state.goals;
export default goalsSlice.reducer;
