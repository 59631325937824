import { TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITableFilterProps {
  filter: string;
  setFilter: (filterValue: string) => void;
}

export const TableFilter: React.FC<ITableFilterProps> = ({ filter, setFilter }) => {
  const { t } = useTranslation('common');
  return (
    <div>
      <h2>{t('usersList')}</h2>
      <TextField value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
    </div>
  );
};
