import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { HelpIcon } from 'components';
import { ISelectOptionsCompany, timeSelectCompany } from 'constants/timeSelectOptions';
import { fetchDailyCheckIns } from 'redux/dailyTracking/dailyTrackingAction';
import { dailyTrackingSelector } from 'redux/dailyTracking/dailyTrackingSlice';
import { Company, DailyCheckInReasonName, EmojiName } from 'types';
import {
  calculateAverageMood,
  firstLetterUpper,
  getDailyCheckInReason,
  getEmoji,
} from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      '@media print': {
        display: 'block',
        float: 'left',
        pageBreakInside: 'avoid',
        pageBreakBefore: 'always',
      },
    },
    content: {
      display: 'flex',
      paddingBottom: 24,
      paddingRight: '10%',
      gap: '140px',
      '@media print': {
        display: 'block',
        float: 'left',
        pageBreakInside: 'avoid',
        pageBreakBefore: 'always',
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    topEmotions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,1fr)',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      gap: '20px',
    },
    gridWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4,1fr)',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
    },
    emotionsContainer: {
      flexDirection: 'row',
      display: 'flex',
      padding: 5,
      width: 500,
      [theme.breakpoints.down('md')]: {
        width: 300,
      },
    },
  }),
);

type Props = { wrapperStyle: string; company?: Company };

export const DailyCheckIns: React.FC<Props> = ({ wrapperStyle, company }) => {
  const { dailyCheckIn } = useSelector(dailyTrackingSelector);
  const isCompany = !!company;
  const [chartValue, setChartValue] = useState<ISelectOptionsCompany>(
    timeSelectCompany(isCompany, company?.createdAt)[0],
  );

  const classes = useStyles();
  const { t } = useTranslation(['dailyCheckins', 'widgets', 'errors', 'tooltip']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchDailyCheckIns({
        isCompany,
        companyId: company ? company.id : undefined,
        date: chartValue.date,
      }),
    );
  }, [chartValue, company]);

  const PurpleTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#371a65',
      fontWeight: 'normal',
      fontSize: 16,
    },
  })(Tooltip);

  return (
    <Box className={wrapperStyle}>
      <Card className={classes.root}>
        <CardHeader
          title={t('cardTitle')}
          action={
            <Box>
              <Select
                value={chartValue.value}
                onChange={({ target }) => {
                  const selectedTime = timeSelectCompany(isCompany, company?.createdAt).find(
                    (time) => time.value === target.value,
                  );
                  return setChartValue(selectedTime as ISelectOptionsCompany);
                }}
                name="chartValue"
                id="chartValue"
                MenuProps={{ disableScrollLock: true }}
                style={{ marginRight: '5px', height: 30 }}
              >
                {timeSelectCompany(isCompany, company?.createdAt).map((option) => (
                  <MenuItem key={option.text} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          }
        />
        <CardContent>
          {dailyCheckIn.totalAVG > 0 ? (
            <Box className={classes.content}>
              <Box style={{ flex: 1 }}>
                <Box>
                  <Box style={{ flexDirection: 'row', display: 'flex' }}>
                    <Typography variant="h2">{t('avgScore')}</Typography>

                    <HelpIcon title={t('tooltip:average')} />
                  </Box>

                  <Typography variant="body2">({chartValue.text})</Typography>
                  <Typography variant="h1">{dailyCheckIn.totalAVG}</Typography>
                  <Typography variant="h3">
                    {calculateAverageMood(dailyCheckIn.totalAVG)}
                  </Typography>
                </Box>
                <Box style={{ marginTop: 40 }}>
                  <Box style={{ flexDirection: 'row', display: 'flex' }}>
                    <Typography variant="h2">{t('topDrivers')}</Typography>

                    <HelpIcon title={t('tooltip:average')} />
                  </Box>
                  <Typography variant="body2">({chartValue.text})</Typography>
                  <Box className={classes.topEmotions}>
                    {Object.keys(dailyCheckIn.topReasons).map(function (key, index) {
                      return (
                        <Typography variant={index === 0 ? 'subtitle2' : 'subtitle1'}>
                          {firstLetterUpper(key)} - {dailyCheckIn.topReasons[key]}%
                        </Typography>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box style={{ flex: 2 }}>
                <Box style={{}}>
                  <Box style={{ flexDirection: 'row', display: 'flex' }}>
                    <Typography variant="h2">{t('topDriversEmotion')}</Typography>

                    <HelpIcon title={t('tooltip:average')} />
                  </Box>
                  <Typography variant="body2">({chartValue.text})</Typography>

                  {Object.keys(dailyCheckIn.topPairs).map((topPair) => {
                    const emotions = Object.keys(dailyCheckIn.topPairs[topPair]).map((x) => x);
                    const moodValue = Object.values(dailyCheckIn.topPairs[topPair]).map((x) => x);
                    return (
                      <Box className={classes.gridWrapper}>
                        <Typography variant="body2" style={{ fontSize: 26 }}>
                          {getEmoji(topPair as EmojiName)}
                        </Typography>
                        <Typography variant="subtitle2">{topPair}</Typography>
                        <AiOutlineArrowRight
                          style={{ color: 'grey', justifySelf: 'center', fontSize: 22 }}
                        />
                        <Box className={classes.emotionsContainer}>
                          {emotions.map((emotion, index) => (
                            <PurpleTooltip
                              title={(moodValue[index] + t('tooltip:usersHadPicked')) as string}
                              placement="top-start"
                              arrow
                            >
                              <Box
                                style={{
                                  padding: 10,
                                  flexDirection: 'row',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontFamily: 'FontAwesomeLight',
                                    fontSize: 26,
                                    padding: 5,
                                  }}
                                >
                                  {getDailyCheckInReason(
                                    firstLetterUpper(
                                      emotion.replace('\n', ' '),
                                    ) as DailyCheckInReasonName,
                                  )}
                                </Typography>
                                <Typography variant={index === 0 ? 'subtitle2' : 'subtitle1'}>
                                  {_.startCase(_.toLower(emotion))}
                                </Typography>
                              </Box>
                            </PurpleTooltip>
                          ))}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          ) : (
            <div>{t('errors:noData')}</div>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
