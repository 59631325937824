import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CustomTablePagination } from 'components';
import { GOALS_COLUMNS } from 'constants/columns/goalsColumns';
import { fetchTypesOfGoals } from 'redux/goals/goalsActions';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';

export const GoalsPage: React.FC = () => {
  const columns = React.useMemo(() => GOALS_COLUMNS, []);
  const { goalsTypes, isLoading } = useSelector(goalsSliceSelector);
  const { t } = useTranslation('createGoal');
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleButton = () => {
    history.push(`${path}/create`);
  };

  const onEdit = async (id: string) => {
    history.push(`${path}/${id}`);
  };

  const buttonProps = {
    title: t('createGoal'),
    onCreate: handleButton,
  };

  return (
    <div>
      <CustomTablePagination
        columns={columns}
        data={goalsTypes}
        onEdit={onEdit}
        isLoading={isLoading}
        createButtonProps={buttonProps}
        fetchData={fetchTypesOfGoals}
      />
    </div>
  );
};
