import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserSessions } from './../../types/index';
import { fetchUserSessions } from './UserSessionsAction';
import { RootState } from 'redux/store';

export interface IUserSessionsState {
  userSessions: IUserSessions[];
  isLoading: boolean;
  errorMessage: string;
}

const initialState: IUserSessionsState = {
  userSessions: [],
  isLoading: false,
  errorMessage: '',
};

export const userSessionsSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSessions.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUserSessions.fulfilled, (state, action: PayloadAction<IUserSessions[]>) => {
        state.userSessions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserSessions.rejected, (state, action: any) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const userSessionsSelector = (state: RootState) => state.userSessions;
export default userSessionsSlice.reducer;
