import i18n from 'translation/i18n';

import { ErrorType, VideoStatus } from 'types';

export const ERROR_GOOGLE_UNAUTHENTICATED =
  'Request had invalid authentication credentials. Expected OAuth 2 access token, login cookie or other valid authentication credential. See https://developers.google.com/identity/sign-in/web/devconsole-project.';
export const ERROR_NOT_FOUND = 'Request failed with status code 404';
export const ERROR_CANT_FIND_VIDEO = "The requested video couldn't be found.";
export const ERROR_NAME_IN_USE = 'name: This name is already in use.';
export const ERROR_CANT_CHANGE_TYPE_TEST =
  'You cannot change the type of the test when it has question answer';
export const ERROR_ACCESS_CODE_IN_USE = `accessCode: The value \"{{ value }}\" is not unique.`;
export const ERROR_COMPANY_NAME_IN_USE = `name: The value \"{{ value }}\" is not unique.`;

export const vimeoStatusErrors: Record<VideoStatus, { message: string }> = {
  available: { message: i18n.t('vimeo:available') },
  uploading_error: {
    message: i18n.t('vimeo:uploadingError'),
  },
  transcoding_error: {
    message: i18n.t('vimeo:transcodingError'),
  },
  unavailable: {
    message: i18n.t('vimeo:unavailable'),
  },
  transcoding: {
    message: i18n.t('vimeo:transcoding'),
  },
  transcode_starting: {
    message: i18n.t('vimeo:transcodeStarting'),
  },
  total_cap_exceeded: {
    message: i18n.t('vimeo:totalCapExceeded'),
  },
  quota_exceeded: {
    message: i18n.t('vimeo:quotaExceeded'),
  },
  uploading: {
    message: i18n.t('vimeo:uploading'),
  },
};

export const errors: ErrorType[] = [
  {
    apiResponse: ERROR_ACCESS_CODE_IN_USE,
    message: i18n.t('errors:invalidAccessCode'),
  },
  {
    apiResponse: ERROR_COMPANY_NAME_IN_USE,
    message: i18n.t('errors:invalidCompanyName'),
  },
  {
    apiResponse: ERROR_COMPANY_NAME_IN_USE + '\n' + ERROR_ACCESS_CODE_IN_USE,
    message: i18n.t('errors:invalidCompanyName'),
  },
];
