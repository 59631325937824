import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from 'translation/i18n';

import { GoalFormType } from 'pages/Session/CreateGoal';
import { updateExclusiveCompanies } from 'redux/companies/companiesActions';
import {
  CheckAdditionalCompanyGoalParams,
  IGoal,
  IGoalType,
  IUserGoals,
  PaginationOptions,
  PaginationType,
} from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchGoals = createAsyncThunk<IUserGoals[]>('goals/fetchGoals', async () => {
  const response = await usersAPI(`api/admin/user_goals?pagination=false`);
  return response.data.results;
});

export const fetchTypesOfGoals = createAsyncThunk<
  {
    pagination: PaginationType;
    results: IGoalType[];
  },
  PaginationOptions
>('goals/fetchTypesOfGoals', async ({ page, pagination, id, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  id && Object.assign(params, { id });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI.get('/api/admin/goals', { params });
  return response.data;
});

export const createGoal = createAsyncThunk<IGoal, GoalFormType>(
  'goals/createGoal',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersAPI.post('/api/admin/goals', data);
      const { exclusiveCompanies, exclusive } = data;

      await dispatch(
        updateExclusiveCompanies({
          id: response.data.id,
          exclusiveCompanies,
          type: 'goal',
          isExclusive: exclusive,
          isAlreadyExclusive: response.data.exclusive,
        }),
      ).unwrap();

      toast.success(i18n.t('form:createdSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateGoal = createAsyncThunk<IGoal, GoalFormType>(
  'goals/updateGoal',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersAPI.patch(`/api/admin/goals/${data.id}`, data);
      const { id, exclusiveCompanies, exclusive } = data;

      await dispatch(
        updateExclusiveCompanies({
          id,
          exclusiveCompanies,
          type: 'goal',
          isExclusive: exclusive,
          isAlreadyExclusive: response.data.exclusive,
        }),
      ).unwrap();

      toast.success(i18n.t('form:updatedSuccessfully'));
      return { ...response.data, exclusive };
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const checkAdditionalCompanyGoal = createAsyncThunk<void, CheckAdditionalCompanyGoalParams>(
  'goals/checkAdditionalCompanyGoal',
  async ({ companyId, goalId, cb }) => {
    const response = await usersAPI.get(
      `/api/admin/company_goals/check/company/${companyId}/goal/${goalId}`,
    );

    cb(response.data.length !== 0);
  },
);
