import {
  Box,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { default as lodash, default as _ } from 'lodash';

import { ProgressBar } from './ProgressBar';
import { IUserGoals } from 'types';
import { getFocusArea } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      //   justifyContent: 'center',
      margin: '24px',
    },
  }),
);

type Props = {
  data: IUserGoals[][];
  title: string;
};

export const FocusAreaItem: React.FC<Props> = ({ data, title }) => {
  const classes = useStyles();

  const color = data.length ? getFocusArea(data[0][0].goal.focusArea).contentColor : 'black';

  return (
    <Card className={classes.card}>
      <CardHeader title={title} style={{ textAlign: 'center', color: color }} />
      {lodash.map(data, (x, index) => {
        const max = x.reduce(function (prev, current) {
          return prev.completedSessions > current.completedSessions ? prev : current;
        });
        return (
          <Tooltip
            placement="top"
            arrow
            title={
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{ textAlign: 'center' }}
                >
                  {'Pathway id ' + x[0].goal.id}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{ textAlign: 'center' }}
                >
                  {x[0].goal.name}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{ textAlign: 'center' }}
                >
                  {x.length + ' users chose this pathway'}
                </Typography>
              </Box>
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '120px' }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  style={{ textAlign: 'center' }}
                >
                  {_.truncate(x[0].goal.name, { length: 10 })}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{ textAlign: 'center' }}
                >
                  {x.length}
                </Typography>
              </Box>

              <ProgressBar
                value={max.completedSessions}
                max={x[0].goal.goalLength.length}
                color={color}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Card>
  );
};
