import { CardContent, CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PieChart } from 'components/Charts/PieChart';
import { usersSelector } from 'redux/users/usersSlice';
import { convertAgesData } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridColumn: 'auto / span 4',
      gridRow: '1 / 3',
      boxShadow: theme.shadows[24],
      padding: '1rem',
      borderRadius: '10px',
      maxWidth: 460,
      maxHeight: 400,
    },
    cardHeader: {
      padding: '0 2rem',
    },
    cardItem: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export const AgeChart: React.FC = () => {
  const classes = useStyles();
  const { users } = useSelector(usersSelector);
  const { t } = useTranslation(['usersDashboard', 'errors']);

  const data = convertAgesData(users.results);

  return (
    <Card className={classes.root}>
      <CardHeader title={t('ageChart')} />
      <CardContent style={{ padding: 0 }}>
        <PieChart
          chartData={data}
          labels={['18-24', '25-34', '35-44', '45-54', '55+']}
          legendTitle={t('ageLegend')}
          percentage
        />
      </CardContent>
    </Card>
  );
};
