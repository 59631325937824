import { format } from 'date-fns';
import i18 from 'translation/i18n';

import { UJJI_RELEASE_DATE } from './analytics';
import { ActiveUsersState, IChartValueState } from 'types';
import { monthAgo, sevenDaysAgo } from 'utils/helperFunctions';

export interface ITimeSelectOptions {
  value: IChartValueState;
  text: string;
}
export interface ISelectOptionsCompany {
  value: IChartValueState;
  text: string;
  date: string;
}
interface StateSelectOptions {
  value: ActiveUsersState;
  text: string;
}

export const timeSelectOptions: ITimeSelectOptions[] = [
  {
    value: 'monthAgo',
    text: `${i18.t('timeSelectOptions:monthAgo')}`,
  },
  {
    value: 'sevenDaysAgo',
    text: `${i18.t('timeSelectOptions:sevenDaysAgo')}`,
  },
  {
    value: 'sinceRelease',
    text: `${i18.t('timeSelectOptions:sinceRelease')}`,
  },
];

export const timeSelectCompany = (
  isCompany: boolean,
  contractedDate?: string,
): ISelectOptionsCompany[] => [
  {
    value: 'monthAgo',
    text: `${i18.t('timeSelectOptions:monthAgo')}`,
    date: format(monthAgo, 'yyyy-MM-dd'),
  },
  {
    value: 'sevenDaysAgo',
    text: `${i18.t('timeSelectOptions:sevenDaysAgo')}`,
    date: format(sevenDaysAgo, 'yyyy-MM-dd'),
  },
  {
    value: 'sinceContracted',
    text: isCompany
      ? `${i18.t('timeSelectOptions:sinceContracted')}`
      : `${i18.t('timeSelectOptions:sinceRelease')}`,
    date:
      isCompany && contractedDate
        ? format(new Date(contractedDate), 'yyyy-MM-dd')
        : format(new Date(UJJI_RELEASE_DATE), 'yyyy-MM-dd'),
  },
];

export const chartStateOptions: StateSelectOptions[] = [
  {
    value: 'Daily',
    text: `${i18.t('timeSelectOptions:daily')}`,
  },
  {
    value: 'Monthly',
    text: `${i18.t('timeSelectOptions:monthly')}`,
  },
];
