import { CardContent, CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomLoader } from 'components';
import { PieChart } from 'components/Charts/PieChart';
import { analyticsSelector } from 'redux/analytics/analyticsSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridColumn: 'auto / span 4',
      gridRow: '1 / 3',
      maxWidth: 460,
      maxHeight: 400,
      boxShadow: theme.shadows[24],
      padding: '1rem',
      borderRadius: '10px',
    },
    cardHeader: {
      padding: '0 2rem',
    },
    cardItem: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export const LocationChart: React.FC = () => {
  const classes = useStyles();
  const { topCities, loading } = useSelector(analyticsSelector);
  const { t } = useTranslation(['usersDashboard', 'errors']);

  const filteredCities = topCities.filter((x) => x.dimensionValues[0].value !== '(not set)');

  const data = filteredCities.map((x) => parseInt(x.metricValues[0].value));
  const labels = filteredCities.map((x) => x.dimensionValues[0].value);

  return (
    <Card className={classes.root}>
      <CardHeader title={t('location')} />
      <CardContent style={{ padding: 0 }}>
        {loading.fetchTopCities ? (
          <CustomLoader />
        ) : (
          <PieChart
            chartData={data.slice(0, 5)}
            labels={labels.slice(0, 5)}
            legendTitle="Top Cities"
            percentage
          />
        )}
      </CardContent>
    </Card>
  );
};
