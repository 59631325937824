import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import LogoIcon from 'assets/gif/loader.gif';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.common.white,
      height: '100%',
      width: '100%',
      transition: 'all 2s',
      position: 'fixed',
      top: 0,
    },
    icon: {
      width: '90px',
      height: '90px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      animation: 'pulse 3000ms infinite',
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 0,
        transform: 'scale(1)',
      },
      '100%': {
        opacity: 1,
        transform: 'scale(1.35)',
      },
    },
  }),
);

export const FullPageLoader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img className={classes.icon} src={LogoIcon} alt="logo" />
    </div>
  );
};
