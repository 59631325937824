import { createAsyncThunk } from '@reduxjs/toolkit';

import { DailyCheckIn, IUserDailyTracking } from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchDailyTracking = createAsyncThunk<IUserDailyTracking[]>(
  'dailyTracking/fetchDailyTracking',
  async () => {
    const response = await usersAPI('api/admin/daily_trackings');
    return response.data;
  },
);

export const fetchDailyCheckIns = createAsyncThunk<
  DailyCheckIn,
  { isCompany: boolean; companyId?: number; date: string }
>('dailyTracking/fetchDailyCheckIns', async ({ isCompany, companyId, date }) => {
  const params = {};
  companyId && Object.assign(params, { companyId });
  const response = await usersAPI.post(`/api/daily_check/average`, { isCompany, date, ...params });

  return response.data;
});
