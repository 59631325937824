import { Box, makeStyles, Typography } from '@material-ui/core';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const useStyles = makeStyles({
  root: {
    height: 10,
    borderRadius: 5,
    width: '120px',
  },
  bar: ({ progress, color }: { progress: number; color: string }) => ({
    borderRadius: 5,
    background: `linear-gradient(90deg, ${color} ${100 - progress}%, ${color} 100%)`,
  }),
  text: {
    position: 'absolute',
  },
});

type Props = {
  value: number;
  max: number;
  color: string;
};

export const ProgressBar: React.FC<Props> = ({ value, max, color }) => {
  const progress = (value / max) * 100;
  const classes = useStyles({ progress, color });

  return (
    <Box
      sx={{
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BorderLinearProgress
        classes={{ root: classes.root, bar: classes.bar }}
        variant="determinate"
        value={progress}
      />
      <Typography className={classes.text} variant="body2">{`${value}/${max}`}</Typography>
    </Box>
  );
};
