import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { CellProps } from 'react-table';

import { nuggetTypeData } from 'constants/mock';
import { Nugget } from 'types';

export const NUGGETS_COLUMN = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '10%',
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: '40%',
    Cell: ({ row }: CellProps<Nugget>): string =>
      _.truncate(row.original.description, { length: 100 }),
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: '20%',
    Cell: (props: CellProps<Nugget>): string => nuggetTypeData[props.row.original.type].label,
  },
  {
    Header: 'Pathway',
    accessor: 'goal.name',
    width: '20%',
  },
  {
    Header: 'Options',
    accessor: 'test',
    width: '10%',
    Cell: (props: CellProps<Nugget>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
          onClick={() => props.onEdit(id)}
        >
          <EditIcon fontSize="large" />
        </Button>
      );
    },
  },
];
