import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import i18n from 'translation/i18n';

import { PillFormType } from 'pages';
import { updateExclusiveCompanies } from 'redux/companies/companiesActions';
import {
  ConvertedSession,
  ExternalReward,
  FileType,
  IDailyTask,
  IGame,
  IPill,
  ITest,
  Nugget,
  PaginationOptions,
  PaginationType,
  Question,
  Session,
  TestFormType,
  TestTypeAPI,
  VimeoApiResponse,
} from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchPills = createAsyncThunk<
  {
    pagination: PaginationType;
    results: IPill[];
  },
  PaginationOptions
>('badges/fetchPills', async ({ id, page, pagination, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  id && Object.assign(params, { id });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI(`/api/admin/pills`, { params });
  return response.data;
});

export const fetchGames = createAsyncThunk<{
  pagination: PaginationType;
  results: IGame[];
}>('badges/fetchGames', async () => {
  const response = await usersAPI('/api/admin/games?pagination=false');
  return response.data;
});

export const fetchTests = createAsyncThunk<
  {
    pagination: PaginationType;
    results: ITest[];
  },
  PaginationOptions
>('badges/fetchTests', async ({ id, page, pagination, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  id && Object.assign(params, { id });
  search && Object.assign(params, { search });
  pagination !== undefined && Object.assign(params, { pagination });
  const response = await usersAPI(`/api/admin/tests`, { params });
  return response.data;
});

export const fetchDailyTask = createAsyncThunk<
  {
    pagination: PaginationType;
    results: IDailyTask[];
  },
  PaginationOptions
>('badges/fetchDailyTask', async ({ page, pagination, id, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  id && Object.assign(params, { id });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI('/api/admin/daily_tasks', { params });
  return response.data;
});

export const fetchNuggets = createAsyncThunk<
  {
    pagination: PaginationType;
    results: Nugget[];
  },
  PaginationOptions
>('badges/fetchNuggets', async ({ page, pagination, id, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  id && Object.assign(params, { id });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI('/api/admin/goal_nuggets', { params });
  return response.data;
});

export const fetchSessionsTemplates = createAsyncThunk<
  {
    pagination: PaginationType;
    results: Session[];
  },
  PaginationOptions
>('badges/fetchSessionsTemplates', async ({ page, pagination, id, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  pagination !== undefined && Object.assign(params, { pagination });
  id && Object.assign(params, { id });
  search && Object.assign(params, { search });
  const response = await usersAPI('/api/admin/session_templates', { params });
  return response.data;
});

export const fetchTestQuestions = createAsyncThunk<
  { pagination: PaginationType; results: Question[] },
  PaginationOptions
>('badges/fetchTestQuestions', async ({ type, id, page, pagination, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  pagination !== undefined && Object.assign(params, { pagination });
  id && Object.assign(params, { test: id });
  search && Object.assign(params, { search });
  const response = await usersAPI(`/api/admin/test_questions/${type}`, { params });
  return response.data;
});

export const createSession = createAsyncThunk<Session, ConvertedSession>(
  'createSessionAction/createSession',
  async (newSession, { rejectWithValue }) => {
    try {
      const response = await usersAPI.post('/api/admin/session_templates', newSession);
      toast.success(i18n.t('form:createdSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const createDailyTask = createAsyncThunk<
  IDailyTask,
  { goal: number; description: string; title: string }
>('createSessionAction/createDailyTask', async (task) => {
  const response = await usersAPI.post('/api/admin/daily_tasks', task);
  toast.success(i18n.t('form:createdSuccessfully'));
  return response.data;
});

export const updateDailyTask = createAsyncThunk<
  IDailyTask,
  { id: string; task: { goal: number; description: string; title: string } }
>('createSessionAction/updateDailyTask', async ({ id, task }) => {
  const response = await usersAPI.patch(`/api/admin/daily_tasks/${id}`, task);
  toast.success(i18n.t('form:updatedSuccessfully'));
  return response.data;
});

export const uploadVideo = createAsyncThunk<
  VimeoApiResponse,
  { file: FileType | null; name: string }
>('createSessionAction/uploadVideo', async (video) => {
  const response = await axios.post(
    `${process.env.REACT_APP_VIMEO_API_URL}`,
    {
      upload: { approach: 'tus', size: video.file?.size },
      name: video.name,
      privacy: { view: 'disable' },
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    },
  );
  const uploadUrl = response.data.upload.upload_link;

  await axios.patch(`${uploadUrl}`, video.file, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
      'Content-Type': 'application/offset+octet-stream',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
      'Tus-Resumable': '1.0.0',
      'Upload-Offset': '0',
    },
  });

  return response.data;
});

export const deleteVideo = createAsyncThunk<void, { videoId: string }>(
  'createSessionAction/deleteVideo',
  async ({ videoId }) => {
    if (process.env.NODE_ENV === 'development') return;
    const response = await axios.delete(`https://api.vimeo.com/videos/${videoId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
      },
    });
    return response.data;
  },
);

export const createPill = createAsyncThunk<IPill, PillFormType>(
  'createSessionAction/createPill',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const formData = { file: data.file, name: data.name };
      const res = await dispatch(uploadVideo(formData));

      const { id, file, videoId, ...newData } = data;
      const vimeoId = (res.payload as VimeoApiResponse).uri.replace('/videos/', '');
      const newPill = { videoId: vimeoId, ...newData };
      const response = await usersAPI.post('/api/admin/pills', newPill);

      await dispatch(uploadPillLogo({ logo: data.logo, id: response.data.id, isEditMode: false }));

      await dispatch(
        updateExclusiveCompanies({
          id: response.data.id,
          exclusiveCompanies: data.exclusiveCompanies,
          type: 'pill',
          isExclusive: data.exclusive,
          isAlreadyExclusive: response.data.exclusive,
        }),
      ).unwrap();

      toast.success(i18n.t('form:createdSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updatePill = createAsyncThunk<IPill, { id: string; data: PillFormType }>(
  'createSessionAction/updatePill',
  async ({ id, data }, { dispatch, rejectWithValue }) => {
    try {
      const formData = { file: data.file, name: data.name };
      let response;
      if (data.file) {
        const { videoId, isEditMode, file, ...newData } = data;
        const res = await dispatch(uploadVideo(formData));
        const vimeoId = (res.payload as VimeoApiResponse).uri.replace('/videos/', '');
        const newPill = { videoId: vimeoId, ...newData };
        response = await usersAPI.patch(`/api/admin/pills/${id}`, newPill);
      } else {
        const { file, isEditMode, ...newData } = data;
        response = await usersAPI.patch(`/api/admin/pills/${id}`, newData);
      }

      await dispatch(
        updateExclusiveCompanies({
          id,
          exclusiveCompanies: data.exclusiveCompanies,
          type: 'pill',
          isExclusive: data.exclusive,
          isAlreadyExclusive: response.data.exclusive,
        }),
      ).unwrap();

      toast.success(i18n.t('form:updatedSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const createNugget = createAsyncThunk<
  Nugget,
  { goal: number; description: string; type: string }
>('createSessionAction/createNugget', async (nugget) => {
  const response = await usersAPI.post('/api/admin/goal_nuggets', nugget);
  toast.success(i18n.t('form:createdSuccessfully'));
  return response.data;
});

export const createTest = createAsyncThunk<ITest, TestFormType>(
  'createSessionAction/createTest',
  async (test, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersAPI.post('/api/admin/tests', test);
      const { exclusiveCompanies, exclusive } = test;

      await dispatch(
        updateExclusiveCompanies({
          id: response.data.id,
          exclusiveCompanies,
          type: 'test',
          isExclusive: exclusive,
          isAlreadyExclusive: response.data.exclusive,
        }),
      ).unwrap();

      toast.success(i18n.t('form:createdSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateTest = createAsyncThunk<ITest, TestFormType>(
  'createSessionAction/updateTest',
  async (test, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersAPI.patch(`/api/admin/tests/${test.id}`, test, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      });
      const { id, exclusiveCompanies, exclusive } = test;

      await dispatch(
        updateExclusiveCompanies({
          id,
          exclusiveCompanies,
          type: 'test',
          isExclusive: exclusive,
          isAlreadyExclusive: response.data.exclusive,
        }),
      ).unwrap();

      toast.success(i18n.t('form:updatedSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateNugget = createAsyncThunk<
  Nugget,
  { id: string; nugget: { goal: number; description: string; type: string } }
>('createSessionAction/updateNugget', async ({ id, nugget }) => {
  const response = await usersAPI.patch(`/api/admin/goal_nuggets/${id}`, nugget);
  toast.success(i18n.t('form:updatedSuccessfully'));
  return response.data;
});

export const updateSession = createAsyncThunk<
  Session,
  { id: string; newSession: ConvertedSession }
>('createSessionAction/updateSession', async ({ id, newSession }, { rejectWithValue }) => {
  try {
    const response = await usersAPI.patch(`/api/admin/session_templates/${id}`, newSession);
    toast.success(i18n.t('form:updatedSuccessfully'));
    return response.data;
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});

export const updateQuestionAndAnswers = createAsyncThunk<
  void,
  { test: number; data: Question; type: TestTypeAPI }
>('createSessionAction/updateQuestionAndAnswers', async ({ test, data, type }) => {
  const { question, answers, explanation } = data;
  const response = await usersAPI.patch(
    `/api/admin/test_questions/${type}/${data.id}`,
    { test, question, answers, explanation },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  );
  toast.success(i18n.t('form:updatedSuccessfully'));
  return response.data;
});

export const createQuestionAndAnswers = createAsyncThunk<
  void,
  { test: number; data: Question; type: TestTypeAPI }
>('createSessionAction/createQuestionAndAnswers', async ({ test, data, type }) => {
  const { question, answers, explanation } = data;
  const formData = { test, question, answers, explanation };
  const response = await usersAPI.post(`/api/admin/test_questions/${type}`, formData);
  toast.success(i18n.t('form:createdSuccessfully'));
  return response.data;
});

export const getVideoStatus = createAsyncThunk<VimeoApiResponse, { id: string | undefined }>(
  'createSessionAction/getVideoStatus',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://api.vimeo.com/videos/${id}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
        },
      });
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const fetchExternalRewards = createAsyncThunk<
  {
    pagination: PaginationType;
    results: ExternalReward[];
  },
  PaginationOptions
>('createSessionAction/fetchExternalRewards', async ({ id, page, pagination, search }) => {
  const params = {};
  page && Object.assign(params, { page });
  id && Object.assign(params, { id });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI(`/api/admin/external_rewards`, { params });
  return response.data;
});

export const uploadRewardLogo = createAsyncThunk<
  ExternalReward & { isEditMode: boolean },
  { logo: string; id: number; isEditMode: boolean }
>('createSessionAction/uploadRewardLogo', async (data, { rejectWithValue }) => {
  try {
    const { id, logo, isEditMode } = data;
    const response = await usersAPI.patch(`/api/admin/external_rewards/${id}/photo`, {
      logo,
    });
    return { ...response.data, isEditMode };
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});

export const uploadPillLogo = createAsyncThunk<
  IPill & { isEditMode: boolean },
  { logo: string; id: string; isEditMode: boolean }
>('createSessionAction/uploadPillLogo', async (data, { rejectWithValue }) => {
  try {
    const { id, logo, isEditMode } = data;
    const response = await usersAPI.patch(`/api/admin/pills/${id}/photo`, {
      logo,
    });
    return { ...response.data, isEditMode };
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});

export const createExternalReward = createAsyncThunk<ExternalReward, ExternalReward>(
  'createSessionAction/createExternalReward',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await usersAPI.post('/api/admin/external_rewards', data);
      await dispatch(
        uploadRewardLogo({ logo: data.logo, id: response.data.id, isEditMode: false }),
      );
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateExternalReward = createAsyncThunk<ExternalReward, ExternalReward>(
  'createSessionAction/updateExternalReward',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.patch(`/api/admin/external_rewards/${data.id}`, data);
      toast.success(i18n.t('form:updatedSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);
