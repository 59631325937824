import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { MIN_PASSWORD_LENGTH } from '../constants';
import { convertErrorMessage } from '../utils';
import { StyledSpinner } from './StyledSpinner';
import { LoginFormValues } from 'constants/forms';
import { signInUser } from 'redux/userAuth/userAuthActions';
import { userAuthSelector } from 'redux/userAuth/userAuthSlice';

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const { isSingIn, errorMessage, isFetching } = useSelector(userAuthSelector);
  const { t } = useTranslation('form');
  const history = useHistory();

  const validationSchema = Yup.object({
    username: Yup.string()
      .email(t('emailValidationMessage'))
      .required(t('requiredValidationMessage')),
    password: Yup.string()
      .required(t('noPasswordProvided'))
      .min(MIN_PASSWORD_LENGTH, t('passwordTooShort', { MIN_PASSWORD_LENGTH }))
      .matches(/[a-zA-Z]/, t('onlyLetters')),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm<LoginFormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const showAlert = (
    <Box mt={2}>
      <Alert severity="error">{convertErrorMessage(errorMessage)}</Alert>
    </Box>
  );

  const onSubmit = (values: LoginFormValues) => {
    dispatch(signInUser(values));
  };

  useEffect(() => {
    if (isSingIn) {
      history.push('/');
    }
  }, [isSingIn, history, dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        id="username"
        margin="normal"
        fullWidth
        label={t('email')}
        placeholder={t('emailPlaceholder')}
        {...register('username')}
        error={touchedFields.username && !!errors.username?.message}
        helperText={(touchedFields.username && errors.username?.message) || ''}
      />
      <TextField
        id="password"
        fullWidth
        label={t('password')}
        placeholder={t('passwordPlaceholder')}
        type="password"
        {...register('password')}
        error={touchedFields.password && !!errors.password?.message}
        helperText={(touchedFields.password && errors.password?.message) || ' '}
      />

      {!!errorMessage && showAlert}
      <Box mt={2}>
        <Button
          disabled={!isValid || !isDirty}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          {isFetching ? <StyledSpinner /> : t('login')}
        </Button>
      </Box>
    </form>
  );
};
