import { Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { default as Tab } from '@material-ui/core/Tab';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { CompaniesPage } from './CompaniesPage';
import { CreateCompany } from './CreateCompany';
import { CreateInsight } from './CreateInsight';
import { InsightsPage } from './InsightsPage';
import { TabPanelProps } from 'types';
import { MainWrapper } from 'wrappers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      maxWidth: '1400px',
      alignSelf: 'center',
      marginBottom: '20px',
    },
    indicator: { background: theme.palette.gradients[400], minHeight: '3px' },
  }),
);

export const CompaniesCard: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('tabs');
  const { url, path } = useRouteMatch();
  const pathVariable = window.location.pathname;

  const [value, setValue] = React.useState<number>(0);

  useEffect(() => {
    if (pathVariable.includes('companies-dashboard/companies') && value !== 0) setValue(0);
    else if (pathVariable.includes('/insights') && value !== 1) setValue(1);
  }, [pathVariable, value]);

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <MainWrapper>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Card className={classes.wrapper}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="companies tabs"
              centered
              scrollButtons
              allowScrollButtonsMobile
              TabIndicatorProps={{ className: classes.indicator }}
            >
              <Tab label={t('companies')} component={Link} to={`${url}/companies`} />
              <Tab label={t('insights')} component={Link} to={`${url}/insights`} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Route exact path={`${path}/companies`} component={CompaniesPage} />
            <Route exact path={`${path}/companies/:id`} component={CreateCompany} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Route exact path={`${path}/insights`} component={InsightsPage} />
            <Route exact path={`${path}/insights/:id`} component={CreateInsight} />
          </TabPanel>
        </Card>
      </Grid>
    </MainWrapper>
  );
};
