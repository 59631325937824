import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { CellProps } from 'react-table';

import { Question } from 'types';

export const QUESTIONS_COLUMN = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '10%',
  },
  {
    Header: 'Question',
    accessor: 'question',
    width: '40%',
  },
  {
    Header: 'Answers',
    accessor: 'answers',
    width: '40%',
    Cell: (props: CellProps<Question>): JSX.Element => {
      return (
        <div style={{ flex: 1, width: '100%' }}>
          {props.row.original.answers.map((x) => (
            <li>{x.answer}</li>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Options',
    accessor: 'test',
    width: '10%',
    Cell: (props: CellProps<Question>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
          onClick={() => props.onEdit(id)}
        >
          <EditIcon fontSize="large" />
        </Button>
      );
    },
  },
];
