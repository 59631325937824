import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CustomTablePagination } from 'components';
import { PILLS_COLUMN } from 'constants/columns/pillsColumns';
import { fetchPills } from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';

export const PillsPage: React.FC = () => {
  const columns = React.useMemo(() => PILLS_COLUMN, []);
  const { pills, loading } = useSelector(createSessionSelector);
  const { t } = useTranslation('createPill');
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleButton = () => {
    history.push(`${path}/create`);
  };

  const onEdit = async (id: string) => {
    history.push(`${path}/${id}`);
  };

  const buttonProps = {
    title: t('createPill'),
    onCreate: handleButton,
  };

  return (
    <div>
      <CustomTablePagination
        columns={columns}
        data={pills}
        onEdit={onEdit}
        isLoading={loading}
        createButtonProps={buttonProps}
        fetchData={fetchPills}
      />
    </div>
  );
};
