import { createSlice } from '@reduxjs/toolkit';

import { IUserSessions } from './../../types/index';
import { fetchSessions } from './sessionAction';
import { RootState } from 'redux/store';

export interface ISessionState {
  sessions: IUserSessions[];
  isLoading: boolean;
  errorMessage: string;
}

const initialState: ISessionState = {
  sessions: [],
  isLoading: false,
  errorMessage: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessions = action.payload;
      })
      .addCase(fetchSessions.rejected, (state, action: any) => {
        state.errorMessage = action.error;
      });
  },
});

export const sessionSliceSelector = (state: RootState) => state.sessions;
export default sessionSlice.reducer;
