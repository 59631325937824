import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import {
  createCompany,
  createInsight,
  createInsightLabel,
  fetchAllCompaniesActivity,
  fetchCompanies,
  fetchCompany,
  fetchCompanyActivity,
  fetchExclusiveCompanies,
  fetchInsightLabels,
  fetchInsights,
  updateCompany,
  updateInsight,
} from './companiesActions';
import { RootState } from 'redux/store';
import {
  Company,
  CompanyActivity,
  ExclusiveCompany,
  Insight,
  InsightLabel,
  PaginationType,
} from 'types';
import { convertErrorMessages } from 'utils';

interface IUsersState {
  company: Company;
  companies: { pagination: PaginationType; results: Company[] };
  insights: { pagination: PaginationType; results: Insight[] };
  insightLabels: InsightLabel[];
  companyActivity: CompanyActivity[];
  exclusiveCompanies: ExclusiveCompany[];
  error: string;
  loading: {
    fetchCompanies: boolean;
    fetchInsights: boolean;
    fetchInsightLabels: boolean;
    createInsightLabel: boolean;
    createInsight: boolean;
    createCompany: boolean;
    updateCompany: boolean;
    updateInsight: boolean;
    fetchCompanyActivity: boolean;
    fetchCompany: boolean;
    fetchExclusiveCompaniesLoading: boolean;
  };
}

const initialState: IUsersState = {
  company: {
    id: 0,
    name: '',
    accessCode: '',
    status: 'active',
    email: '',
    memberships: 1,
    size: '>25',
    createdAt: '2022-03-14T21:17:24+00:00',
    insights: [],
  },
  companies: { pagination: { page: 1, allPages: 1 }, results: [] },
  insights: { pagination: { page: 1, allPages: 1 }, results: [] },
  insightLabels: [],
  companyActivity: [],
  exclusiveCompanies: [],
  error: '',
  loading: {
    fetchCompanies: false,
    fetchInsights: false,
    fetchInsightLabels: false,
    createInsightLabel: false,
    createInsight: false,
    createCompany: false,
    updateCompany: false,
    updateInsight: false,
    fetchCompanyActivity: false,
    fetchCompany: false,
    fetchExclusiveCompaniesLoading: false,
  },
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading.fetchCompanies = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action: any) => {
        state.loading.fetchCompanies = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchCompanies.pending, (state) => {
        state.error = '';
        state.loading.fetchCompanies = true;
      })
      .addCase(fetchInsights.fulfilled, (state, action) => {
        state.loading.fetchInsights = false;
        state.insights = action.payload;
      })
      .addCase(fetchInsights.rejected, (state, action: any) => {
        state.loading.fetchInsights = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchInsights.pending, (state) => {
        state.error = '';
        state.loading.fetchInsights = true;
      })
      .addCase(fetchInsightLabels.fulfilled, (state, action) => {
        state.loading.fetchInsightLabels = false;
        state.insightLabels = action.payload;
      })
      .addCase(fetchInsightLabels.rejected, (state, action: any) => {
        state.loading.fetchInsightLabels = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchInsightLabels.pending, (state) => {
        state.error = '';
        state.loading.fetchInsightLabels = true;
      })
      .addCase(createInsightLabel.fulfilled, (state, action) => {
        state.loading.createInsightLabel = false;
        state.insightLabels.push(action.payload);
      })
      .addCase(createInsightLabel.rejected, (state, action: any) => {
        state.loading.createInsightLabel = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(createInsightLabel.pending, (state) => {
        state.error = '';
        state.loading.createInsightLabel = true;
      })
      .addCase(createInsight.fulfilled, (state, action) => {
        state.loading.createInsight = false;
        state.insights.results.push(action.payload);
      })
      .addCase(createInsight.rejected, (state, action: any) => {
        state.loading.createInsight = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(createInsight.pending, (state) => {
        state.error = '';
        state.loading.createInsight = true;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.loading.createCompany = false;
        state.companies.results.push(action.payload);
      })
      .addCase(createCompany.rejected, (state, action: any) => {
        state.loading.createCompany = false;
        toast.error(convertErrorMessages(action.payload));
        state.error = convertErrorMessages(action.payload);
      })
      .addCase(createCompany.pending, (state) => {
        state.error = '';
        state.loading.createCompany = true;
      })
      .addCase(updateCompany.fulfilled, (state, action: PayloadAction<Company>) => {
        const { id } = action.payload;
        const foundId = state.companies.results.findIndex((x) => x.id === id);
        state.companies.results[foundId] = action.payload;
        state.company = action.payload;
        state.loading.updateCompany = false;
      })
      .addCase(updateCompany.rejected, (state, action: any) => {
        state.loading.updateCompany = false;
        toast.error(convertErrorMessages(action.payload));
        state.error = convertErrorMessages(action.payload);
      })
      .addCase(updateCompany.pending, (state) => {
        state.error = '';
        state.loading.updateCompany = true;
      })
      .addCase(updateInsight.fulfilled, (state, action) => {
        const { id } = action.payload;
        const foundId = state.insights.results.findIndex((x) => x.id === id);
        state.insights.results[foundId] = action.payload;
        state.loading.updateInsight = false;
      })
      .addCase(updateInsight.rejected, (state, action) => {
        state.loading.updateInsight = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(updateInsight.pending, (state) => {
        state.error = '';
        state.loading.updateInsight = true;
      })
      .addCase(fetchCompanyActivity.fulfilled, (state, action) => {
        state.companyActivity = action.payload;
        state.loading.fetchCompanyActivity = false;
      })
      .addCase(fetchCompanyActivity.rejected, (state, action) => {
        state.loading.fetchCompanyActivity = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchCompanyActivity.pending, (state) => {
        state.error = '';
        state.loading.fetchCompanyActivity = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.loading.fetchCompany = false;
        state.company = action.payload;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.loading.fetchCompany = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchCompany.pending, (state) => {
        state.error = '';
        state.loading.fetchCompany = true;
      })
      .addCase(fetchExclusiveCompanies.fulfilled, (state, action) => {
        state.loading.fetchExclusiveCompaniesLoading = false;
        const companies = action.payload.map((i) => i.company);
        state.exclusiveCompanies = companies;
      })
      .addCase(fetchExclusiveCompanies.rejected, (state, action) => {
        state.loading.fetchExclusiveCompaniesLoading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchExclusiveCompanies.pending, (state) => {
        state.error = '';
        state.loading.fetchExclusiveCompaniesLoading = true;
      })
      .addCase(fetchAllCompaniesActivity.fulfilled, (state, action) => {
        state.companyActivity = action.payload;
        state.loading.fetchCompanyActivity = false;
      })
      .addCase(fetchAllCompaniesActivity.rejected, (state, action) => {
        state.loading.fetchCompanyActivity = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchAllCompaniesActivity.pending, (state) => {
        state.error = '';
        state.loading.fetchCompanyActivity = true;
      }),
});

export const companiesSelector = (state: RootState) => state.companies;

export default companiesSlice.reducer;
