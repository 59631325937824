import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useSidebar } from 'context/SidebarContext';
import { FiMenu } from 'react-icons/fi';

import { ProfileSection } from './ProfileSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'fixed',
      top: '0',
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      padding: '3rem 2rem',
      zIndex: 100,
      transition: '0.2s all',
    },
    hamburgerMenu: {
      fontSize: '3rem',
      marginRight: '10px',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  }),
);

export const Topbar: React.FC = () => {
  const classes = useStyles();
  const { handleToggleSidebar } = useSidebar();

  return (
    <nav className={clsx(classes.root, {})}>
      <FiMenu className={classes.hamburgerMenu} onClick={handleToggleSidebar} />
      <Box sx={{ flex: 1 }} />
      <ProfileSection />
    </nav>
  );
};
