import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { Box, FormControl, MenuItem } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CropImage, CustomLoader, DragAndDrop, HelpIcon } from 'components';
import { CustomAlert } from 'components/CustomAlert';
import { ErrorAlert } from 'components/ErrorBoundary';
import { ExclusiveContent } from 'components/ExclusiveContent/ExclusiveContent';
import { pillCategoriesData, PILL_CATEGORIES } from 'constants/mock';
import { fetchCompanies, fetchExclusiveCompanies } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import {
  createPill,
  deleteVideo,
  fetchPills,
  getVideoStatus,
  updatePill,
  uploadPillLogo,
} from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { AppDispatch } from 'redux/store';
import { ExclusiveCompany, FileType, PillCategories } from 'types';
import { getVimeoStatus, removeImgExtension } from 'utils/helperFunctions';

export type PillFormType = {
  id: number;
  file: FileType | null;
  name: string;
  duration: number;
  category: PillCategories;
  videoId: string;
  transcription?: string;
  memberName: string;
  isEditMode: boolean;
  rawImg: string;
  logo: string;
  exclusive: boolean;
  exclusiveCompanies: ExclusiveCompany[];
};

export const CreatePill: React.FC = () => {
  const {
    pills,
    loading: pillsLoading,
    error: pillsError,
    isSubmitted,
  } = useSelector(createSessionSelector);
  const {
    companies,
    exclusiveCompanies,
    loading: { fetchCompanies: fetchCompaniesLoading, fetchExclusiveCompaniesLoading },
    error: companiesError,
  } = useSelector(companiesSelector);
  const { id } = useParams<{ id: string }>();
  const selectedPill = pills.results.find((pill) => pill.id === parseInt(id));
  const { t } = useTranslation(['createPill', 'errors', 'admin']);

  const isEditMode = Number.isInteger(parseInt(id));
  const [videoError, setVideoError] = useState<string>('');
  const [playerError, setPlayerError] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const schema = yup
    .object({
      name: yup.string().required(),
      duration: yup.number().required().min(1).max(10000),
      category: yup.string().required(),
      memberName: yup.string(),
      logo: yup.string().required(),
      transcription: yup.string().when('category', {
        is: (value: PillCategories) => value === 'AUDIO',
        then: (rule) => rule.required(),
      }),

      file: yup.mixed().when('isEditMode', {
        is: false,
        then: yup.mixed().test('name', t('fileRequired'), (value) => {
          return value && value.name;
        }),
      }),
      exclusive: yup.boolean().required(),
      exclusiveCompanies: yup.array().when('exclusive', { is: true, then: yup.array().min(1) }),
    })
    .required();

  const useFormMethods = useForm<PillFormType>({
    defaultValues: {
      id: 0,
      name: '',
      file: null,
      duration: 0,
      category: 'VIDEO',
      isEditMode: false,
      exclusive: false,
      exclusiveCompanies: [],
    },
    resolver: yupResolver(schema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    clearErrors,
    setError,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useFormMethods;

  const supportedTypes = ['png', 'jpg', 'jpeg'];
  const formValue = watch();

  const isLoading = pillsLoading || fetchCompaniesLoading || fetchExclusiveCompaniesLoading;
  const error = pillsError || companiesError;

  useEffect(() => {
    dispatch(fetchCompanies({ pagination: false }));
    if (isEditMode) {
      dispatch(fetchPills({ id: parseInt(id) }));
      dispatch(fetchExclusiveCompanies({ id, type: 'pill' }));
    }
  }, [dispatch, id, isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      if (selectedPill) {
        const {
          id,
          name,
          duration,
          category,
          transcription,
          memberName,
          videoId,
          logo,
          exclusive,
        } = selectedPill;
        setValue('id', id);
        setValue('name', name);
        setValue('duration', duration);
        setValue('category', category);
        setValue('transcription', transcription);
        setValue('memberName', memberName);
        setValue('videoId', videoId);
        setValue('logo', logo);
        setValue('isEditMode', true);
        setValue('exclusive', exclusive);
      }
    }
  }, [id, isEditMode, pills, selectedPill, setValue]);

  useEffect(() => {
    if (isSubmitSuccessful && !isLoading && !error && isSubmitted) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, isLoading, isSubmitted]);

  const handleSelectChange = (e: any) => {
    setValue('category', e.target.value);
  };

  const onSubmit = handleSubmit((data: PillFormType) => {
    const { videoId, logo, file, rawImg } = data;
    if (isEditMode) {
      file && dispatch(deleteVideo({ videoId }));
      dispatch(updatePill({ id, data }));
      (rawImg || logo) && dispatch(uploadPillLogo({ logo, id, isEditMode }));
    } else {
      dispatch(createPill(data));
    }
  });

  const handleChange = (file: FileType) => {
    setValue('file', file, { shouldValidate: true });
  };

  const handleImgUpload = async (logo: File) => {
    const r = new FileReader();
    r.readAsDataURL(logo);
    r.onload = function () {
      clearErrors('logo');
      setValue('rawImg', removeImgExtension(r.result as string), {
        shouldValidate: true,
      });
    };
  };

  const handleVideoStatus = async () => {
    if (selectedPill) {
      const data = await dispatch(getVideoStatus({ id: selectedPill.videoId })).unwrap();
      if (data.status !== 'available') setVideoError(getVimeoStatus(data.status));
    }
  };

  useEffect(() => {
    handleVideoStatus();
  }, [selectedPill?.videoId]);

  if (isLoading) return <CustomLoader />;

  if (isEditMode && !selectedPill) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      {error && (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CustomAlert description={error} severity="error" />
        </Grid>
      )}
      <FormProvider {...useFormMethods}>
        <form onSubmit={onSubmit}>
          <Typography variant="h2" gutterBottom>
            {isEditMode ? t('editPill') : t('createPill')}
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label={t('name')}
            {...register('name')}
            helperText={errors.name?.message}
            FormHelperTextProps={{ style: { color: 'red' } }}
          />
          <TextField
            margin="normal"
            fullWidth
            label={t('duration')}
            {...register('duration')}
            helperText={errors.duration?.message}
            FormHelperTextProps={{ style: { color: 'red' } }}
          />
          <TextField
            margin="normal"
            fullWidth
            label={t('memberName')}
            {...register('memberName')}
            helperText={errors.memberName?.message}
            FormHelperTextProps={{ style: { color: 'red' } }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-category-pill">{t('category')}</InputLabel>
            <Controller
              control={control}
              name="category"
              render={({ field: { value } }) => {
                return (
                  <Select
                    onChange={handleSelectChange}
                    labelId="select-category-pill"
                    id="select-category-pill"
                    label="category"
                    MenuProps={{ disableScrollLock: true }}
                    value={value}
                  >
                    {PILL_CATEGORIES.map((category) => (
                      <MenuItem
                        key={category.name}
                        value={category.name}
                        sx={{ fontSize: '1.6rem' }}
                      >
                        {pillCategoriesData[category.name].label}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>

          <ExclusiveContent
            companies={companies.results}
            alreadyChosenCompanies={isEditMode ? exclusiveCompanies : []}
            isAlreadyExclusive={!!selectedPill?.exclusive}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="transcription-label">{t('transcription')}</InputLabel>
            <TextareaAutosize
              aria-label="transcription"
              minRows={4}
              {...register('transcription')}
              placeholder="Transcription"
              style={{ fontSize: '16px', fontFamily: 'MarkPro', padding: '6px 20px 7px 20px' }}
            />
            <FormHelperText error>{errors.transcription?.message}</FormHelperText>
          </FormControl>

          {formValue.videoId && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="current-file">{t('currentFile')}</InputLabel>
              {videoError && <CustomAlert description={videoError} />}
              {playerError && !videoError && <CustomAlert description={t('vimeo:noVideo')} />}

              <Vimeo
                video={formValue.videoId}
                onError={(e) => {
                  setPlayerError(e.message);
                }}
              />
            </FormControl>
          )}

          <FormControl fullWidth margin="normal">
            <InputLabel id="select-file">
              {isEditMode ? t('changeFile') : t('uploadFile')}
            </InputLabel>
            <FileUploader handleChange={handleChange} name="file" types={['MP4']} />

            <FormHelperText error>{errors.file && t('fileRequired')}</FormHelperText>
          </FormControl>

          <FormControl margin="normal" style={{ maxWidth: '100%' }}>
            <InputLabel id="select-logo">
              {t('logo')}
              <HelpIcon title={t('tooltip:pillLogo')} style={{ position: 'relative', top: 5 }} />
            </InputLabel>

            {(formValue.rawImg || formValue.logo) && (
              <Box>
                <CropImage
                  file={formValue.rawImg}
                  onChange={(logo) => {
                    setValue('logo', logo);
                  }}
                  isEditMode={isEditMode}
                  logo={formValue.logo}
                  maxHeight={300}
                  maxWidth={300}
                  minHeight={250}
                  minWidth={250}
                  aspect={1}
                  compress
                />
              </Box>
            )}
          </FormControl>

          {isEditMode && <InputLabel id="change-logo">{t('changeLogo')}</InputLabel>}
          <Box
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              width: '300px',
            }}
          >
            {(!formValue.logo || isEditMode) && (
              <FileUploader
                hoverTitle={' '}
                children={<DragAndDrop />}
                handleChange={handleImgUpload}
                name="logo"
                maxSize={0.5}
                types={supportedTypes}
                onSizeError={(err: string) =>
                  setError('logo', {
                    type: 'custom',
                    message: err,
                  })
                }
                onTypeError={(err: string) =>
                  setError('logo', {
                    type: 'custom',
                    message: err + `. Supported types: ${supportedTypes.map((type) => type)}`,
                  })
                }
              />
            )}
            <FormHelperText error>{errors.logo?.message}</FormHelperText>
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <Button variant="outlined" type="button" onClick={history.goBack}>
              {t('admin:goBack')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: '20px' }}
            >
              {t(isEditMode ? 'updatePill' : 'createPill')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
};
