import { Card, makeStyles, Typography } from '@material-ui/core';
import { Avatar, Box, CardContent } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { usersSelector } from 'redux/users/usersSlice';
import { IUser } from 'types';
import { formatBirthday } from 'utils/helperFunctions';

import avatar1 from 'assets/images/avatars/avatarHead1.png';
import avatar2 from 'assets/images/avatars/avatarHead2.png';
import avatar3 from 'assets/images/avatars/avatarHead3.png';
import avatar4 from 'assets/images/avatars/avatarHead4.png';
import avatar5 from 'assets/images/avatars/avatarHead5.png';
import avatar6 from 'assets/images/avatars/avatarHead6.png';

interface IAvatars {
  [x: number]: string;
}

const avatars: IAvatars = {
  1: avatar1,
  2: avatar2,
  3: avatar3,
  4: avatar4,
  5: avatar5,
  6: avatar6,
};

interface IUserProfileProps {
  activeUser: IUser;
}

const useStyles = makeStyles({
  wrapper: {
    gridColumn: '1 / 2',
    gridRow: '1 / 4',
  },
  content: { display: 'flex', flexDirection: 'column', height: '100%' },
  avatarContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 },
  detailsContainer: { flex: 1, paddingBottom: '250px' },
  detailsContainerItem: {
    marginBottom: '10px',
  },
});

export const UserProfile: React.FC<IUserProfileProps> = ({ activeUser }) => {
  const classes = useStyles();
  const { t } = useTranslation(['userProfile', 'errors']);
  const { userProfiles } = useSelector(usersSelector);

  const profile = userProfiles?.[0] ?? 'loading';

  const avatarImage = avatars[profile.avatarId];

  const { email, firstName, birthday, accessCode, createdAt } = activeUser;

  return (
    <Card className={classes.wrapper}>
      <CardContent className={classes.content}>
        <Box className={classes.avatarContainer}>
          <Avatar
            sx={{ height: '200px', width: '200px', marginBottom: '10px', position: 'static' }}
            alt="Demo"
            src={avatarImage}
          />
          <Typography variant="h2">{firstName}</Typography>
        </Box>
        <Box className={classes.detailsContainer}>
          <Box className={classes.detailsContainerItem}>
            <Typography variant="h4">{t('email')}</Typography>
            <Typography variant="subtitle1">{email} </Typography>
          </Box>
          <Box className={classes.detailsContainerItem}>
            <Typography variant="h4">{t('birthDay')}</Typography>
            <Typography variant="subtitle1">
              {birthday ? formatBirthday(birthday) : t('errors:noDate')}
            </Typography>
          </Box>

          <Box className={classes.detailsContainerItem}>
            <Typography variant="h4">{t('accessCode')}</Typography>
            <Typography variant="subtitle1">{accessCode}</Typography>
          </Box>

          <Box className={classes.detailsContainerItem}>
            <Typography variant="h4">{t('createdAt')}</Typography>
            <Typography variant="subtitle1">{format(new Date(createdAt), 'yyyy-MM-dd')}</Typography>
          </Box>

          <Box className={classes.detailsContainerItem}>
            <Typography variant="h4">{t('lastSeen')}</Typography>
            <Typography variant="subtitle1">
              {profile.loginTimestamp && format(new Date(profile.loginTimestamp), 'yyyy-MM-dd')}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
