import { Theme } from '@material-ui/core/styles';

export default function Input(theme: Theme) {
  return {
    MuiInput: {
      root: {
        borderRadius: 5,
        height: 48,
        padding: '0 20px',
        fontFamily: 'MarkProMedium',
        fontSize: '1.4rem',
        border: `2px solid  ${theme.palette.grey[200]}`,
        '&::placeholder': {
          opacity: 1,
          color: theme.palette.text.disabled,
        },

        '&:hover': {
          border: `2px solid  ${theme.palette.common.black}`,
        },

        '&.Mui-error': {
          border: `2px solid red `,
        },

        '&.Mui-focused': {
          border: `2px solid  ${theme.palette.grey[300]}`,
        },

        '&.Mui-disabled': {
          border: `2px solid  ${theme.palette.grey[300]}`,
        },
      },

      input: {},
    },

    MuiInputLabel: {
      root: {
        fontFamily: 'MarkProMedium',
        fontSize: '1.6rem',
        marginBottom: 5,
        fontWeight: 'normal',

        '&.Mui-disabled': {
          background: 'transparent',
          border: `none`,
        },
      },
      formControl: {
        top: -5,
      },
    },
  };
}
