import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUserSessions } from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchUserSessions = createAsyncThunk<IUserSessions[], string>(
  'usersSessions/fetchUsersSessions',
  async (userId) => {
    const response = await usersAPI(`/api/admin/sessions?user.id=${userId}&pagination=false`);
    return response.data.results;
  },
);
