import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';

import { UsersTable } from 'components';
import { fetchUsers } from 'redux/users/usersActions';
import { MainWrapper } from 'wrappers';

export const UsersPage: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(fetchUsers({}));
    });
  }, [dispatch]);
  return (
    <MainWrapper>
      <UsersTable />
    </MainWrapper>
  );
};
