import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import i18n from 'translation/i18n';
import * as yup from 'yup';

import { CustomLoader } from 'components';
import { CustomAlert } from 'components/CustomAlert';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import { COMPANY_SIZE, COMPANY_STATUS } from 'constants/mock';
import { createCompany, fetchCompanies, updateCompany } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { Company, CompanySize, CompanyStatus } from 'types';

type CreateParams = { id: string };

export const CreateCompany: React.FC = () => {
  const { companies, loading, error } = useSelector(companiesSelector);
  const { id } = useParams<CreateParams>();
  const { t } = useTranslation('companies');

  const isEditMode = Number.isInteger(parseInt(id));
  const selectedCompany = companies.results.find((company) => company.id === parseInt(id));
  const isLoading = loading.fetchCompanies || loading.createCompany || loading.updateCompany;

  useEffect(() => {
    if (!selectedCompany && isEditMode) dispatch(fetchCompanies({ id: parseInt(id) }));
  }, [id]);

  const schema = yup
    .object({
      name: yup.string().required(),
      email: yup.string().required().email(t('validation:invalidEmail')),
      accessCode: yup.string().required(),
    })
    .required();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Company>({
    defaultValues: {
      id: undefined,
      name: '',
      accessCode: '',
      email: '',
      status: 'active',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEditMode) {
      if (selectedCompany) {
        const { id, name, email, memberships, accessCode, size, status } = selectedCompany;
        setValue('id', id);
        setValue('name', name);
        setValue('email', email);
        setValue('memberships', memberships);
        setValue('accessCode', accessCode);
        setValue('size', size);
        setValue('status', status.toLowerCase() as CompanyStatus);
      }
    }
  }, [id, isEditMode, companies, selectedCompany, setValue]);

  const onSubmit = handleSubmit((data: Company) => {
    if (isEditMode) {
      dispatch(updateCompany({ id: parseInt(id), data }));
    } else {
      dispatch(createCompany(data));
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful && !isLoading && !error) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, isLoading, error]);

  if (isLoading) return <CustomLoader />;

  if (
    error &&
    error !== i18n.t('errors:invalidAccessCode') &&
    error !== i18n.t('errors:invalidCompanyName')
  )
    return <ShowError error={error} />;
  if (isEditMode && !selectedCompany) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" gutterBottom>
          {isEditMode ? t('editCompany') : t('createCompany')}
        </Typography>

        {error && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <CustomAlert description={error} severity="error" />
          </Grid>
        )}

        <TextField
          margin="normal"
          fullWidth
          label={t('name')}
          {...register('name')}
          helperText={errors.name?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />

        <TextField
          margin="normal"
          fullWidth
          label={t('email')}
          {...register('email')}
          helperText={errors.email?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />

        <TextField
          margin="normal"
          fullWidth
          type="number"
          label={t('memberships')}
          {...register('memberships', { valueAsNumber: true })}
          helperText={errors.memberships?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />

        <TextField
          margin="normal"
          fullWidth
          label={t('accessCode')}
          {...register('accessCode')}
          helperText={errors.accessCode?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="size-label">{t('size')}</InputLabel>
          <Controller
            control={control}
            name="size"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const companySize = e.target.value;
                    setValue('size', companySize as CompanySize);
                  }}
                  value={value ?? ''}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="size-select-label"
                  id="size-select"
                >
                  {COMPANY_SIZE.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>{errors.size?.message}</FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="select-status-label">{t('status')}</InputLabel>
          <Controller
            control={control}
            name="status"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => setValue('status', e.target.value as CompanyStatus)}
                  labelId="select-status"
                  id="select-status"
                  label="status"
                  MenuProps={{ disableScrollLock: true }}
                  value={value ?? ''}
                >
                  {COMPANY_STATUS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
            {t(isEditMode ? 'editCompany' : 'createCompany')}
          </Button>
        </Box>
      </form>
    </div>
  );
};
