import { Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userSessionsSelector } from 'redux/userSessions/UserSessionsSlice';

const useStyles = makeStyles({
  wrapper: {
    gridColumn: '2 / 5',
  },
  styledCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  styledCardItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const SessionCard: React.FC = () => {
  const { userSessions } = useSelector(userSessionsSelector);
  const numberOfSessions = userSessions.length;
  const { t } = useTranslation(['sessionCard', 'errors', 'focusArea']);
  const classes = useStyles();

  const { goal, step } = userSessions?.[0] || {};

  const completedSessions = numberOfSessions ? numberOfSessions - 1 : 0;

  //TODO: DELETE IT AND USE TOPCATEGORIES

  return (
    <div className={classes.wrapper}>
      <Card>
        <CardContent className={classes.styledCard}>
          {!numberOfSessions ? (
            <Typography variant="subtitle1">{t('errors:noData')}</Typography>
          ) : (
            <>
              <Box className={classes.styledCardItem}>
                <Typography variant="h1">{numberOfSessions}</Typography>
                <Typography variant="subtitle1">{t('activeSession')}</Typography>
              </Box>

              <Box className={classes.styledCardItem}>
                <Typography variant="h2">{t(`focusArea:${goal.focusArea}`)}</Typography>
                <Typography variant="subtitle1">{t('currentSessionGoal')}</Typography>
              </Box>

              <Box className={classes.styledCardItem}>
                <Typography variant="h1">{step}</Typography>
                <Typography variant="subtitle1">{t('currentSessionStep')}</Typography>
              </Box>

              <Box className={classes.styledCardItem}>
                <Typography variant="h1">{completedSessions}</Typography>
                <Typography variant="subtitle1">{t('sessionsCompleted')}</Typography>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
