import { Box, CardContent, CardHeader, makeStyles, useTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { auxiliaryPalette } from 'theme/palette';

import { usersSelector } from 'redux/users/usersSlice';
import { IUserQualities } from 'types';

//This components has fake data because there is just 2 personalty tests in database

const useStyles = makeStyles({
  wrapper: {
    gridColumn: '3 / 5',
    gridRow: '3 / 4',
  },
});

export const PersonalityChart = () => {
  const classes = useStyles();
  const { userQualities } = useSelector(usersSelector);
  const { t } = useTranslation(['personalityChart', 'userQualities', 'errors']);

  const theme = useTheme();

  const objectData: IUserQualities = userQualities[0];
  const { user, id, ...object } = objectData || {};

  const data = Object.values(object).map((key) => (key /= 1000));
  const categories = Object.keys(object).map((item) => `${t(`userQualities:${item}`)}`);

  const CHART_DATA = [
    {
      data: data,
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      openChart: false,
      offsetY: -10,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
      radialBar: {
        startAngle: -135,
        endAngle: 135,

        border: `1px solid ${theme.palette.common.black}`,
        dataLabels: {
          name: {
            fontSize: '30px',
            offsetY: 12,
            color: theme.palette.common.black,
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: theme.palette.common.black,
            show: false,
          },
        },
      },
    },
    xaxis: {
      categories: categories,
    },
    fill: {
      opacity: 1,
      colors: [auxiliaryPalette.lightOrange],
      type: 'gradient',
      gradient: {
        gradientToColors: [auxiliaryPalette.pink],
        shadeIntensity: 2,
        opacityFrom: 1,
        opacityTo: 2,
        stops: [0, 50, 100],
        inverseColors: false,
      },
    },

    labels: ['5/7'],
  };

  return (
    <Card className={classes.wrapper}>
      <CardHeader title={t('title')} />
      <CardContent>
        {userQualities.length ? (
          <Box>
            <Chart type="bar" height={350} options={chartOptions} series={CHART_DATA} />
          </Box>
        ) : (
          ` ${t('errors:noData')}`
        )}
      </CardContent>
    </Card>
  );
};
