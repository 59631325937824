import { CardContent, CardHeader, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chart from 'react-apexcharts';
import { auxiliaryPalette } from 'theme/palette';

type Props = {
  currentStreak?: number;
  title: string;
};

export const WeekStreak = ({ currentStreak = 0, title }: Props) => {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      height: 350,
      openChart: false,
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        border: `1px solid ${theme.palette.common.black}`,
        dataLabels: {
          name: {
            fontSize: '30px',
            offsetY: 12,
            color: theme.palette.common.black,
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: theme.palette.common.black,
            show: false,
          },
        },
      },
    },
    fill: {
      opacity: 1,
      colors: [auxiliaryPalette.lightOrange],
      type: 'gradient',
      gradient: {
        gradientToColors: [auxiliaryPalette.pink],
        shadeIntensity: 2,
        opacityFrom: 1,
        opacityTo: 2,
        stops: [0, 50, 100],
        inverseColors: false,
      },
    },

    labels: [`${currentStreak}`],
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box>
          <Chart type="radialBar" height={250} options={chartOptions} series={undefined} />
        </Box>
      </CardContent>
    </Card>
  );
};
