import { PixelCrop } from 'react-image-crop';

import { compressPhoto, removeImgExtension } from 'utils';

const TO_RADIANS = Math.PI / 180;

export const canvasPreview = async (
  image: HTMLImageElement | null,
  canvas: HTMLCanvasElement | null,
  crop: PixelCrop | undefined,
  onChange: (logo: string) => void,
  scale = 1,
  rotate = 0,
  compress = false,
): Promise<void> => {
  if (!image || !canvas || !crop) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;
  if (!ctx) throw new Error('No canvas!');

  const { width, height, x, y } = crop;

  canvas.width = scaleX * width * pixelRatio;
  canvas.height = scaleY * height * pixelRatio;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  const cropX = x * scaleX;
  const cropY = y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();

  const base64Image = compress ? await compressPhoto(ctx.canvas) : ctx.canvas.toDataURL();

  onChange(removeImgExtension(base64Image));
};
