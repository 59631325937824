import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import * as React from 'react';

const IOSSwitch = styled((props: SwitchProps) => {
  return <Switch {...props} focusVisibleClassName=".Mui-focusVisible" disableRipple />;
})(({ theme }) => ({
  width: 72,
  height: 36,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(36px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[600]
            : 'transparent linear-gradient(90deg, #D96499 0%, #EEA347 100%)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 32,
    height: 32,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    background:
      theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : 'transparent linear-gradient(90deg, #D96499 0%, #EEA347 100%)',
    opacity: 1,
    transition: theme.transitions.create(['background'], {
      duration: 500,
    }),
  },
}));

export const CustomSwitch: React.FC<SwitchProps> = (props) => {
  return <IOSSwitch {...props} />;
};
