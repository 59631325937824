/* eslint-disable import/order */
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from 'redux/store';

import App from './App';

const persistor = persistStore(store());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store()}>
      <PersistGate loading={null} persistor={persistor} />
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
