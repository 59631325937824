/* eslint-disable array-callback-return */
import { Card, CardHeader, makeStyles } from '@material-ui/core';
import { Box, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FONT_BOLD } from 'theme/typography';

import { userSessionsSelector } from 'redux/userSessions/UserSessionsSlice';

import bronzeTrophy from 'assets/images/trophies/trophy-bronze.png';
import goldTrophy from 'assets/images/trophies/trophy-gold.png';
import silverTrophy from 'assets/images/trophies/trophy-silver.png';

export const BRONZE_LIMIT = 50;
export const SILVER_LIMIT = 75;
export const GOLD_LIMIT = 90;

const useStyles = makeStyles({
  trophyImage: {
    height: '70px',
    width: '70px',
    marginBottom: '12px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '40px',
    '& div>img:nth-child(2)': {
      height: '100px',
      width: '100px',
    },
  },
  text: {
    fontFamily: FONT_BOLD,
    fontSize: 16,
  },
  trophyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

interface IResultSummaryState {
  gold: number;
  silver: number;
  bronze: number;
}

const initSummary: IResultSummaryState = {
  gold: 0,
  silver: 0,
  bronze: 0,
};

export const TrophiesCard = () => {
  const { userSessions } = useSelector(userSessionsSelector);
  const [resultSummary, setResultSummary] = useState<IResultSummaryState>(initSummary);
  const classes = useStyles();
  const { t } = useTranslation('trophiesCard');

  const userGames = userSessions.flatMap((el) => el.userGame || {});

  useEffect(() => {
    mapTrophies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapTrophies = () => {
    const summaryCopy = { ...resultSummary };
    userGames.map((el) => {
      const result = el.result;
      const isBronze = result < SILVER_LIMIT && result >= BRONZE_LIMIT;
      const isSilver = result < GOLD_LIMIT && result >= SILVER_LIMIT;
      const isGold = result >= GOLD_LIMIT;

      if (isBronze) {
        return summaryCopy.bronze++;
      } else if (isSilver) {
        return summaryCopy.silver++;
      } else if (isGold) {
        return summaryCopy.gold++;
      }
      return result;
    });
    setResultSummary(summaryCopy);
  };

  return (
    <Card>
      <CardHeader title={t('title')} />
      <CardContent>
        <div className={classes.wrapper}>
          <Box className={classes.trophyItem}>
            <img className={classes.trophyImage} src={goldTrophy} alt={goldTrophy} />
            <Typography variant="h3" className={classes.text}>
              {resultSummary.gold}
            </Typography>
          </Box>
          <Box className={classes.trophyItem}>
            <img className={classes.trophyImage} src={silverTrophy} alt={silverTrophy} />
            <Typography variant="h3" className={classes.text}>
              {resultSummary.silver}
            </Typography>
          </Box>
          <Box className={classes.trophyItem}>
            <img className={classes.trophyImage} src={bronzeTrophy} alt={bronzeTrophy} />
            <Typography variant="h3" className={classes.text}>
              {resultSummary.bronze}
            </Typography>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};
