import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUserSessions } from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchSessions = createAsyncThunk<IUserSessions[]>(
  'sessions/fetchSessions',
  async () => {
    const response = await usersAPI(`api/admin/sessions?pagination=false`);
    return response.data.results;
  },
);
