import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { Button } from '@mui/material';
import React from 'react';
import { CellProps } from 'react-table';

import { ITest } from 'types';

export const TESTS_COLUMN = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '10%',
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '40%',
  },
  {
    Header: 'Type',
    accessor: 'testType',
    width: '20%',
  },
  {
    Header: 'Game Template',
    accessor: 'gameTemplate',
    width: '20%',
  },
  {
    Header: 'Options',
    accessor: 'test',
    width: '10%',
    Cell: (props: CellProps<ITest>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
            onClick={() => props.onEdit(id)}
          >
            <EditIcon fontSize="large" />
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: 50,
              minWidth: '40px',
              marginLeft: '10px',
            }}
            onClick={() => props.onInfo(id)}
          >
            <InfoIcon fontSize="large" />
          </Button>
        </>
      );
    },
  },
];
