import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottom: {
      outlineColor: 'white',
    },
  }),
);

export const StyledSpinner: React.FC = () => {
  const classes = useStyles();
  return <CircularProgress color="secondary" className={classes.bottom} size={25} />;
};
