import React, { useRef } from 'react';

interface ICheckboxProps {
  indeterminate?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement | null>(null);

    return <input type="checkbox" ref={defaultRef} {...rest} />;
  },
);
