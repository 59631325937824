import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { CustomSlider } from 'components';
import { centerAspectCrop } from 'utils';
import { canvasPreview } from 'utils/canvasPreview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { flex: 1 },
    card: {
      padding: 10,
      margin: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      margin: 10,
      padding: 10,
      border: '1px solid black',
    },
    options: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

type Props = {
  file: string;
  onChange: (logo: string) => void;
  isEditMode: boolean;
  logo: string;
  aspect?: number;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  compress?: boolean;
};

export const CropImage: React.FC<Props> = ({
  file,
  onChange,
  isEditMode,
  logo,
  aspect,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  compress,
}) => {
  const classes = useStyles();

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const photo = React.useMemo(() => logo, []);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const { t } = useTranslation('createReward');

  useEffect(() => {
    canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      onChange,
      scale,
      rotate,
      compress,
    );
  }, [completedCrop, scale, rotate, compress]);

  useEffect(() => {
    setCrop(undefined);
  }, [file]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      const logoWidth = isEditMode ? 100 : 90;
      if (maxWidth && maxHeight) {
        const calcWidth = (maxWidth * 100) / width;
        const calcHeight = (maxHeight * 100) / height;
        setCrop(centerAspectCrop(width, height, aspect, calcWidth, calcHeight));
      } else {
        setCrop(centerAspectCrop(width, height, aspect, logoWidth));
      }
    }
  }

  const onCompleteCrop = async (crop: PixelCrop) => {
    setCompletedCrop(crop);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.card}>
        <canvas
          ref={previewCanvasRef}
          style={{
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop?.width,
            height: completedCrop?.height,
          }}
        />
      </Box>
      <Box className={classes.logo}>
        <ReactCrop
          crop={crop}
          onChange={(_, p) => setCrop(p)}
          onComplete={onCompleteCrop}
          aspect={aspect}
          ruleOfThirds={true}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minWidth={minWidth}
        >
          <img
            ref={imgRef}
            src={`data:image/jpeg;base64,${file ? file : photo}`}
            onLoad={onImageLoad}
            alt="img"
            style={{
              width: '100%',
              maxHeight: '100%',
              transform: `scale(${scale}) rotate(${rotate}deg)`,
            }}
          />
        </ReactCrop>
      </Box>
      <Box className={classes.options}>
        <label htmlFor="scale-input">{t('scale')}</label>
        <div>
          <CustomSlider
            aria-label={t('scale')}
            step={0.1}
            value={scale}
            max={2}
            min={0.1}
            onChange={(_, value) => setScale(Number(value))}
            style={{ width: 400 }}
          />
        </div>
        <label htmlFor="rotate-input">{t('rotate')}</label>
        <div>
          <CustomSlider
            aria-label={t('rotate')}
            value={rotate}
            max={180}
            min={-180}
            onChange={(_, value) => setRotate(Math.min(180, Math.max(-180, Number(value))))}
            style={{ width: 400 }}
          />
        </div>
      </Box>
    </Box>
  );
};
