import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import LogoIcon from 'assets/gif/loader.gif';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      minHeight: '600px',
    },
    icon: {
      width: '90px',
      height: '90px',
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 0,
        transform: 'scale(1)',
      },
      '100%': {
        opacity: 1,
        transform: 'scale(1.35)',
      },
    },
  }),
);

export const CustomLoader: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img className={classes.icon} src={LogoIcon} alt="logo" />
    </div>
  );
};
