import { createSlice } from '@reduxjs/toolkit';

import { fetchDailyCheckIns, fetchDailyTracking } from './dailyTrackingAction';
import { RootState } from 'redux/store';
import { DailyCheckIn, IDailyTracker } from 'types';

interface IDailyTrackingState {
  dailyTrackers: IDailyTracker[];
  dailyCheckIn: DailyCheckIn;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: IDailyTrackingState = {
  dailyTrackers: [],
  dailyCheckIn: { totalAVG: 0, topReasons: {}, topPairs: {} },
  isLoading: false,
  errorMessage: '',
};

export const dailyTrackingSlice = createSlice({
  name: 'dailyTracking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyTracking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDailyTracking.fulfilled, (state, action) => {
        state.dailyTrackers = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDailyTracking.rejected, (state, action: any) => {
        state.errorMessage = action.error;
      })
      .addCase(fetchDailyCheckIns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDailyCheckIns.fulfilled, (state, action) => {
        state.dailyCheckIn = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDailyCheckIns.rejected, (state, action: any) => {
        state.errorMessage = action.error;
      });
  },
});

export const dailyTrackingSelector = (state: RootState) => state.dailyTracking;
export default dailyTrackingSlice.reducer;
