import { Theme } from '@material-ui/core';

export default function Drawer(theme: Theme) {
  return {
    MuiDrawer: {
      drawer: {
        width: '280px',
      },
      paper: {
        width: '280px',
      },
    },
  };
}
