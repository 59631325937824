import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { fetchTrending, updateTrending } from './trendingActions';
import { RootState } from 'redux/store';
import { Trending } from 'types';

type TrendingState = {
  trending: Trending;
  isLoading: boolean;
  error: string;
};

const initialState: TrendingState = {
  trending: { pills: [], games: [], goals: [], tests: [] },
  isLoading: false,
  error: '',
};

export const trendingSlice = createSlice({
  name: 'trending',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrending.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(fetchTrending.fulfilled, (state, action) => {
        state.trending = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTrending.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateTrending.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(updateTrending.fulfilled, (state, action) => {
        const { data, type } = action.payload;
        const foundId = state.trending[type].findIndex((x) => x.id === data.id);
        state.trending[type][foundId] = data;
        state.isLoading = false;
      })
      .addCase(updateTrending.rejected, (state, action: any) => {
        state.isLoading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      });
  },
});

export const trendingSelector = (state: RootState) => state.trending;
export default trendingSlice.reducer;
