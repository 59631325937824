import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components';
import {
  AuthPage,
  CompanyDetailsPage,
  CreateCard,
  DashboardPage,
  SettingsPage,
  UserDetailsPage,
  UsersPage,
} from 'pages';
import { CompaniesCard } from 'pages/Companies/CompaniesCard';

export const RootNavigator: React.FC = () => {
  return (
    <Switch>
      <Route component={AuthPage} path="/login" />
      <PrivateRoute component={CreateCard} path="/create-session" />
      <PrivateRoute component={UserDetailsPage} path="/users/:id" />
      <PrivateRoute component={CompaniesCard} path="/companies-dashboard" />
      <PrivateRoute component={CompanyDetailsPage} path="/company/:id" />
      <PrivateRoute exact component={SettingsPage} path="/settings" />
      <PrivateRoute exact component={UsersPage} path="/users" />
      <PrivateRoute exact component={DashboardPage} path="/" />
    </Switch>
  );
};
