import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from 'translation/i18n';

import { IGame, IGoal, IPill, Trending, TrendingType } from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchTrending = createAsyncThunk<Trending, void>(
  'trendingActions/fetchTrending',
  async (_, { rejectWithValue }) => {
    try {
      const response = await usersAPI.get(`/api/admin/trending/all`);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateTrending = createAsyncThunk<
  { data: IPill | IGoal | IGame; type: TrendingType },
  { id: number; type: TrendingType; isTrending: boolean }
>('trendingActions/updateTrending', async ({ type, isTrending, id }, { rejectWithValue }) => {
  try {
    const url = `/api/admin/${type}/${id}/trending`;
    const response = await usersAPI.patch(url, { trending: isTrending });
    toast.success(i18n.t('form:updatedSuccessfully'));
    return { data: response.data, type };
  } catch (e: any) {
    return rejectWithValue(e.response.data);
  }
});
