import { format } from 'date-fns';
import i18n from 'translation/i18n';

import {
  DisplayResult,
  FocusArea,
  GameTemplate,
  NuggetType,
  PillCategories,
  ScoringType,
  IUserGoals,
  ITest,
  IUser,
  RewardStatus,
  CompanyStatus,
  CompanySize,
} from 'types';
import { fiveDaysAgo, monthAgo, twoMonthsAgo } from 'utils/helperFunctions';

export type Categories = {
  name: NuggetType;
};

const year = new Date().getFullYear();
const month = new Date().getMonth();

export const NUGGET_TYPES: Categories[] = [
  {
    name: 'INSPIRATIONAL_STORY',
  },
  {
    name: 'GUIDANCE_QUOTE',
  },
  {
    name: 'PRACTICAL_TIP',
  },
];

export const TEST_TYPES: ScoringType[] = ['SUM', 'AVG', 'MEAN', 'PERCENTAGE'];

export const GAME_TEMPLATES: GameTemplate[] = [
  'pinBoard',
  'sortingCardsMethod',
  'basketBall',
  'ballPicker',
  'puzzle',
];

export const MEAN_DISABLED_GAME_TEMPLATES: GameTemplate[] = [
  'pinBoard',
  'basketBall',
  'ballPicker',
  'puzzle',
];

export const DISPLAY_RESULTS: DisplayResult[] = ['scale', 'number', 'topWords'];

export const GAME_TEMPLATE_SETTINGS = [
  {
    name: 'pinBoard',
    answers: [2, 3, 4, 5],
    maxLength: 24,
  },
  {
    name: 'basketBall',
    answers: [2, 3],
    maxLength: 7,
  },
  {
    name: 'ballPicker',
    answers: [2, 3, 4, 5, 6],
    maxLength: 18,
  },
  {
    name: 'sortingCardsMethod',
    answers: [6, 7, 12, 13, 14, 15, 24, 25, 26, 27, 28, 29, 30, 31],
    maxLength: 55,
  },
  {
    name: 'puzzle',
    answers: [2, 3],
    maxLength: 130,
  },
];

export const PILL_CATEGORIES: { name: PillCategories }[] = [
  {
    name: 'VIDEO',
  },
  {
    name: 'AUDIO',
  },
];

export const pillCategoriesData: Record<PillCategories, { label: PillCategories }> = {
  VIDEO: {
    label: i18n.t('pillCategories:Video'),
  },
  AUDIO: {
    label: i18n.t('pillCategories:Audio'),
  },
};

export const nuggetTypeData: Record<NuggetType, { label: NuggetType }> = {
  INSPIRATIONAL_STORY: {
    label: i18n.t('tips:INSPIRATIONAL_STORY'),
  },
  GUIDANCE_QUOTE: {
    label: i18n.t('tips:GUIDANCE_QUOTE'),
  },
  PRACTICAL_TIP: {
    label: i18n.t('tips:PRACTICAL_TIP'),
  },
};

export const COMPANY_SIZE: CompanySize[] = [
  '>25',
  '26-50',
  '51-250',
  '251-500',
  '501-2000',
  '2000+',
];

export const USERS_FOCUS_DATA: { name: FocusArea; count: number }[] = [
  {
    name: i18n.t('COMMUNICATIONS'),
    count: 0,
  },
  {
    name: i18n.t('PRODUCTIVITY'),
    count: 0,
  },
  {
    name: i18n.t('PROBLEM_SOLVING'),
    count: 0,
  },
  {
    name: i18n.t('ADAPTABILITY'),
    count: 0,
  },
  {
    name: i18n.t('LEADERSHIP'),
    count: 0,
  },
];

export const FOCUS_AREAS: FocusArea[] = [
  'COMMUNICATIONS',
  'PRODUCTIVITY',
  'PROBLEM_SOLVING',
  'ADAPTABILITY',
  'LEADERSHIP',
];

export const exampleGoals: IUserGoals[] = [
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'ACTIVE',
  },
  {
    id: 2,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 3,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 11,
    status: 'ACTIVE',
  },
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'DONE',
  },
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'INACTIVE',
  },
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'ACTIVE',
  },
];

export const sortedExampleGoals: IUserGoals[] = [
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'ACTIVE',
  },
  {
    id: 2,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 3,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 11,
    status: 'ACTIVE',
  },
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'ACTIVE',
  },
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'INACTIVE',
  },
  {
    id: 1,
    user: {
      company: {
        id: 1,
        name: 'UJJI',
      },
    },
    goal: {
      id: 1,
      focusArea: 'COMMUNICATIONS',
      name: 'FirstExample',
      goalLength: {
        length: 10,
      },
      trending: false,
      exclusive: false,
    },
    completedSessions: 10,
    status: 'DONE',
  },
];

export const exampleITestSUM: ITest = {
  id: 101,
  name: 'Example test SUM',
  testType: 'SUM',
  gameTemplate: 'ballPicker',
  displayResult: 'number',
  minPoints: 10,
  maxPoints: 20,
  howToPlay: 'test',
  mindset: 'test',
  preResults: 'test',
  userResults: 'test',
  info: 'test',
  numberOfAnswers: 7,
  isEmpty: false,
  exclusive: false,
};

export const exampleITestMEAN: ITest = {
  id: 102,
  name: 'Example test MEAN',
  testType: 'MEAN',
  gameTemplate: 'ballPicker',
  displayResult: 'number',
  minPoints: 10,
  maxPoints: 20,
  howToPlay: 'test',
  mindset: 'test',
  preResults: 'test',
  userResults: 'test',
  info: 'test',
  numberOfAnswers: 7,
  isEmpty: false,
  exclusive: false,
};

export const exampleITestAVG: ITest = {
  id: 102,
  name: 'Example test AVG',
  testType: 'AVG',
  gameTemplate: 'ballPicker',
  displayResult: 'number',
  minPoints: 10,
  maxPoints: 20,
  howToPlay: 'test',
  mindset: 'test',
  preResults: 'test',
  userResults: 'test',
  info: 'test',
  numberOfAnswers: 7,
  isEmpty: false,
  exclusive: false,
};

export const exampleITestPERCENTAGE: ITest = {
  id: 102,
  name: 'Example test PERCENTAGE',
  testType: 'PERCENTAGE',
  gameTemplate: 'ballPicker',
  displayResult: 'number',
  minPoints: 10,
  maxPoints: 20,
  howToPlay: 'test',
  mindset: 'test',
  preResults: 'test',
  userResults: 'test',
  info: 'test',
  numberOfAnswers: 7,
  isEmpty: false,
  exclusive: false,
};

export const iUsersArray: IUser[] = [
  {
    id: 10,
    email: 'test@example.com',
    firstName: 'test',
    birthday: '1996-07-05',
    company: {
      id: 18,
      name: 'test',
    },
    createdAt: 'test',
    accessCode: 'test',
    loginTimestamp: format(new Date(), 'yyyy-MM-dd'),
    blocked: 0,
  },
  {
    id: 11,
    email: 'test@example.com',
    firstName: 'test',
    birthday: '1996-07-05',
    company: {
      id: 18,
      name: 'test',
    },
    createdAt: 'test',
    accessCode: 'test',
    loginTimestamp: format(new Date(year, month, 1), 'yyyy-MM-dd'),
    blocked: 0,
  },
  {
    id: 12,
    email: 'test@example.com',
    firstName: 'test',
    birthday: '1976-07-05',
    company: {
      id: 18,
      name: 'test',
    },
    createdAt: 'test',
    accessCode: 'test',
    loginTimestamp: format(new Date(year, month, 1), 'yyyy-MM-dd'),
    blocked: 0,
  },
  {
    id: 13,
    email: 'test@example.com',
    firstName: 'test',
    birthday: '1956-07-05',
    company: {
      id: 18,
      name: 'test',
    },
    createdAt: format(twoMonthsAgo, 'yyyy-MM-dd'),
    accessCode: 'test',
    loginTimestamp: format(monthAgo, 'yyyy-MM-dd'),
    blocked: 0,
  },
  {
    id: 14,
    email: 'test@example.com',
    firstName: 'test',
    birthday: '2006-07-05',
    company: {
      id: 18,
      name: 'test',
    },
    createdAt: format(fiveDaysAgo, 'yyyy-MM-dd'),
    accessCode: 'test',
    loginTimestamp: format(new Date(), 'yyyy-MM-dd'),
    blocked: 0,
  },
];

export const REWARD_STATUS: RewardStatus[] = ['active', 'inactive', 'archived'];
export const COMPANY_STATUS: CompanyStatus[] = ['active', 'inactive'];
export const iUsersArrayWeekly: IUser[] = [
  {
    id: 11,
    email: 'test@example.com',
    firstName: 'test',
    birthday: '1996-07-05',
    company: {
      id: 18,
      name: 'test',
    },
    createdAt: 'test',
    accessCode: 'test',
    loginTimestamp: format(fiveDaysAgo, 'yyyy-MM-dd'),
    blocked: 0,
  },
];
