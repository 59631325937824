import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUserGoals } from 'types';
import { usersAPI } from 'utils/axiosConfig';
import { sortGoalsByStatus } from 'utils/helperFunctions';

export const fetchUsersGoal = createAsyncThunk<IUserGoals[], string>(
  'usersGoals/fetchUsersGoal',
  async (id: string) => {
    const response = await usersAPI.get(`api/admin/user_goals?userId=${id}`);
    return sortGoalsByStatus(response.data.results);
  },
);
