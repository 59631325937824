import { createAsyncThunk } from '@reduxjs/toolkit';

import { ANALYTICS_START_DATE } from 'constants/analytics';
import { AnalyticsOptions, AnalyticsResult, AnalyticsResultRows } from 'types';
import { usersAPI } from 'utils/axiosConfig';

const fetchData = async (token: string, options: AnalyticsOptions) => {
  return await usersAPI.post(
    `${process.env.REACT_APP_ANALYTICS_URL}/properties/${process.env.REACT_APP_ANALYTICS_PROPERTY_ID}:runReport`,
    options,
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const fetchTopCities = createAsyncThunk<AnalyticsResult>(
  'analyticsActions/fetchTopCities',
  async (_, { getState }) => {
    const { userAuth } = getState() as { userAuth: { googleToken: string } };
    const options = {
      dateRanges: [
        {
          startDate: ANALYTICS_START_DATE,
          endDate: 'today',
        },
      ],
      dimensions: [
        {
          name: 'city',
        },
        {
          name: 'customUser:Environment',
        },
      ],
      metrics: [
        {
          name: 'totalUsers',
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: 'customUser:Environment',
          stringFilter: {
            matchType: 'EXACT',
            value: 'prod',
          },
        },
      },
    };
    const response = await fetchData(userAuth.googleToken, options);
    return response.data;
  },
);

export const fetchDownloads = createAsyncThunk<AnalyticsResult>(
  'analyticsActions/fetchDownloads',
  async (_, { getState }) => {
    const { userAuth } = getState() as { userAuth: { googleToken: string } };
    const options = {
      dimensions: [
        {
          name: 'firstSessionDate',
        },
        {
          name: 'customUser:Environment',
        },
      ],
      metrics: [
        {
          name: 'totalUsers',
        },
      ],
      dateRanges: [
        {
          startDate: ANALYTICS_START_DATE,
          endDate: 'today',
        },
      ],
      metricAggregations: ['TOTAL'],
      dimensionFilter: {
        filter: {
          fieldName: 'customUser:Environment',
          stringFilter: {
            matchType: 'EXACT',
            value: 'prod',
          },
        },
      },
    };
    const response = await fetchData(userAuth.googleToken, options);
    return response.data;
  },
);

export const fetchAverageTime = createAsyncThunk<AnalyticsResult>(
  'analyticsActions/fetchAverageTime',
  async (_, { getState }) => {
    const { userAuth } = getState() as { userAuth: { googleToken: string } };
    const options = {
      metrics: [{ name: 'userEngagementDuration' }, { name: 'activeUsers' }],
      dimensions: [
        {
          name: 'customUser:Environment',
        },
      ],
      dateRanges: [
        {
          startDate: ANALYTICS_START_DATE,
          endDate: 'today',
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: 'customUser:Environment',
          stringFilter: {
            matchType: 'EXACT',
            value: 'prod',
          },
        },
      },
    };
    const response = await fetchData(userAuth.googleToken, options);
    return response.data;
  },
);

export const fetchActiveUsers = createAsyncThunk<
  { daily: boolean; data: AnalyticsResultRows[] },
  { daily: boolean }
>('analyticsActions/fetchActiveUsers', async ({ daily }, { getState }) => {
  const { userAuth } = getState() as { userAuth: { googleToken: string } };
  const options = {
    metrics: [
      {
        name: 'activeUsers',
      },
    ],
    dimensions: [
      {
        name: daily ? 'date' : 'month',
      },
      {
        name: 'customUser:Environment',
      },
    ],
    dateRanges: [
      {
        startDate: ANALYTICS_START_DATE,
        endDate: 'today',
      },
    ],
    orderBys: [
      {
        dimension: {
          orderType: 'ALPHANUMERIC',
          dimensionName: daily ? 'date' : 'month',
        },
      },
    ],
    dimensionFilter: {
      filter: {
        fieldName: 'customUser:Environment',
        stringFilter: {
          matchType: 'EXACT',
          value: 'prod',
        },
      },
    },
  };
  const response = await fetchData(userAuth.googleToken, options);
  return { daily: daily, data: response.data.rows };
});

export const fetchBodyType = createAsyncThunk<
  { bodyType: number; count: number },
  { bodyType: number }
>('analyticsActions/fetchBodyType', async ({ bodyType }) => {
  const response = await usersAPI(`/api/admin/avatars/body_type?bodyType=${bodyType}`);
  return { bodyType: bodyType, count: response.data.count };
});
