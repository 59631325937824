import { Chip, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { checkAdditionalCompanyGoal } from 'redux/goals/goalsActions';

interface Props {
  id: number;
  goalId?: number;
  name: string;
  isDisabled: boolean;
  handleDelete: () => void;
}

export const ExclusiveContentChip: React.FC<Props> = ({
  id,
  goalId,
  name,
  isDisabled,
  handleDelete,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(goalId !== undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const check = async () => {
      if (goalId === undefined) return;

      await dispatch(
        checkAdditionalCompanyGoal({
          companyId: id,
          goalId,
          cb: (hasGoalRelations) => {
            if (hasGoalRelations) handleDelete();

            setIsLoading(false);
            return;
          },
        }),
      );
    };

    check();
  }, []);

  return (
    <>
      {isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <Chip
          key={id}
          label={name}
          onDelete={handleDelete}
          style={{ fontSize: '1.4rem', margin: 3 }}
          disabled={isDisabled}
        />
      )}
    </>
  );
};
