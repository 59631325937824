import { auxiliaryPalette } from 'theme/palette';

export const BaseOptionsChart = () => {
  return {
    chart: {
      height: 400,

      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
        // formatter: (value: string) => {
        //   if (!value) {
        //     return value;
        //   }
        //   const formattedDate = value?.split('/')?.slice(0, 2);
        //   return formattedDate?.join('/');
        // },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth' as const,
    },
    colors: ['#F48451'],

    yaxis: {},
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: auxiliaryPalette.pink,
            opacity: 1,
          },
          {
            offset: 20,
            color: auxiliaryPalette.pink,
            opacity: 1,
          },
          {
            offset: 50,
            color: auxiliaryPalette.lightOrange,
            opacity: 1,
          },
          {
            offset: 100,
            color: auxiliaryPalette.lightOrange,
            opacity: 1,
          },
        ],
      },
    },
  };
};
