import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';
import { Paths } from 'types';

export interface FiltersState {
  filters: {
    path: Paths;
    query: string;
    page: number;
    sortBy: [{ id: string; desc: boolean }] | [];
  }[];
}

const initialState: FiltersState = {
  filters: [{ path: '/create-session/sessions', query: '', page: 0, sortBy: [] }],
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateSearchFilter: (
      state: FiltersState,
      action: PayloadAction<{ path: Paths; query: string }>,
    ) => {
      const { path, query } = action.payload;
      if (state.filters) {
        const foundId = state.filters.findIndex((x) => x.path === path);
        if (foundId >= 0) {
          state.filters[foundId].query = query;
          state.filters[foundId].page = 0;
        } else {
          state.filters.push({ path: path, query: query, page: 0, sortBy: [] });
        }
      }
    },
    updatePageFilter: (
      state: FiltersState,
      action: PayloadAction<{ path: Paths; page: number }>,
    ) => {
      if (state.filters) {
        const { path, page } = action.payload;
        const foundId = state.filters.findIndex((x) => x.path === path);
        if (foundId >= 0) {
          state.filters[foundId].page = page;
        } else {
          state.filters.push({ path: path, query: '', page: page, sortBy: [] });
        }
      }
    },
    updateSortFilter: (
      state: FiltersState,
      action: PayloadAction<{ path: Paths; sortBy: [{ id: string; desc: boolean }] | [] }>,
    ) => {
      if (state.filters) {
        const { path, sortBy } = action.payload;
        const foundId = state.filters.findIndex((x) => x.path === path);
        if (foundId >= 0) {
          state.filters[foundId].sortBy = sortBy;
        } else {
          state.filters.push({ path: path, query: '', page: 0, sortBy: sortBy });
        }
      }
    },
  },
});

export const { updateSearchFilter, updatePageFilter, updateSortFilter } = filtersSlice.actions;

export const filtersSelector = (state: RootState) => state.filters;

export default filtersSlice.reducer;
