import React, { ErrorInfo } from 'react';

import { ShowError } from './ShowError';

export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: '' };
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.message };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //TODO: send error on external analitic server
  }
  render() {
    if (this.state.hasError) {
      return (
        <ShowError error={this.state.error} onPress={() => this.setState({ hasError: false })} />
      );
    }
    return this.props.children;
  }
}
