import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'translation/i18n';

import {
  createDailyTask,
  createExternalReward,
  createNugget,
  createPill,
  createSession,
  createTest,
  fetchDailyTask,
  fetchExternalRewards,
  fetchGames,
  fetchNuggets,
  fetchPills,
  fetchSessionsTemplates,
  fetchTestQuestions,
  fetchTests,
  getVideoStatus,
  updateDailyTask,
  updateExternalReward,
  updateNugget,
  updatePill,
  updateSession,
  updateTest,
  uploadPillLogo,
  uploadRewardLogo,
} from './createSessionAction';
import { RootState } from 'redux/store';
import {
  ConvertedVideo,
  IDailyTask,
  IGame,
  IPill,
  ITest,
  Nugget,
  PaginationType,
  Question,
  ExternalReward,
  ScoringType,
  Session,
  VideoStatus,
  VimeoApiResponse,
} from 'types';

interface createSessionState {
  pills: { pagination: PaginationType; results: IPill[] };
  games: { pagination: PaginationType; results: IGame[] };
  tests: { pagination: PaginationType; results: ITest[] };
  dailyTasks: { pagination: PaginationType; results: IDailyTask[] };
  nuggets: { pagination: PaginationType; results: Nugget[] };
  sessions: { pagination: PaginationType; results: Session[] };
  externalRewards: { pagination: PaginationType; results: ExternalReward[] };
  videos: ConvertedVideo[];
  questions: { pagination: PaginationType; results: Question[] };
  loading: boolean;
  videoStatus: VideoStatus | null;
  error: string;
  isSubmitted: boolean;
}

const initialState: createSessionState = {
  pills: { pagination: { page: 1, allPages: 1 }, results: [] },
  games: { pagination: { page: 1, allPages: 1 }, results: [] },
  tests: { pagination: { page: 1, allPages: 1 }, results: [] },
  dailyTasks: { pagination: { page: 1, allPages: 1 }, results: [] },
  nuggets: { pagination: { page: 1, allPages: 1 }, results: [] },
  sessions: { pagination: { page: 1, allPages: 1 }, results: [] },
  externalRewards: { pagination: { page: 1, allPages: 1 }, results: [] },
  videos: [],
  questions: { pagination: { page: 1, allPages: 1 }, results: [] },
  loading: false,
  videoStatus: null,
  error: '',
  isSubmitted: false,
};

const createSessionSlice = createSlice({
  name: 'createSession',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchPills.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchPills.fulfilled, (state, action) => {
        state.loading = false;
        state.pills = action.payload;
      })
      .addCase(fetchPills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchExternalRewards.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchExternalRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.externalRewards = action.payload;
      })
      .addCase(fetchExternalRewards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchGames.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.loading = false;
        state.games = action.payload;
      })
      .addCase(fetchGames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchTests.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(
        fetchTests.fulfilled,
        (
          state,
          action: PayloadAction<{
            pagination: PaginationType;
            results: ITest[];
          }>,
        ) => {
          state.loading = false;
          state.tests = action.payload;
        },
      )
      .addCase(fetchTests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchDailyTask.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchDailyTask.fulfilled, (state, action) => {
        state.loading = false;
        state.dailyTasks = action.payload;
      })
      .addCase(fetchTestQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchTestQuestions.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchTestQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload;
      })
      .addCase(fetchDailyTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchNuggets.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchNuggets.fulfilled, (state, action) => {
        state.loading = false;
        state.nuggets = action.payload;
      })
      .addCase(fetchNuggets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchSessionsTemplates.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchSessionsTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
      })
      .addCase(fetchSessionsTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(createSession.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createSession.fulfilled, (state, action: PayloadAction<Session>) => {
        state.loading = false;
        state.sessions.results.push(action.payload);
      })
      .addCase(createSession.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload?.detail || i18next.t('error:other');
      })
      .addCase(createDailyTask.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createDailyTask.fulfilled, (state, action: PayloadAction<IDailyTask>) => {
        state.loading = false;
        state.dailyTasks.results.push(action.payload);
      })
      .addCase(createDailyTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(getVideoStatus.pending, (state) => {
        state.error = '';
      })
      .addCase(getVideoStatus.fulfilled, (state, action: PayloadAction<VimeoApiResponse>) => {
        state.loading = false;
        state.videoStatus = action.payload.status;
      })
      .addCase(getVideoStatus.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.error || i18next.t('error:other'));
        state.error = action.payload.error || i18next.t('error:other');
      })
      .addCase(createNugget.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createNugget.fulfilled, (state, action: PayloadAction<Nugget>) => {
        state.loading = false;
        state.nuggets.results.push(action.payload);
      })
      .addCase(createNugget.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(createPill.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.isSubmitted = false;
      })
      .addCase(createPill.fulfilled, (state, action: PayloadAction<IPill>) => {
        state.loading = false;
        state.isSubmitted = true;
        state.pills.results.push(action.payload);
      })
      .addCase(createPill.rejected, (state, action: any) => {
        state.loading = false;
        state.isSubmitted = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(uploadRewardLogo.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(uploadRewardLogo.fulfilled, (state, action) => {
        const { isEditMode, id } = action.payload;
        const foundId = state.externalRewards.results.findIndex((x) => x.id === id);
        isEditMode
          ? (state.externalRewards.results[foundId] = action.payload)
          : state.externalRewards.results.push(action.payload);
        state.loading = false;
      })
      .addCase(uploadRewardLogo.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(uploadPillLogo.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(uploadPillLogo.fulfilled, (state, action) => {
        const { isEditMode, id } = action.payload;
        const foundId = state.pills.results.findIndex((x) => x.id === id);
        isEditMode
          ? (state.pills.results[foundId] = { ...action.payload, ...state.pills.results[foundId] })
          : state.pills.results.push(action.payload);
        state.loading = false;
      })
      .addCase(uploadPillLogo.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(createExternalReward.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createExternalReward.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(createExternalReward.fulfilled, (state, action) => {
        state.loading = false;
        state.externalRewards.results.push(action.payload);
      })
      .addCase(updateExternalReward.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateExternalReward.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(updateExternalReward.fulfilled, (state, action) => {
        const { id } = action.payload;
        const foundId = state.externalRewards.results.findIndex((x) => x.id === id);
        state.externalRewards.results[foundId] = action.payload;
        state.loading = false;
      })
      .addCase(createTest.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createTest.fulfilled, (state, action: PayloadAction<ITest>) => {
        state.loading = false;
        const newData = action.payload;
        newData.testType = action.payload.testType as ScoringType;
        state.tests.results.push(newData);
      })
      .addCase(createTest.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(updateSession.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateSession.fulfilled, (state, action: PayloadAction<Session>) => {
        const { id } = action.payload;
        const foundId = state.sessions.results.findIndex((x) => x.id === id);
        state.sessions.results[foundId] = action.payload;
        state.loading = false;
      })
      .addCase(updateSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(updatePill.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.isSubmitted = false;
      })
      .addCase(updatePill.fulfilled, (state, action: PayloadAction<IPill>) => {
        const { id } = action.payload;
        const foundId = state.pills.results.findIndex((x) => x.id === id);
        state.pills.results[foundId] = action.payload;
        state.loading = false;
        state.isSubmitted = true;
      })
      .addCase(updatePill.rejected, (state, action: any) => {
        state.loading = false;
        state.isSubmitted = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })
      .addCase(updateTest.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateTest.fulfilled, (state, action: PayloadAction<ITest>) => {
        const { id, testType } = action.payload;
        const foundId = state.tests.results.findIndex((x) => x.id === id);
        const newArray: ITest = {
          ...action.payload,
          testType: testType ? (testType as ScoringType) : 'MEAN',
        };
        state.tests.results[foundId] = newArray;
        state.loading = false;
      })
      .addCase(updateTest.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.detail || i18next.t('error:other'));
        state.error = action.payload.detail || action.error.message || i18next.t('error:other');
      })

      .addCase(updateDailyTask.fulfilled, (state, action: PayloadAction<IDailyTask>) => {
        const { id } = action.payload;
        const foundId = state.dailyTasks.results.findIndex((x) => x.id === id);
        state.dailyTasks.results[foundId] = action.payload;
        state.loading = false;
      })
      .addCase(updateDailyTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(updateDailyTask.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateNugget.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateNugget.fulfilled, (state, action: PayloadAction<Nugget>) => {
        const { id } = action.payload;
        const foundId = state.nuggets.results.findIndex((x) => x.id === id);
        state.nuggets.results[foundId] = action.payload;
        state.loading = false;
      })
      .addCase(updateNugget.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      }),
});

export const createSessionSelector = (state: RootState) => state.createSession;

export default createSessionSlice.reducer;
