import { Theme } from '@material-ui/core';

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      root: {
        color: 'red',
        '&$error': {
          color: 'red',
        },
      },
    },
  };
}
