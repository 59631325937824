import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import lodash from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BarChart } from 'components/Charts';
import { IUserGoals } from 'types';
import { getFocusArea } from 'utils/helperFunctions';

const useStyles = makeStyles({
  wrapper: {
    gridColumn: 'auto /span 6',
    gridRow: '6',
    display: 'grid',
    width: '100%',
    height: '100%',
  },
});

type Props = {
  groupedByFocusArea: lodash.Dictionary<IUserGoals[]>;
};

export const CurrentAreaFocus: React.FC<Props> = ({ groupedByFocusArea }) => {
  const classes = useStyles();
  const { t } = useTranslation(['widgets', 'companyProfile', 'errors']);

  const categories = lodash.map(groupedByFocusArea, (x) => x[0].goal.focusArea);
  const categoriesConvertedNames = categories.map((x) => getFocusArea(x).label);
  const colors = categories.map((x) => getFocusArea(x).contentColor);
  const data = lodash.map(groupedByFocusArea, (x) => x.length);

  return (
    <Box className={classes.wrapper}>
      <BarChart
        title={t('currentAreaFocus')}
        data={data}
        categories={categoriesConvertedNames}
        colors={colors}
      />
    </Box>
  );
};
