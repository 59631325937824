import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { CellProps } from 'react-table';

import { ExternalReward, IDailyTask } from 'types';

export const REWARDS_COLUMN = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '5%',
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '20%',
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: '40%',
    Cell: ({ row }: CellProps<IDailyTask>): string =>
      _.truncate(row.original.description, { length: 100 }),
  },
  {
    Header: 'link',
    accessor: 'link',
    width: '20%',
    Cell: (props: CellProps<ExternalReward>): JSX.Element => {
      const { link } = props.row.original;
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '10%',
  },
  {
    Header: 'Options',
    accessor: 'test',
    width: '5%',
    Cell: (props: CellProps<ExternalReward>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
          onClick={() => props.onEdit(id)}
        >
          <EditIcon fontSize="large" />
        </Button>
      );
    },
  },
];
