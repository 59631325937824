import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { Button } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { CellProps } from 'react-table';
import i18n from 'translation/i18n';

import { Company, IGoal } from 'types';
import { firstLetterUpper } from 'utils';

export const COMPANIES_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '5%',
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '15%',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '20%',
    Cell: ({ value }: CellProps<Company>): JSX.Element | string => {
      if (!value) {
        return `-`;
      } else {
        return (
          <a href={`mailto:${value}`} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      }
    },
  },
  {
    Header: 'Access code',
    accessor: 'accessCode',
    width: '10%',
  },
  {
    Header: 'Memberships',
    accessor: 'memberships',
    width: '5%',
    Cell: ({ value }: CellProps<Company>): string => value ?? '-',
  },
  {
    Header: 'Size',
    accessor: 'size',
    width: '10%',
    Cell: ({ value }: CellProps<Company>): string => value ?? '-',
  },
  {
    Header: 'Member since',
    accessor: 'createdAt',
    width: '10%',
    Cell: ({ value }: CellProps<Company>): string => {
      if (!value) {
        return `${i18n.t('table:noDate')}`;
      } else {
        return format(new Date(value), 'dd/MM/yyyy');
      }
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '10%',
    Cell: ({ value }: CellProps<Company>): string => firstLetterUpper(value),
  },
  {
    Header: 'Options',
    accessor: 'test',
    width: '10%',
    Cell: (props: CellProps<IGoal>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
            onClick={() => props.onEdit(id)}
          >
            <EditIcon fontSize="large" />
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: 50,
              minWidth: '40px',
              marginLeft: '10px',
            }}
            onClick={() => props.onInfo(id)}
          >
            <InfoIcon fontSize="large" />
          </Button>
        </>
      );
    },
  },
];
