import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'translation/i18n';

import { fetchUserBadges } from './badgesActions';
import { RootState } from 'redux/store';

export interface IBadge {
  id: number;
  name: string | null;
  type: string;
  isActive?: boolean;
  title: string;
}

export interface IUserBadgeResponse {
  id: number;
  status: string;
  userId: number;
  badgeId: number;
  badgeName: string;
  title: string;
  type: string;
}

interface IBadgesState {
  badges: IBadge[];
  userBadges: IUserBadgeResponse[];
  loading: boolean;
  error: string;
}

const initialState: IBadgesState = {
  badges: [],
  userBadges: [],
  loading: false,
  error: '',
};

const badgesSlice = createSlice({
  name: 'badges',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserBadges.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchUserBadges.fulfilled, (state, action: PayloadAction<IUserBadgeResponse[]>) => {
        state.loading = false;
        state.userBadges = action.payload;
      })
      .addCase(fetchUserBadges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || i18next.t('error:other');
      }),
});

export const badgesSelector = (state: RootState) => state.badges;

export default badgesSlice.reducer;
