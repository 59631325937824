import { SerializedError } from '@reduxjs/toolkit';
import i18n from 'translation/i18n';

import { errors } from 'constants/errors';
import { ErrorType, SymfonyError } from 'types';

export const convertErrorMessage = (error: string): string => {
  if (error === 'Invalid credentials.') {
    return i18n.t('errors:wrongEmailPassword');
  } else {
    return error;
  }
};

export const convertErrorMessages = (error: ErrorType | SymfonyError | SerializedError): string => {
  if ('detail' in error) {
    return (
      errors.find((x) => x.apiResponse === error.detail)?.message ||
      error.detail ||
      i18n.t('errors:unknownError')
    );
  } else {
    return (
      errors.find((x) => x.apiResponse === error.message)?.message || i18n.t('errors:unknownError')
    );
  }
};
