import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormHelperText,
  InputLabel,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Grid,
} from '@material-ui/core';
import { Box, FormControl, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CustomLoader } from 'components';
import { CustomAlert } from 'components/CustomAlert';
import { ErrorAlert } from 'components/ErrorBoundary';
import { ExclusiveContent } from 'components/ExclusiveContent/ExclusiveContent';
import {
  DISPLAY_RESULTS,
  GAME_TEMPLATES,
  MEAN_DISABLED_GAME_TEMPLATES,
  TEST_TYPES,
} from 'constants/mock';
import { fetchCompanies, fetchExclusiveCompanies } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { createTest, fetchTests, updateTest } from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { DisplayResult, GameTemplate, ScoringType, TestFormType } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textArea: { fontSize: '16px', fontFamily: 'MarkPro', padding: '6px 20px 7px 20px' },
    listItem: { fontSize: '1.6rem' },
  }),
);

export const CreateTest: React.FC = () => {
  const classes = useStyles();
  const {
    tests,
    loading: sessionLoading,
    error: sessionError,
  } = useSelector(createSessionSelector);
  const {
    companies,
    exclusiveCompanies,
    loading: { fetchCompanies: fetchCompaniesLoading, fetchExclusiveCompaniesLoading },
    error: companiesError,
  } = useSelector(companiesSelector);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('createTest');

  const isEditMode = Number.isInteger(parseInt(id));
  const selectedTest = tests.results.find((test) => test.id === parseInt(id));

  const isLoading = sessionLoading || fetchCompaniesLoading || fetchExclusiveCompaniesLoading;
  const error = sessionError || companiesError;

  useEffect(() => {
    dispatch(fetchCompanies({ pagination: false }));
    if (isEditMode) {
      if (!selectedTest) dispatch(fetchTests({ id: parseInt(id) }));
      dispatch(fetchExclusiveCompanies({ id, type: 'test' }));
    }
  }, [id, isEditMode, selectedTest]);

  const schema = yup
    .object({
      name: yup.string().required(),
      testType: yup.string().required(),
      gameTemplate: yup
        .string()
        .required()
        .when('testType', {
          is: (value: ScoringType) => value === 'MEAN',
          then: (rule) =>
            rule.test(
              'disable gameTemplate',
              t('validation:invalidGameTemplate'),
              (val) => !MEAN_DISABLED_GAME_TEMPLATES.some((x) => val === x),
            ),
        }),
      howToPlay: yup.string().required(),
      mindset: yup.string().required(),
      preResults: yup.string(),
      userResults: yup.string().required(),
      info: yup.string().required(),
      exclusive: yup.boolean().required(),
      exclusiveCompanies: yup.array().when('exclusive', { is: true, then: yup.array().min(1) }),
    })
    .required();

  const dispatch = useDispatch();
  const history = useHistory();

  const useFormMethods = useForm<TestFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      testType: 'AVG',
      gameTemplate: 'ballPicker',
      displayResult: 'number',
    },
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useFormMethods;

  const formValue = watch();

  useEffect(() => {
    if (isEditMode) {
      if (selectedTest) {
        const {
          id,
          name,
          testType,
          gameTemplate,
          displayResult,
          minPoints,
          maxPoints,
          howToPlay,
          mindset,
          preResults,
          userResults,
          info,
          exclusive,
        } = selectedTest;
        setValue('id', id);
        setValue('name', name);
        setValue('testType', testType);
        setValue('gameTemplate', gameTemplate);
        setValue('displayResult', displayResult);
        setValue('minPoints', minPoints);
        setValue('maxPoints', maxPoints);
        setValue('howToPlay', howToPlay);
        setValue('mindset', mindset);
        setValue('preResults', preResults);
        setValue('userResults', userResults);
        setValue('info', info);
        setValue('exclusive', exclusive);
      }
    }
  }, [id, isEditMode, selectedTest, setValue, tests]);

  const onSubmit = handleSubmit((data: TestFormType) => {
    if (isEditMode) {
      dispatch(updateTest(data));
    } else {
      dispatch(createTest(data));
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful && !isLoading && !error) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, isLoading]);

  if (isLoading) return <CustomLoader />;

  if (isEditMode && !selectedTest) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      <FormProvider {...useFormMethods}>
        <form onSubmit={onSubmit}>
          <Typography variant="h2" gutterBottom>
            {isEditMode ? t('editTest') : t('createTest')}
          </Typography>
          {error && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <CustomAlert description={error} severity="error" />
            </Grid>
          )}

          <TextField
            margin="normal"
            fullWidth
            label="Name"
            {...register('name')}
            name="name"
            helperText={errors.name?.message}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="type-label">{t('scoringType')}</InputLabel>
            <Controller
              control={control}
              name="testType"
              render={({ field: { value } }) => {
                return (
                  <Select
                    labelId="type-label"
                    id="type-label"
                    label="scoringType"
                    value={value}
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      const name = e.target.value;
                      setValue('testType', name as ScoringType);
                    }}
                  >
                    {TEST_TYPES.map((test) => {
                      return (
                        <MenuItem key={test} value={test} sx={{ fontSize: '1.6rem' }}>
                          {test}
                        </MenuItem>
                      );
                    })}
                  </Select>
                );
              }}
            />
            <FormHelperText error>{errors.testType?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('gameTemplate')}</InputLabel>
            <Controller
              control={control}
              name="gameTemplate"
              render={({ field: { value } }) => {
                return (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="gameTemplate"
                    value={value}
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      const name = e.target.value;
                      setValue('gameTemplate', name as GameTemplate);
                    }}
                  >
                    {GAME_TEMPLATES.map((game) => {
                      const isDisabled =
                        formValue.testType === 'MEAN' &&
                        MEAN_DISABLED_GAME_TEMPLATES.some((x) => game === x);
                      return (
                        <MenuItem
                          key={game}
                          disabled={isDisabled}
                          value={game}
                          sx={{ fontSize: '1.6rem' }}
                        >
                          {game}
                        </MenuItem>
                      );
                    })}
                  </Select>
                );
              }}
            />
            <FormHelperText error>{errors.gameTemplate?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('displayResult')}</InputLabel>
            <Controller
              control={control}
              name="displayResult"
              render={({ field: { value } }) => {
                return (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="displayResult"
                    defaultValue={value}
                    value={value}
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      const name = e.target.value;
                      setValue('displayResult', name as DisplayResult);
                    }}
                  >
                    {DISPLAY_RESULTS.map((result) => {
                      return (
                        <MenuItem key={result} value={result} sx={{ fontSize: '1.6rem' }}>
                          {result}
                        </MenuItem>
                      );
                    })}
                  </Select>
                );
              }}
            />
          </FormControl>

          {isEditMode && (
            <>
              <TextField
                margin="normal"
                type="number"
                disabled
                fullWidth
                label={t('minPoints')}
                {...register('minPoints', { valueAsNumber: true })}
              />
              <TextField
                margin="normal"
                type="number"
                disabled
                fullWidth
                label={t('maxPoints')}
                {...register('maxPoints', { valueAsNumber: true })}
              />
            </>
          )}

          <ExclusiveContent
            companies={companies.results}
            alreadyChosenCompanies={isEditMode ? exclusiveCompanies : []}
            isAlreadyExclusive={!!selectedTest?.exclusive}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('howToPlay')}</InputLabel>
            <TextareaAutosize
              aria-label="howToPlay"
              minRows={4}
              {...register('howToPlay')}
              placeholder={t('howToPlay')}
              className={classes.textArea}
            />
            <FormHelperText error>{errors.howToPlay?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('mindset')}</InputLabel>
            <TextareaAutosize
              aria-label="mindset"
              minRows={4}
              {...register('mindset')}
              placeholder={t('mindset')}
              className={classes.textArea}
            />
            <FormHelperText error>{errors.mindset?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('preResults')}</InputLabel>
            <TextareaAutosize
              aria-label="preResults"
              minRows={4}
              {...register('preResults')}
              placeholder={t('preResults')}
              className={classes.textArea}
            />
            <FormHelperText error>{errors.preResults?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('userResults')}</InputLabel>
            <TextareaAutosize
              aria-label="userResults"
              minRows={4}
              {...register('userResults')}
              placeholder={t('userResults')}
              className={classes.textArea}
            />
            <FormHelperText error>{errors.userResults?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">{t('info')}</InputLabel>
            <TextareaAutosize
              aria-label="info"
              minRows={4}
              {...register('info')}
              placeholder={t('info')}
              className={classes.textArea}
            />
            <FormHelperText error>{errors.info?.message}</FormHelperText>
          </FormControl>

          <Box sx={{ marginTop: '32px' }}>
            <Button variant="outlined" type="button" onClick={history.goBack}>
              {t('admin:goBack')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: '20px' }}
            >
              {t(isEditMode ? 'updateTest' : 'createTest')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
};
