import i18n from 'translation/i18n';

interface INavigation {
  name: string;
  path: string;
  activeLinks: string;
  exact: boolean;
  isDropDown?: boolean;
  links?: INavigation[];
}

export const navigation: INavigation[] = [
  {
    name: `${i18n.t('navigation:overallDashboard')}`,
    path: '/',
    activeLinks: '/',
    exact: true,
  },
  {
    name: `${i18n.t('navigation:overallCompanies')}`,
    path: '/companies-dashboard/companies',
    activeLinks: 'companies-dashboard',
    exact: false,
  },
  {
    name: `${i18n.t('navigation:users')}`,
    path: '/users',
    activeLinks: 'users',
    exact: false,
  },
  {
    name: `${i18n.t('navigation:create')}`,
    path: '/create-session/sessions',
    activeLinks: 'create-session',
    exact: false,
  },
];
