import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  wrapper: {
    gridColumn: '1 / 2',
    gridRow: '3 / 4',
    height: '300px',
  },
});

export const UserOptions = () => {
  const classes = useStyles();
  const { t } = useTranslation('userOptions');
  return (
    <Card className={classes.wrapper}>
      <CardHeader title={t('title')} />
      <CardContent>{t('comingSoon')}</CardContent>
    </Card>
  );
};
