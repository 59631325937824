import { Box, Card, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoginForm, Logo } from 'components';

import grid from 'assets/images/grid.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: '470px',
      height: 'auto',
      boxShadow: theme.shadows[24],
      padding: '40px', //theme.spacing(1)
      borderRadius: '5px',
      position: 'relative',
      zIndex: 10,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '5rem',
    },
    dots: {
      position: 'relative',
      right: '-40px',
      zIndex: -20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

export const AuthPage: React.FC = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img className={classes.dots} src={grid} alt="grid" />
      <Card className={classes.card}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
        <Box>
          <Typography variant="h3">{t('welcomeMessage')}</Typography>
          <Typography variant="body1">{t('welcomeSubtitle')}</Typography>
        </Box>
        <LoginForm />
      </Card>
    </div>
  );
};
