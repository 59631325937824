import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUserBadgeResponse } from './badgesSlice';
import { usersAPI } from 'utils/axiosConfig';

export const fetchUserBadges = createAsyncThunk<IUserBadgeResponse[], string>(
  'badgesActions/fetchUserBadges',
  async (id) => {
    const response = await usersAPI(`/api/admin/user_badges?userId=${id}&pagination=false`);
    return response.data.results;
  },
);
