import React from 'react';

import community1 from 'assets/icons/badges/community/community1.svg';
import community2 from 'assets/icons/badges/community/community2.svg';
import community3 from 'assets/icons/badges/community/community3.svg';
import community4 from 'assets/icons/badges/community/community4.svg';
import daily1 from 'assets/icons/badges/daily-checks/daily1.svg';
import daily2 from 'assets/icons/badges/daily-checks/daily2.svg';
import daily3 from 'assets/icons/badges/daily-checks/daily3.svg';
import daily4 from 'assets/icons/badges/daily-checks/daily4.svg';
import daily5 from 'assets/icons/badges/daily-checks/daily5.svg';
import daily6 from 'assets/icons/badges/daily-checks/daily6.svg';
import life1 from 'assets/icons/badges/life/life1.svg';
import life2 from 'assets/icons/badges/life/life2.svg';
import life3 from 'assets/icons/badges/life/life3.svg';
import life4 from 'assets/icons/badges/life/life4.svg';
import life5 from 'assets/icons/badges/life/life5.svg';
import life6 from 'assets/icons/badges/life/life6.svg';
import apr from 'assets/icons/badges/months/apr.svg';
import aug from 'assets/icons/badges/months/aug.svg';
import dec from 'assets/icons/badges/months/dec.svg';
import feb from 'assets/icons/badges/months/feb.svg';
import jan from 'assets/icons/badges/months/jan.svg';
import jul from 'assets/icons/badges/months/jul.svg';
import jun from 'assets/icons/badges/months/jun.svg';
import mar from 'assets/icons/badges/months/mar.svg';
import may from 'assets/icons/badges/months/may.svg';
import nov from 'assets/icons/badges/months/Nov.svg';
import oct from 'assets/icons/badges/months/oct.svg';
import sep from 'assets/icons/badges/months/sep.svg';
import nugget1 from 'assets/icons/badges/nuggets/nugget1.svg';
import nugget2 from 'assets/icons/badges/nuggets/nugget2.svg';
import nugget3 from 'assets/icons/badges/nuggets/nugget3.svg';
import nugget4 from 'assets/icons/badges/nuggets/nugget4.svg';
import nugget5 from 'assets/icons/badges/nuggets/nugget5.svg';
import nugget6 from 'assets/icons/badges/nuggets/nugget6.svg';
import progress1 from 'assets/icons/badges/progress/progress1.svg';
import progress2 from 'assets/icons/badges/progress/progress2.svg';
import progress3 from 'assets/icons/badges/progress/progress3.svg';
import progress4 from 'assets/icons/badges/progress/progress4.svg';
import progress5 from 'assets/icons/badges/progress/progress5.svg';
import progress6 from 'assets/icons/badges/progress/progress6.svg';
import progress7 from 'assets/icons/badges/progress/progress7.svg';
import progress8 from 'assets/icons/badges/progress/progress8.svg';
import progress9 from 'assets/icons/badges/progress/progress9.svg';
import share1 from 'assets/icons/badges/shared/share1.svg';
import share2 from 'assets/icons/badges/shared/share2.svg';
import share3 from 'assets/icons/badges/shared/share3.svg';
import share4 from 'assets/icons/badges/shared/share4.svg';
import share5 from 'assets/icons/badges/shared/share5.svg';
import share6 from 'assets/icons/badges/shared/share6.svg';
import task1 from 'assets/icons/badges/tasks/task1.svg';
import task2 from 'assets/icons/badges/tasks/task2.svg';
import task3 from 'assets/icons/badges/tasks/task3.svg';
import task4 from 'assets/icons/badges/tasks/task4.svg';
import weekly1 from 'assets/icons/badges/weekly/weekly1.svg';
import weekly2 from 'assets/icons/badges/weekly/weekly2.svg';
import weekly3 from 'assets/icons/badges/weekly/weekly3.svg';
import weekly4 from 'assets/icons/badges/weekly/weekly4.svg';
import weekly5 from 'assets/icons/badges/weekly/weekly5.svg';
import weekly6 from 'assets/icons/badges/weekly/weekly6.svg';
import weekly7 from 'assets/icons/badges/weekly/weekly7.svg';

interface IBadges {
  [x: string]: string;
}

const badges: IBadges = {
  Community1: community1,
  Community2: community2,
  Community3: community3,
  Community4: community4,
  Daily1: daily1,
  Daily2: daily2,
  Daily3: daily3,
  Daily4: daily4,
  Daily5: daily5,
  Daily6: daily6,
  Weekly1: weekly1,
  Weekly2: weekly2,
  Weekly3: weekly3,
  Weekly4: weekly4,
  Weekly5: weekly5,
  Weekly6: weekly6,
  Weekly7: weekly7,
  Life1: life1,
  Life2: life2,
  Life3: life3,
  Life4: life4,
  Life5: life5,
  life6: life6,

  Jan: jan,
  Feb: feb,
  Mar: mar,
  Apr: apr,
  May: may,
  Jun: jun,
  Jul: jul,
  Aug: aug,
  Sep: sep,
  Oct: oct,
  Nov: nov,
  Dec: dec,

  Nugget1: nugget1,
  Nugget2: nugget2,
  Nugget3: nugget3,
  Nugget4: nugget4,
  Nugget5: nugget5,
  Nugget6: nugget6,

  Progress1: progress1,
  Progress2: progress2,
  Progress3: progress3,
  Progress4: progress4,
  Progress5: progress5,
  Progress6: progress6,
  Progress7: progress7,
  Progress8: progress8,
  Progress9: progress9,

  Share1: share1,
  Share2: share2,
  Share3: share3,
  Share4: share4,
  Share5: share5,
  Share6: share6,

  Task1: task1,
  Task2: task2,
  Task3: task3,
  Task4: task4,
};

interface IProps {
  title: string;
}

export const Badge: React.FC<IProps> = ({ title, ...props }) => {
  const customBadge = badges[title];

  return (
    <img {...props} style={{ width: '60px', padding: '5px' }} src={customBadge} alt={customBadge} />
  );
};
