import { Theme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gradients: {
      [key: number]: string;
    };
  }

  interface PaletteOptions {
    gradients: {
      [key: number]: string;
    };
  }
}

export default function Button({ palette }: Theme) {
  // TODO - type ts
  return {
    MuiButton: {
      root: {
        height: 48,
        padding: '0 30px',
        borderRadius: 5,
        minWidth: '200px',
        fontFamily: 'MarkProMedium',
        fontSize: '1.4rem',

        '&.MuiButton-containedSizeSmall': {
          width: '90px',
          height: '30px',
          padding: '0 10px',
          minWidth: '70px',
          fontSize: '1.1rem',
          fontWeight: 'bold',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },

        '&:active': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        background: palette.gradients[300],
        color: palette.common.white,

        '&:hover': {
          background: palette.gradients[400],
        },
      },
      containedSecondary: {
        color: palette.common.black,
        background: palette.common.white,
        border: `2px solid ${palette.grey[400]}`,

        '&:hover': {
          border: `2px solid ${palette.action.hover}`,
          background: palette.common.white,
        },
      },
      disabled: {
        background: palette.grey[200],
        border: `2px solid ${palette.grey[200]}`,
      },
    },
  };
}
