import { Emoji } from 'types';

export const emojis: Emoji[] = [
  {
    name: 'Amazing',
    character: '😀',
  },
  {
    name: 'Good',
    character: '🙂',
  },
  {
    name: 'Ok',
    character: '😐',
  },
  {
    name: 'Bad',
    character: '😟',
  },
  {
    name: 'Awful',
    character: '🤬',
  },
];
