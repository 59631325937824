import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';

import ComponentsOverrides from './overrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

import '@material-ui/core/styles';

const theme = createTheme({ palette, typography, shadows });
theme.overrides = ComponentsOverrides(theme);
theme.props = {
  MuiInputLabel: {
    shrink: true,
  },
  MuiInput: {
    disableUnderline: true,
  },
  MuiListItem: {
    disableGutters: true,
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: 'h4' },
    subheaderTypographyProps: { variant: 'body1' },
  },
};

export const ThemeConfig: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
