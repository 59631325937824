import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IUser,
  IUserDailyTracking,
  IUserQualities,
  IUserResponse,
  PaginationType,
  UserProfile,
  UserProfileParams,
  UsersParams,
} from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchUsers = createAsyncThunk<
  { pagination: PaginationType; results: IUserResponse[] },
  UsersParams
>('userActions/fetchUsers', async ({ id, companyName, accessCode }) => {
  const params = {};
  id && Object.assign(params, { id });
  companyName && Object.assign(params, { 'company.name': companyName });
  accessCode && Object.assign(params, { accessCode: accessCode });
  // TODO: Add pagination to user table
  const response = await usersAPI.get(`/api/admin/users?pagination=false`, { params });

  return response.data;
});

export const fetchUser = createAsyncThunk<IUser, UsersParams>(
  'userActions/fetchUser',
  async ({ id, companyName, accessCode }) => {
    const params = {};
    id && Object.assign(params, { id });
    companyName && Object.assign(params, { 'company.name': companyName });
    accessCode && Object.assign(params, { accessCode: accessCode });
    const response = await usersAPI.get(`/api/admin/users?pagination=false`, { params });

    return response.data.results[0];
  },
);

export const fetchUserDailyTracking = createAsyncThunk<IUserDailyTracking[], string>(
  'userActions/fetchUserDailyTracking',
  async (id) => {
    const response = await usersAPI(`/api/admin/daily_trackings?user.id=${id}&pagination=false`);
    return response.data.results;
  },
);

export const fetchUserQualities = createAsyncThunk<IUserQualities[], string>(
  'userActions/fetchUserQualities',
  async (id) => {
    const response = await usersAPI(`/api/user_qualities?user.id=${id}`);
    return response.data;
  },
);

export const fetchUserProfiles = createAsyncThunk<{ results: UserProfile[] }, UserProfileParams>(
  'userActions/fetchUserProfile',
  async ({ id, companyId }) => {
    const params = {};
    id && Object.assign(params, { id });
    companyId && Object.assign(params, { company_id: companyId });

    const response = await usersAPI.get(`/api/admin/user_profiles?pagination=false`, { params });

    return response.data;
  },
);

export const toggleUserStatus = createAsyncThunk<IUserResponse, IUser>(
  'userActions/toggleUserStatus',
  async ({ id, blocked }) => {
    const response = await usersAPI.patch(`/api/admin/users/${id}/block`, {
      isBlocked: !blocked,
    });

    return response.data;
  },
);
