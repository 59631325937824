import { ExclusiveCompany } from 'types';

export type GoalStatus = 'ACTIVE' | 'INACTIVE' | 'DONE';

export type FocusArea =
  | 'COMMUNICATIONS'
  | 'PRODUCTIVITY'
  | 'PROBLEM_SOLVING'
  | 'ADAPTABILITY'
  | 'LEADERSHIP';

export type IChartValueState = 'monthAgo' | 'sevenDaysAgo' | 'sinceRelease' | 'sinceContracted';

export enum BadgeStatus {
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
}

export interface IChartData {
  name: string;
  data: number[] | { x: string; y: number }[];
}

export interface IGoalType {
  id: number;
  focusArea: FocusArea;
  name: string;
  exclusive: boolean;
}

export interface IGoal {
  id: number;
  focusArea: FocusArea;
  name: string;
  trending?: boolean;
  exclusive: boolean;
}

export interface IBaseUser {
  id: number;
  email: string;
  firstName: string;
  birthday: string;
  company: ICompany;
  createdAt: string;
  accessCode?: string;
  loginTimestamp: string;
}
export interface IUser extends IBaseUser {
  blocked: 0 | 1;
}

export interface IUserResponse extends IBaseUser {
  isBlocked: boolean;
}

export interface IPill {
  id: number;
  videoId: string;
  category: PillCategories;
  name: string;
  memberName: string;
  duration: number;
  transcription?: string;
  trending?: boolean;
  logo: string;
  exclusive: boolean;
}

export type PillCategories = 'VIDEO' | 'AUDIO';

export interface ITest {
  id: number;
  name: string;
  testType: ScoringType;
  gameTemplate: GameTemplate;
  displayResult: DisplayResult;
  minPoints: number;
  maxPoints: number;
  howToPlay: string;
  mindset: string;
  preResults?: string;
  userResults: string;
  info: string;
  numberOfAnswers: number;
  isEmpty: boolean;
  exclusive: boolean;
}

export interface IDailyTask {
  id: number;
  title: string;
  goal: { id: number; name: string };
  description: string;
  externalReward: ExternalReward | null;
  link: string | null;
}

export interface UserProfile {
  id: number;
  userId: number;
  finishedTutorial: boolean;
  loginTimestamp: string;
  avatarId: number;
}

export interface IMostActiveUser {
  id?: number;
  date: string;
  firstName: string;
}

export interface IUserQualities {
  id: number;
  user: Pick<IUser, 'id'>;
  selfConfidence: number;
  organisation: number;
  empathy: number;
  focusOnDetail: number;
  sociability: number;
  openMindedness: number;
  energy: number;
  riskAppetite: number;
}

export interface IUserDailyTracking {
  id: number;
  user: Pick<IUser, 'id'>;
  date: string;
  currentStreak: number;
  currentWeekStreak: number;
}

export interface IDailyTracker {
  id: number;
  date: string;
  currentStreak: number;
  user: Pick<IUser, 'id'>;
}

export interface ICompany {
  id: number;
  name: string;
}

export interface IUserNugget {
  id: number;
}

export type NuggetType = 'INSPIRATIONAL_STORY' | 'GUIDANCE_QUOTE' | 'PRACTICAL_TIP';

export interface Nugget {
  id: number;
  description: string;
  type: NuggetType;
  goal: IGoal;
}

export interface IGame {
  id: number;
  name: string;
  type: string;
  trending?: boolean;
}

export interface IUserGame {
  id: number;
  result: number;
  game: IGame;
}

export interface IUserGoals {
  id: number;
  user: {
    company: {
      id: number;
      name: string;
    };
  };
  goal: {
    id: number;
    focusArea: FocusArea;
    name: string;
    goalLength: {
      length: number;
    };
    trending: boolean;
    exclusive: boolean;
  };
  status: GoalStatus;
  completedSessions: number;
}

export interface IGamesResult {
  game: IGame;
  id: number;
  result: number;
}

export interface ICombineGamesWithResult extends IGamesResult {
  type: string;
  name: string;
}

export interface IUserSessions {
  id: number;
  step: number;
  userGame: IUserGame[];
  goal: IGoal;
  user: Pick<IUser, 'id'>;
  userNuggets: IUserNugget;
}

export interface IUsersWithDailyTracking {
  company: ICompany;
  createdAt: Date;
  currentStreak: number;
  currentWeekStreak: number;
  date: string;
  email: string;
  id: number;
  user: Pick<IUser, 'id'>;
  firstName: string;
  birthday: string;
}

export interface Session {
  id: number;
  test: ITest;
  pill: IPill;
  game: IGame;
  nugget: Nugget;
  task: IDailyTask;
  goal: IGoalType;
  tasksOrder: string;
  textAfterTest: string;
  textBeforeAudio: string;
  textAfterAudio: string;
  textBeforeTask: string;
  textAfterTask: string[];
}
export interface ConvertedSession {
  test: number;
  pill: number;
  game: number | null;
  nugget: number;
  task: number;
  goal: number;
  tasksOrder: string;
  textAfterTest: string;
  textBeforeAudio: string;
  textAfterAudio: string;
  textBeforeTask: string;
  textAfterTask: string[];
}

export interface ConvertedVideo {
  name: string;
  id: number;
  videoId: string;
}

export interface FileType {
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  __proto__: string;
  message?: string;
}

export type ScoringType = 'SUM' | 'AVG' | 'MEAN' | 'PERCENTAGE';

export type GameTemplate =
  | 'pinBoard'
  | 'sortingCardsMethod'
  | 'basketBall'
  | 'ballPicker'
  | 'puzzle';

export type DisplayResult = 'scale' | 'number' | 'topWords';

export type Question = {
  id: number;
  question: string;
  answers: QuestionAnswers[];
  explanation: string;
};

export type QuestionAnswers = {
  id: number;
  answer: string;
  questionId: number;
  weight?: number;
  meaning?: string;
};

export type TestTypeAPI = 'mean' | 'sumavg';

export type VideoStatus =
  | 'available'
  | 'uploading_error'
  | 'transcoding'
  | 'transcoding_error'
  | 'transcode_starting'
  | 'total_cap_exceeded'
  | 'quota_exceeded'
  | 'unavailable'
  | 'uploading';

export type UploadStatus = 'complete' | 'error' | 'in_progress';

export type VimeoApiResponse = {
  uri: string;
  type: string;
  status: VideoStatus;
  upload: { upload_link: string; status: UploadStatus };
  transcode: { status: UploadStatus };
};

export type GoogleApiParams = {
  refresh_token?: string;
  code?: string;
};

export type GoogleApiResponse = {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
  id_token: string;
  refresh_token: string;
};
export type Paths =
  | '/create-session/sessions'
  | '/create-session/goals'
  | '/create-session/pills'
  | '/create-session/daily-tasks'
  | '/create-session/nuggets'
  | '/create-session/tests';

export type UsersParams = { id?: string; companyName?: string; accessCode?: string };

export interface FocusAreaType {
  label: string;
  icon: string;
  contentColor: string;
  backgroundColor: string;
}

export type DailyCheckIn = {
  totalAVG: number;
  topReasons: { [name: string]: number };
  topPairs: { [name: string]: { [name: string]: number } };
};

export type EmojiName = 'Amazing' | 'Good' | 'Ok' | 'Bad' | 'Awful';
export type EmojiCharacter = '😀' | '🙂' | '😐' | '😟' | '🤬';

export type Emoji = {
  name: EmojiName;
  character: EmojiCharacter;
};

export type DailyCheckInReasonName =
  | 'Social media'
  | 'Family'
  | 'Work'
  | 'Sleep'
  | 'Finance'
  | 'Friends'
  | 'Weather'
  | 'Health'
  | 'Partner'
  | 'The news'
  | 'Music'
  | 'Traveling'
  | 'Hobbies'
  | 'Self-image'
  | 'Other';

export type DailyCheckInReasonIcon =
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | ''
  | '';

export interface DailyCheckInReason {
  name: DailyCheckInReasonName;
  icon: DailyCheckInReasonIcon;
}
export type PaginationOptions = {
  page?: number;
  pagination?: boolean;
  id?: number;
  type?: TestTypeAPI;
  search?: string;
};

export type PaginationType = { page: number; allPages: number };

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export type ExternalReward = {
  id: number;
  name: string;
  description: string;
  logo: string;
  link: string;
  status: RewardStatus;
};

export type RewardStatus = 'active' | 'inactive' | 'archived';

export type ErrorType = {
  apiResponse: string;
  message: string;
};

export type SymfonyError = {
  title: string;
  status: number;
  detail: string;
};

export type TrendingType = 'pills' | 'goals' | 'games' | 'tests';

export type Trending = { pills: IPill[]; games: IGame[]; goals: IGoal[]; tests: TestTrending[] };

export interface TestTrending {
  id: number;
  name: string;
  scoringType: ScoringType;
  gameTemplate: GameTemplate;
  trending: boolean;
  createdAt: string;
}
export interface UserProfileParams {
  id?: number;
  companyId?: number;
}

export interface TestFormType extends ITest {
  exclusiveCompanies: ExclusiveCompany[];
}

export interface CheckAdditionalCompanyGoalParams {
  companyId: number;
  goalId: number;
  cb: (hasGoalRelations: boolean) => void;
}
