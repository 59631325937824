import { format } from 'date-fns';
import React from 'react';
import { FiLock, FiUnlock, FiUser } from 'react-icons/fi';
import { CellProps, Column } from 'react-table';
import i18n from 'translation/i18n';

import { PopUpMenu, PopUpMenuItem } from 'components';

export interface IColumnsAccessor {
  id: number;
  email: string;
  firstName: string;
  birthday: string;
  blocked: 0 | 1;
  options?: string;
}

export const COLUMNS: Column<IColumnsAccessor>[] = [
  //TODO: add i18next translation to Header
  {
    Header: `${i18n.t('table:id')}`,
    accessor: 'id' as keyof IColumnsAccessor,
  },
  {
    Header: `${i18n.t('table:email')}`,
    accessor: 'email' as keyof IColumnsAccessor,
  },
  {
    Header: `${i18n.t('table:firstName')}`,
    accessor: 'firstName' as keyof IColumnsAccessor,
  },
  {
    Header: `${i18n.t('table:dateOfBirth')}`,
    accessor: 'birthday' as keyof IColumnsAccessor,
    Cell: ({ value }: CellProps<IColumnsAccessor>) => {
      if (!value) {
        return `${i18n.t('table:noDate')}`;
      } else {
        return format(new Date(value), 'dd/MM/yyyy');
      }
    },
  },
  {
    Header: `${i18n.t('table:company')}`,
    accessor: 'company.name' as keyof IColumnsAccessor,
  },
  {
    Header: `${i18n.t('table:status')}`,
    accessor: 'blocked' as keyof IColumnsAccessor,
    Cell: ({ value }: CellProps<IColumnsAccessor>) => {
      return value ? <>{i18n.t('table:block')}</> : <>{i18n.t('table:active')}</>;
    },
  },
  {
    Header: `${i18n.t('table:options')}`,
    accessor: 'options ' as keyof IColumnsAccessor,
    Cell: (props: CellProps<IColumnsAccessor>) => {
      const { id, blocked } = props.row.original;

      return (
        <PopUpMenu>
          <PopUpMenuItem
            onClick={() => props.handlePatientDetails(id)}
            text={i18n.t('table:details')}
            icon={<FiUser size={20} />}
          />
          <PopUpMenuItem
            onClick={() => props.handleTogglePatientStatus(props.row.original)}
            text={i18n.t(`table:${blocked ? 'unblock' : 'block'}`)}
            icon={blocked ? <FiUnlock size={20} /> : <FiLock size={20} />}
          />
        </PopUpMenu>
      );
    },
  },
];
