import { Card, makeStyles } from '@material-ui/core';
import React from 'react';

interface UserGoalItem {
  id: number;
  goal: {
    name: string;
  };
}

const useStyles = makeStyles({
  wrapper: {
    margin: '20px 0',
  },
  card: {
    padding: '14px',
  },
});

export const UserGoalsItem: React.FC<UserGoalItem> = ({ goal, id }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>{goal.name}</Card>
    </div>
  );
};
