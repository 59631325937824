import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'translation/i18n';

import {
  fetchActiveUsers,
  fetchAverageTime,
  fetchBodyType,
  fetchDownloads,
  fetchTopCities,
} from './analyticsActions';
import { RootState } from 'redux/store';
import { ActiveUsers, AnalyticsResult, AnalyticsResultRows, Events, Genders } from 'types';
import { secondsToHms } from 'utils/helperFunctions';

interface AnalyticsState {
  topCities: AnalyticsResultRows[];
  downloads: number;
  loading: {
    fetchTopCities: boolean;
    fetchActiveUsers: boolean;
    fetchDownloads: boolean;
    fetchBodyType: boolean;
    fetchAverageTime: boolean;
  };
  error: string;
  genders: Genders;
  events: Events[];
  activeUsers: ActiveUsers;
  averageTime: string;
}

const initialState: AnalyticsState = {
  topCities: [],
  downloads: 0,
  genders: { male: 0, female: 0 },
  loading: {
    fetchTopCities: false,
    fetchActiveUsers: false,
    fetchDownloads: false,
    fetchBodyType: false,
    fetchAverageTime: false,
  },
  error: '',
  averageTime: '',
  events: [],
  activeUsers: { dailyValue: [], dailyLabel: [], monthlyValue: [], monthlyLabel: [] },
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchTopCities.pending, (state) => {
        state.error = '';
        state.loading.fetchTopCities = true;
      })
      .addCase(fetchTopCities.fulfilled, (state, action: PayloadAction<AnalyticsResult>) => {
        state.loading.fetchTopCities = false;
        state.topCities = action.payload.rows;
      })
      .addCase(fetchTopCities.rejected, (state, action) => {
        state.loading.fetchTopCities = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchActiveUsers.pending, (state) => {
        state.error = '';
        state.loading.fetchActiveUsers = true;
      })
      .addCase(
        fetchActiveUsers.fulfilled,
        (state, action: PayloadAction<{ daily: boolean; data: AnalyticsResultRows[] }>) => {
          const { daily, data } = action.payload;
          state.loading.fetchActiveUsers = false;
          const label = data.map((x) => x.dimensionValues[0].value);
          const value = data.map((x) => parseInt(x.metricValues[0].value));
          if (daily) {
            state.activeUsers = {
              ...state.activeUsers,
              dailyLabel: label,
              dailyValue: value,
            };
          } else {
            state.activeUsers = {
              ...state.activeUsers,
              monthlyLabel: label,
              monthlyValue: value,
            };
          }
        },
      )
      .addCase(fetchActiveUsers.rejected, (state, action) => {
        state.loading.fetchActiveUsers = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchDownloads.pending, (state) => {
        state.error = '';
        state.loading.fetchDownloads = true;
      })
      .addCase(fetchDownloads.fulfilled, (state, action: PayloadAction<AnalyticsResult>) => {
        state.loading.fetchDownloads = false;
        state.downloads = parseInt(action.payload.totals[0].metricValues[0].value);
      })
      .addCase(fetchDownloads.rejected, (state, action) => {
        state.loading.fetchDownloads = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchAverageTime.pending, (state) => {
        state.error = '';
        state.loading.fetchAverageTime = true;
      })
      .addCase(fetchAverageTime.fulfilled, (state, action: PayloadAction<AnalyticsResult>) => {
        state.loading.fetchAverageTime = false;
        const metricValues = action.payload.rows[0].metricValues;

        state.averageTime = secondsToHms(
          Math.round(parseInt(metricValues[0].value) / parseInt(metricValues[1].value)),
        );
      })
      .addCase(fetchAverageTime.rejected, (state, action) => {
        state.loading.fetchAverageTime = false;
        state.error = action.error.message || i18next.t('error:other');
      })
      .addCase(fetchBodyType.pending, (state) => {
        state.error = '';
        state.loading.fetchBodyType = true;
      })
      .addCase(
        fetchBodyType.fulfilled,
        (state, action: PayloadAction<{ bodyType: number; count: number }>) => {
          const { bodyType, count } = action.payload;
          state.loading.fetchBodyType = false;
          if (bodyType === 0) state.genders = { ...state.genders, female: count };
          if (bodyType === 1) state.genders = { ...state.genders, male: count };
        },
      )
      .addCase(fetchBodyType.rejected, (state, action) => {
        state.loading.fetchBodyType = false;
        state.error = action.error.message || i18next.t('error:other');
      }),
});

export const analyticsSelector = (state: RootState) => state.analytics;

export default analyticsSlice.reducer;
