import { Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { default as Tab } from '@material-ui/core/Tab';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { CreateQuestion } from './CreateQuestion';
import { CreateReward } from './CreateReward';
import { QuestionsPage } from './QuestionsPage';
import { RewardsPage } from './RewardsPage';
import { CustomLoader } from 'components';
import {
  CreateGoal,
  CreateRealLifeTask,
  CreateNugget,
  CreatePill,
  CreateSession,
  CreateTest,
  DailyTasksPage,
  GoalsPage,
  NuggetsPage,
  PillsPage,
  SessionsPage,
  TestsPage,
  TrendingPage,
} from 'pages';
import {
  fetchDailyTask,
  fetchExternalRewards,
  fetchGames,
  fetchNuggets,
  fetchPills,
  fetchTests,
} from 'redux/createSession/createSessionAction';
import { fetchTypesOfGoals } from 'redux/goals/goalsActions';
import { TabPanelProps } from 'types';
import { MainWrapper } from 'wrappers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      maxWidth: '1400px',
      alignSelf: 'center',
      marginBottom: '20px',
    },
    indicator: { background: theme.palette.gradients[400], minHeight: '3px' },
    scrollButton: {
      '& .MuiSvgIcon-root': {
        fontSize: 24,
      },
    },
  }),
);

export const CreateCard: React.FC = () => {
  const classes = useStyles();
  const { url, path } = useRouteMatch();
  const pathVariable = window.location.pathname;

  const [value, setValue] = React.useState<number>(8);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (value === 4 || value === 3) dispatch(fetchTypesOfGoals({ pagination: false }));
    if (value === 3) dispatch(fetchExternalRewards({ pagination: false }));
    const fetchSessions = async () => {
      setIsLoading(true);
      await dispatch(fetchPills({ pagination: false }));
      await dispatch(fetchTypesOfGoals({ pagination: false }));
      await dispatch(fetchGames());
      await dispatch(fetchTests({ pagination: false }));
      await dispatch(fetchDailyTask({ pagination: false }));
      await dispatch(fetchNuggets({ pagination: false }));
      setIsLoading(false);
    };

    if (value === 0) {
      fetchSessions();
    }
  }, [dispatch, value]);

  useEffect(() => {
    if (pathVariable.includes('/sessions') && value !== 0) setValue(0);
    else if (pathVariable.includes('/pathways') && value !== 1) setValue(1);
    else if (pathVariable.includes('/pills') && value !== 2) setValue(2);
    else if (pathVariable.includes('/daily-tasks') && value !== 3) setValue(3);
    else if (pathVariable.includes('/nuggets') && value !== 4) setValue(4);
    else if (pathVariable.includes('/tests') && value !== 5) setValue(5);
    else if (pathVariable.includes('/rewards') && value !== 6) setValue(6);
    else if (pathVariable.includes('/trending') && value !== 7) setValue(7);
  }, [pathVariable, value]);

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <MainWrapper>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Card className={classes.wrapper}>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            display="flex"
            justifyContent="center"
            width="100%"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
              TabIndicatorProps={{ className: classes.indicator }}
              TabScrollButtonProps={{
                classes: { root: classes.scrollButton },
              }}
            >
              <Tab label="Sessions" component={Link} to={`${url}/sessions`} />
              <Tab label="Pathways" component={Link} to={`${url}/pathways`} />
              <Tab label="Pills" component={Link} to={`${url}/pills`} />
              <Tab label="Real-life Tasks" component={Link} to={`${url}/daily-tasks`} />
              <Tab label="Tips" component={Link} to={`${url}/nuggets`} />
              <Tab label="Tests" component={Link} to={`${url}/tests`} />
              <Tab label="Rewards" component={Link} to={`${url}/rewards`} />
              <Tab label="Trending" component={Link} to={`${url}/trending`} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            {isLoading ? (
              <CustomLoader />
            ) : (
              <>
                <Route exact path={`${path}/sessions`} component={SessionsPage} />
                <Route exact path={`${path}/sessions/:id`} component={CreateSession} />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Route exact path={`${path}/pathways`} component={GoalsPage} />
            <Route exact path={`${path}/pathways/:id`} component={CreateGoal} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Route exact path={`${path}/pills`} component={PillsPage} />
            <Route exact path={`${path}/pills/:id`} component={CreatePill} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Route exact path={`${path}/daily-tasks`} component={DailyTasksPage} />
            <Route exact path={`${path}/daily-tasks/:id`} component={CreateRealLifeTask} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Route exact path={`${path}/nuggets`} component={NuggetsPage} />
            <Route exact path={`${path}/nuggets/:id`} component={CreateNugget} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Route exact path={`${path}/tests`} component={TestsPage} />
            <Route exact path={`${path}/tests/:id`} component={CreateTest} />
            <Route exact path={`${path}/tests/questions/:testId`} component={QuestionsPage} />
            <Route
              exact
              path={`${path}/tests/questions/:testId/:questionId`}
              component={CreateQuestion}
            />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Route exact path={`${path}/rewards`} component={RewardsPage} />
            <Route exact path={`${path}/rewards/:id`} component={CreateReward} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Route exact path={`${path}/trending`} component={TrendingPage} />
          </TabPanel>
        </Card>
      </Grid>
    </MainWrapper>
  );
};
