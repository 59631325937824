import { createStyles, makeStyles, Theme } from '@material-ui/core';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Fab } from '@mui/material';
import { format } from 'date-fns';
import lodash from 'lodash';
import React, { useEffect, useRef } from 'react';
import { EditText } from 'react-edit-text';
import { useTranslation } from 'react-i18next';
import { AiOutlineFall, AiOutlineRise } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { ActiveUsersCard, DailyCheckIns, FullPageLoader, TotalSummaryWidget } from 'components';
import { BarChart } from 'components/Charts';
import {
  CompanyInsights,
  CompanyProfile,
  CurrentAreaFocus,
  SocialChallenges,
  TopCategories,
  UsersFocus,
} from 'components/CompanyDetails';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import { CustomEditIcon } from 'components/Icons/EditIcon';
import { ERROR_NOT_FOUND } from 'constants/errors';
import {
  fetchCompany,
  fetchCompanyActivity,
  updateCompany,
} from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { fetchGoals } from 'redux/goals/goalsActions';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { fetchUserProfiles, fetchUsers } from 'redux/users/usersActions';
import { usersSelector } from 'redux/users/usersSlice';
import { IUserGoals } from 'types';
import { addSignToPositive, filterUsers } from 'utils/helperFunctions';
import { MainWrapper } from 'wrappers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: '0 40px 40px',
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridTemplateRows: 'auto 250px auto',
      maxWidth: '1500px',
      margin: '0 auto',
      gap: '30px',
      WebkitPrintColorAdjust: 'exact',

      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      },
      '@media print': {
        display: 'block',
        float: 'left',
      },
      '& > *': {
        '@media print': {
          marginBottom: 100,
          pageBreakInside: 'avoid',
        },
      },
    },

    textInputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    activeUsers: {
      gridColumn: 'auto /span 9',
      gridRow: '2',
      height: '140%',
      position: 'relative',
      bottom: '40%',
      borderRadius: '10px',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    dailyCheckIns: {
      gridColumn: 'auto/span 12',
      gridRow: '5',
      display: 'grid',
      width: '100%',
      height: '100%',
    },
    fabWrapper: {
      position: 'fixed',
      right: '5%',
      bottom: '5%',
      '@media print': {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        right: 0,
        bottom: 0,
      },
    },
    fab: {
      background: theme.palette.gradients[300],
      color: theme.palette.common.white,
    },
  }),
);

export const CompanyDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['widgets', 'focusArea']);
  const { id } = useParams<{ id: string }>();
  const {
    company,
    companyActivity,
    loading: loadingCompanies,
    error,
  } = useSelector(companiesSelector);
  const { users, userProfiles, isLoading } = useSelector(usersSelector);
  const { usersGoals } = useSelector(goalsSliceSelector);

  const companyMembers = users.results.length;
  const numberID = Number(id);
  const loading =
    isLoading ||
    loadingCompanies.fetchCompanies ||
    loadingCompanies.updateCompany ||
    loadingCompanies.fetchCompanyActivity;

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page{ size: A3; margin: 20mm 0 10mm 0;padding:0; }   
    @media all {
      .pagebreak {
        display: none;
      }
    }
    body:{margin:0; padding:0;}
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }`,
  });

  useEffect(() => {
    batch(() => {
      dispatch(fetchCompany(numberID));
      if (company) {
        dispatch(fetchUsers({ accessCode: company.accessCode }));
        dispatch(fetchGoals());
        dispatch(fetchCompanyActivity(numberID));
        dispatch(fetchUserProfiles({ companyId: numberID }));
      }
    });
  }, [dispatch, company.accessCode, numberID]);

  if (loading) {
    return <FullPageLoader />;
  }

  if (error === ERROR_NOT_FOUND)
    return <ErrorAlert error={t('errors:couldntFindId')} minHeight="100vh" />;

  if (error) return <ShowError error={error} />;

  const filteredGoals = usersGoals.filter((userGoal) =>
    users.results.some((user) => userGoal.user.company.id === user.id),
  );
  const userFocusArea = (usersGoals: IUserGoals) => usersGoals.goal.focusArea;
  const groupedByFocusArea = lodash.groupBy(filteredGoals, userFocusArea);

  const goalId = (usersGoals: IUserGoals) => usersGoals.goal.id;
  const groupedByGoals = lodash.groupBy(filteredGoals, goalId);

  const communications = lodash
    .map(groupedByGoals, (x) => x.filter(({ goal }) => goal.focusArea === 'COMMUNICATIONS'))
    .filter((x) => x.length > 0);
  const productivity = lodash
    .map(groupedByGoals, (x) => x.filter(({ goal }) => goal.focusArea === 'PRODUCTIVITY'))
    .filter((x) => x.length > 0);
  const problemSolving = lodash
    .map(groupedByGoals, (x) => x.filter(({ goal }) => goal.focusArea === 'PROBLEM_SOLVING'))
    .filter((x) => x.length > 0);
  const adaptability = lodash
    .map(groupedByGoals, (x) => x.filter(({ goal }) => goal.focusArea === 'ADAPTABILITY'))
    .filter((x) => x.length > 0);
  const leadership = lodash
    .map(groupedByGoals, (x) => x.filter(({ goal }) => goal.focusArea === 'LEADERSHIP'))
    .filter((x) => x.length > 0);

  const communicationsLabels = communications.map((x) => x[0].goal.name);
  const communicationsData = communications.map((x) => x.length);
  const productivityLabels = productivity.map((x) => x[0].goal.name);
  const productivityData = productivity.map((x) => x.length);
  const problemSolvingLabels = problemSolving.map((x) => x[0].goal.name);
  const problemSolvingData = problemSolving.map((x) => x.length);
  const adaptabilityLabels = adaptability.map((x) => x[0].goal.name);
  const adaptabilityData = adaptability.map((x) => x.length);
  const leadershipLabels = leadership.map((x) => x[0].goal.name);
  const leadershipData = leadership.map((x) => x.length);

  const lastActive =
    userProfiles.length > 0 &&
    userProfiles.reduce((a, b) => (a.loginTimestamp > b.loginTimestamp ? a : b)).loginTimestamp;

  const categoriesValue = [
    {
      label: t('memberSince'),
      value: company.createdAt ? format(new Date(company.createdAt), 'dd/MM/yyyy') : '-',
    },
    {
      label: t('lastActive'),
      value: lastActive ? format(new Date(lastActive), 'dd/MM/yyyy') : '-',
    },
    {
      label: t('membershipPurchased'),
      value: (
        <Box className={classes.textInputContainer}>
          <EditText
            name="email"
            defaultValue={company.memberships.toString()}
            style={{
              width: '100px',
              height: '50px',
              textAlign: 'center',
              padding: 0,
              margin: 0,
            }}
            onSave={({ value }) => {
              dispatch(updateCompany({ id: company.id, data: { memberships: parseInt(value) } }));
            }}
          />
          <CustomEditIcon />
        </Box>
      ),
    },
    { label: t('membershipInUse'), value: companyMembers },
  ];

  const dailyActiveUsers = filterUsers(userProfiles, 'activeDaily');
  const weeklyActiveUsers = filterUsers(userProfiles, 'activeWeekly');
  const monthlyActiveUsers = filterUsers(userProfiles, 'activeMonthly');
  const lastMonthActiveUsers = filterUsers(userProfiles, 'activeLastMonth');
  const newUsers = filterUsers(users.results, 'newMonthly');
  const activeUsersDiff = monthlyActiveUsers - lastMonthActiveUsers;

  return (
    <MainWrapper>
      <div ref={componentRef} className={classes.wrapper}>
        <CompanyProfile company={company} />

        <TopCategories data={categoriesValue} />

        <ActiveUsersCard
          wrapperStyle={classes.activeUsers}
          companyActivity={companyActivity}
          height={250}
        />

        <Box
          sx={{
            gridColumn: 'auto/span 2',
            gridRow: '3',
          }}
        >
          <TotalSummaryWidget
            placeholder={t('dailyUsers')}
            dataLength={dailyActiveUsers}
            icon={<FiUsers size={35} />}
          />
        </Box>

        <Box
          sx={{
            gridColumn: 'auto /span 2',
            gridRow: '3',
          }}
        >
          <TotalSummaryWidget
            placeholder={t('weeklyUsers')}
            dataLength={weeklyActiveUsers}
            icon={<FiUsers size={35} />}
          />
        </Box>

        <Box sx={{ gridColumn: 'auto /span 2', gridRow: '3' }}>
          <TotalSummaryWidget
            placeholder={t('monthlyUsers')}
            dataLength={monthlyActiveUsers}
            icon={<FiUsers size={35} />}
          />
        </Box>

        <Box sx={{ gridColumn: 'auto /span 3', gridRow: '3' }}>
          <TotalSummaryWidget
            placeholder={t('newUsers')}
            dataLength={newUsers}
            icon={<FiUsers size={35} />}
          />
        </Box>

        <Box sx={{ gridColumn: 'auto /span 3', gridRow: '3' }}>
          <TotalSummaryWidget
            placeholder={t('activeUsers')}
            dataLength={addSignToPositive(activeUsersDiff) ?? 0}
            icon={activeUsersDiff > 0 ? <AiOutlineRise size={35} /> : <AiOutlineFall size={35} />}
          />
        </Box>

        <CurrentAreaFocus groupedByFocusArea={groupedByFocusArea} />

        <UsersFocus groupedByFocusArea={groupedByFocusArea} />

        <CompanyInsights />

        <DailyCheckIns wrapperStyle={classes.dailyCheckIns} company={company} />

        <Box
          sx={{
            gridColumn: 'auto /span 6',
            gridRow: '7',
          }}
        >
          <BarChart
            title={t('focusArea:COMMUNICATIONS')}
            data={communicationsData}
            categories={communicationsLabels}
          />
        </Box>

        <Box
          sx={{
            gridColumn: 'auto /span 6',
            gridRow: '7',
          }}
        >
          <BarChart
            title={t('focusArea:PRODUCTIVITY')}
            data={productivityData}
            categories={productivityLabels}
          />
        </Box>

        <Box
          sx={{
            gridColumn: 'auto /span 6',
            gridRow: '8',
          }}
        >
          <BarChart
            title={t('focusArea:PROBLEM_SOLVING')}
            data={problemSolvingData}
            categories={problemSolvingLabels}
          />
        </Box>

        <Box
          sx={{
            gridColumn: 'auto /span 6',
            gridRow: '8',
          }}
        >
          <BarChart
            title={t('focusArea:ADAPTABILITY')}
            data={adaptabilityData}
            categories={adaptabilityLabels}
          />
        </Box>

        <Box
          sx={{
            gridColumn: 'auto /span 6',
            gridRow: '9',
          }}
        >
          <BarChart
            title={t('focusArea:LEADERSHIP')}
            data={leadershipData}
            categories={leadershipLabels}
          />
        </Box>

        <SocialChallenges />

        <div className={classes.fabWrapper}>
          <Fab className={classes.fab} aria-label="print">
            <PictureAsPdfIcon onClick={handlePrint} sx={{ fontSize: '42px', color: 'white' }} />
          </Fab>
        </div>
      </div>
    </MainWrapper>
  );
};
