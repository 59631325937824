import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { APP_URL } from 'constants/auth';
import { GoogleApiParams, GoogleApiResponse } from 'types';

interface Credentials {
  username: string;
  password: string;
}

export const signInUser = createAsyncThunk(
  'userAuth/signInUser',
  async ({ username, password }: Credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${APP_URL}/api/users/login`, {
        username,
        password,
      });
      if (response.status === 200) {
        localStorage.setItem('token', JSON.stringify(response.data));
        return { ...response.data, username: username };
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const refreshGoogleToken = createAsyncThunk<GoogleApiResponse, GoogleApiParams>(
  'userAuth/refreshGoogleToken',
  async ({ refresh_token }) => {
    const token = localStorage.getItem('token');
    const loggedUser = JSON.parse(token!);
    const response = await axios.post(
      `${APP_URL}/api/google/refresh`,
      {
        refreshToken: refresh_token,
      },
      {
        headers: {
          Authorization: `Bearer ${loggedUser.token}`,
        },
      },
    );
    return response.data;
  },
);

export const authorizeGoogle = createAsyncThunk<GoogleApiResponse, GoogleApiParams>(
  'userAuth/authorizeGoogle',
  async ({ code }) => {
    const token = localStorage.getItem('token');
    const loggedUser = JSON.parse(token!);
    const response = await axios.post(
      `${APP_URL}/api/google/auth`,
      {
        code,
      },
      {
        headers: {
          Authorization: `Bearer ${loggedUser.token}`,
        },
      },
    );
    return response.data;
  },
);
