import { Chart } from 'chart.js';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';

import { pieBackgroundColor, pieBorderColor } from 'constants/charts';

Chart.register(ChartDataLabels);

type Props = { chartData: number[]; labels: string[]; percentage?: boolean; legendTitle: string };

export const PieChart: React.FC<Props> = ({ chartData, labels, percentage, legendTitle }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: pieBackgroundColor,
        borderColor: pieBorderColor,
        borderWidth: 1,
        hoverOffset: 20,
      },
    ],
  };

  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        layout: { padding: { top: 24, left: 24, right: 24, bottom: 100 } },
        plugins: {
          tooltip: { position: 'average', xAlign: 'left', yAlign: 'bottom' },
          legend: { title: { text: legendTitle, display: true }, position: 'right' },
          datalabels: {
            color: '#FDFEFE',
            font: { size: 16, weight: 'bold' },
            formatter: function (value: number, context: any) {
              const sum = context.chart.getDatasetMeta(0).total;
              const newValue = percentage ? Math.round((value / sum) * 100) + '%' : value;
              return value > 0 ? newValue : '';
            },
          },
        },
      }}
    />
  );
};
