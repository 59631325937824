import { Tooltip, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material';

const CustomIcon = styled(HelpOutlineIcon)(() => ({
  fontSize: 26,
  marginLeft: 10,
  alignSelf: 'center',
}));

const CustomTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: '#e1e1e1',
    fontWeight: 'normal',
    fontSize: 16,
  },
})(Tooltip);

interface Props {
  title: string;
  style?: React.CSSProperties;
}

export const HelpIcon: React.FC<Props> = ({ title, style }) => {
  return (
    <CustomTooltip title={title} placement="top-start" arrow>
      <CustomIcon style={style} />
    </CustomTooltip>
  );
};
