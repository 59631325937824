import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
  }),
);

export const ErrorText: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.errorWrapper}>{children}</div>;
};
