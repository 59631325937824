import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Box, FormControl, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CustomLoader } from 'components';
import { CustomAlert } from 'components/CustomAlert';
import { ErrorAlert } from 'components/ErrorBoundary';
import { ExclusiveContent } from 'components/ExclusiveContent/ExclusiveContent';
import { fetchCompanies, fetchExclusiveCompanies } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { createGoal, fetchTypesOfGoals, updateGoal } from 'redux/goals/goalsActions';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { ExclusiveCompany, FocusArea } from 'types';
import { getFocusArea } from 'utils/helperFunctions';

export type GoalFormType = {
  id: number;
  name: string;
  focusArea: FocusArea;
  exclusive: boolean;
  exclusiveCompanies: ExclusiveCompany[];
};

type CreatePillParams = { id: string };

export const CreateGoal: React.FC = () => {
  const {
    goalsTypes,
    isLoading: goalsLoading,
    errorMessage: goalsError,
  } = useSelector(goalsSliceSelector);
  const {
    companies,
    exclusiveCompanies,
    loading: { fetchCompanies: fetchCompaniesLoading, fetchExclusiveCompaniesLoading },
    error: companiesError,
  } = useSelector(companiesSelector);
  const { id } = useParams<CreatePillParams>();
  const { t } = useTranslation('createGoal');
  const history = useHistory();

  const isEditMode = Number.isInteger(parseInt(id));
  const selectedGoal = goalsTypes.results.find((goal) => goal.id === parseInt(id));
  const isLoading = goalsLoading || fetchCompaniesLoading || fetchExclusiveCompaniesLoading;
  const error = goalsError || companiesError;

  useEffect(() => {
    if (!selectedGoal && isEditMode) dispatch(fetchTypesOfGoals({ id: parseInt(id) }));
  }, [id]);

  useEffect(() => {
    dispatch(fetchTypesOfGoals({ pagination: false }));
  }, []);

  const schema = yup
    .object({
      name: yup.string().required(),
      focusArea: yup.string().required(),
      exclusive: yup.boolean().required(),
      exclusiveCompanies: yup.array().when('exclusive', { is: true, then: yup.array().min(1) }),
    })
    .required();

  const dispatch = useDispatch();
  const categories = [...new Set(goalsTypes.results.map((item) => item.focusArea))];

  const useFormMethods = useForm<GoalFormType>({
    defaultValues: { name: '', focusArea: undefined, exclusive: false, exclusiveCompanies: [] },
    resolver: yupResolver(schema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useFormMethods;

  useEffect(() => {
    dispatch(fetchCompanies({ pagination: false }));
    if (isEditMode) {
      if (selectedGoal) {
        const { id, name, focusArea, exclusive } = selectedGoal;
        setValue('id', id);
        setValue('name', name);
        setValue('focusArea', focusArea);
        setValue('exclusive', exclusive);
      }
      dispatch(fetchExclusiveCompanies({ id, type: 'goal' }));
    }
  }, [goalsTypes, id, isEditMode, selectedGoal, setValue]);

  useEffect(() => {
    if (isSubmitSuccessful && !isLoading && !error) {
      history.goBack();
    }
  }, [error, history, isLoading, isSubmitSuccessful]);

  const handleSelectChange = (e: any) => {
    setValue('focusArea', e.target.value);
  };

  const onSubmit = handleSubmit(async (data: GoalFormType) => {
    if (isEditMode) {
      dispatch(updateGoal(data));
    } else {
      dispatch(createGoal(data));
    }
  });

  if (isLoading) return <CustomLoader />;

  if (isEditMode && !selectedGoal) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      {error && (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CustomAlert description={error} severity="error" />
        </Grid>
      )}
      <FormProvider {...useFormMethods}>
        <form onSubmit={onSubmit}>
          <Typography variant="h2" gutterBottom>
            {isEditMode ? t('editGoal') : t('createGoal')}
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label="Name"
            {...register('name')}
            name="name"
            helperText={errors.name?.message}
            FormHelperTextProps={{ style: { color: 'red' } }}
          />
          <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('focusArea')}</InputLabel>
              <Controller
                control={control}
                name="focusArea"
                render={({ field: { value } }) => {
                  return (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="focusArea"
                      value={value || ''}
                      MenuProps={{ disableScrollLock: true }}
                      onChange={handleSelectChange}
                    >
                      {categories.map((category) => {
                        return (
                          <MenuItem key={category} value={category} sx={{ fontSize: '1.6rem' }}>
                            {getFocusArea(category).label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  );
                }}
              />
              <ExclusiveContent
                companies={companies.results}
                alreadyChosenCompanies={isEditMode ? exclusiveCompanies : []}
                isAlreadyExclusive={!!selectedGoal?.exclusive}
                goalId={selectedGoal?.id}
              />
              <FormHelperText error>{errors.focusArea?.message}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <Button variant="outlined" type="submit">
              {isEditMode ? t('updateGoal') : t('createGoal')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
};
