import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from 'translation/i18n';

import {
  Company,
  CompanyActivity,
  ExclusiveCompanyParams,
  ExclusiveCompanyResponse,
  Insight,
  InsightLabel,
  PaginationOptions,
  PaginationType,
  UpdateExclusiveCompaniesParams,
} from 'types';
import { usersAPI } from 'utils/axiosConfig';

export const fetchCompanies = createAsyncThunk<
  {
    pagination: PaginationType;
    results: Company[];
  },
  PaginationOptions
>('companiesActions/fetchCompanies', async ({ page, pagination, search, id }) => {
  const params = {};
  id && Object.assign(params, { id });
  page && Object.assign(params, { page });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI.get(`/api/admin/companies`, { params });
  return response.data;
});

export const createCompany = createAsyncThunk<Company, Partial<Company>>(
  'companiesActions/createCompany',
  async (data, { rejectWithValue }) => {
    try {
      const response = await usersAPI.post(`/api/admin/companies`, data);
      toast.success(i18n.t('form:createdSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateCompany = createAsyncThunk<Company, { id: number; data: Partial<Company> }>(
  'companiesActions/updateCompany',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await usersAPI.patch(`/api/admin/companies/${id}`, data);
      toast.success(i18n.t('form:updatedSuccessfully'));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const fetchInsights = createAsyncThunk<
  {
    pagination: PaginationType;
    results: Insight[];
  },
  PaginationOptions
>('companiesActions/fetchInsights', async ({ id, page, pagination, search }) => {
  const params = {};
  id && Object.assign(params, { id });
  page && Object.assign(params, { page });
  pagination !== undefined && Object.assign(params, { pagination });
  search && Object.assign(params, { search });
  const response = await usersAPI.get(`/api/admin/insights`, { params });
  return response.data;
});

export const fetchInsightLabels = createAsyncThunk<InsightLabel[], PaginationOptions>(
  'companiesActions/fetchInsightLabels',
  async ({ page, pagination }) => {
    const params = {};
    page && Object.assign(params, { page });
    pagination !== undefined && Object.assign(params, { pagination });
    const response = await usersAPI.get(`/api/admin/insight_labels`, { params });
    return response.data;
  },
);

export const createInsightLabel = createAsyncThunk<InsightLabel, string>(
  'companiesActions/createInsightLabel',
  async (label) => {
    const response = await usersAPI.post(`/api/admin/insight_labels`, { label });
    return response.data;
  },
);

export const createInsight = createAsyncThunk<Insight, Insight>(
  'companiesActions/createInsight',
  async (insight) => {
    const insightsId = insight.labels.map((x) => x.id);
    const insightsWithIds = { ...insight, labels: insightsId };
    const response = await usersAPI.post(`/api/admin/insights`, insightsWithIds);
    toast.success(i18n.t('form:createdSuccessfully'));
    return response.data;
  },
);

export const updateInsight = createAsyncThunk<Insight, Insight>(
  'companiesActions/updateInsight',
  async (insight) => {
    const insightsId = insight.labels.map((x) => x.id);
    const insightsWithIds = { ...insight, labels: insightsId };
    const response = await usersAPI.patch(`/api/admin/insights/${insight.id}`, insightsWithIds);
    toast.success(i18n.t('form:createdSuccessfully'));
    return response.data;
  },
);

export const fetchCompanyActivity = createAsyncThunk<CompanyActivity[], number>(
  'companiesActions/fetchCompanyActivity',
  async (id) => {
    const response = await usersAPI.get(`/api/admin/companies/${id}/company_activity`);
    return response.data;
  },
);

export const fetchAllCompaniesActivity = createAsyncThunk<CompanyActivity[]>(
  'companiesActions/fetchAllCompaniesActivity',
  async () => {
    const response = await usersAPI.get(`/api/admin/companies/company_activity/all`);
    return response.data;
  },
);

export const fetchCompany = createAsyncThunk<Company, number>(
  'companiesActions/fetchCompany',
  async (id) => {
    const response = await usersAPI.get(`/api/admin/companies/${id}`);
    return response.data;
  },
);

export const fetchExclusiveCompanies = createAsyncThunk<
  ExclusiveCompanyResponse[],
  ExclusiveCompanyParams
>('companiesActions/fetchPillExclusiveCompanies', async ({ id, type }) => {
  const response = await usersAPI.get(`/api/admin/company_${type}s?${type}=${id}`);
  return response.data;
});

export const updateExclusiveCompanies = createAsyncThunk<
  ExclusiveCompanyResponse[],
  UpdateExclusiveCompaniesParams
>(
  'companiesActions/updateExclusiveCompanies',
  async (
    { id, type, exclusiveCompanies, isExclusive, isAlreadyExclusive },
    { rejectWithValue },
  ) => {
    try {
      if (!isExclusive) return;

      if (!isAlreadyExclusive) await usersAPI.patch(`/api/admin/${type}s/${id}/to_exclusive`);

      const response = await usersAPI.post(`/api/admin/company_${type}s/${type}/${id}`, {
        companies: exclusiveCompanies.map((i) => i.id),
      });

      return response.data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);
