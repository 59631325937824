import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/system';
import lodash from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Badge } from 'components';
import { badgesSelector, IBadge } from 'redux/badges/badgesSlice';
import { BadgeStatus } from 'types';

const useStyles = makeStyles({
  wrapper: {
    gridColumn: '2/ 3',
    gridRow: '3/4',
    maxHeight: '400px',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const BadgesCard: React.FC = () => {
  const { userBadges } = useSelector(badgesSelector);
  const { t } = useTranslation(['badgesType', 'badgesCard']);
  const classes = useStyles();

  const getStatus = (status: string): boolean => status === BadgeStatus.DONE;
  const badgeType = (badge: IBadge) => badge.type;

  const badgeConverter = userBadges.map((badge) => {
    const { status, badgeId, title, type } = badge;

    const newBadge: IBadge = {
      name: null,
      id: badgeId,
      type,
      isActive: getStatus(status),
      title,
    };
    return newBadge;
  });

  const groupedBadges = lodash.groupBy(badgeConverter, badgeType);
  const completedBadges = lodash.mapValues(groupedBadges, (badges) =>
    badges.filter((item) => item.isActive),
  );

  return (
    <Card className={classes.wrapper}>
      <CardHeader title={t('badgesCard:title')} />
      <CardContent>
        {Object.keys(completedBadges).map((category) => {
          return (
            <div key={category}>
              {completedBadges[category].length ? (
                <Typography variant="h3">{t(`${category}`)}</Typography>
              ) : null}
              <Box sx={{ marginBottom: '10px' }} />
              {completedBadges[category].map((el) => (
                <Badge key={el.title} title={el.title} />
              ))}
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};
