import { Card, CardHeader, makeStyles, Theme, Typography, createStyles } from '@material-ui/core';
import { Autocomplete, Box, CardContent, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FONT_SIZE_PRIMARY } from 'theme/typography';

import { Tag } from 'components';
import { fetchInsights, updateCompany } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { Insight } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gridColumn: 'auto /span 12',
      gridRow: '4',
    },
    cardContent: { alignItems: 'center', alignContent: 'center', justifyContent: 'center' },
    content: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    detailsContainerItem: {
      margin: 20,
      minHeight: '200px',
      width: '22%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        width: '45%',
      },
    },
    autoComplete: {
      margin: 10,
      '@media print': {
        display: 'none',
      },
    },
  }),
);

export const CompanyInsights: React.FC = () => {
  const { insights, company, loading } = useSelector(companiesSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['insights']);

  useEffect(() => {
    //TODO:Check if admin then fetch.
    dispatch(fetchInsights({ pagination: false }));
  }, []);

  return (
    <Card className={classes.wrapper}>
      <CardHeader
        title={
          <Typography align="center" variant="h3">
            {t('title')}
          </Typography>
        }
      />
      <CardContent className={classes.cardContent}>
        {/* TODO: Hide AutoComplete when user is not admin */}
        <Autocomplete
          multiple
          className={classes.autoComplete}
          loading={loading.fetchInsights}
          id="company-insights-tags"
          defaultValue={company.insights}
          options={insights.results}
          getOptionLabel={(option) => option.note}
          renderInput={(params) => <TextField {...params} label={t('insights')} />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Tag {...getTagProps({ index })} label={option.note} />)
          }
          renderOption={(props, option) => (
            <li style={{ fontSize: FONT_SIZE_PRIMARY }} {...props}>
              {option.note}
            </li>
          )}
          filterOptions={(options, state) => {
            const newOptions: Insight[] = [];
            options.forEach((element) => {
              element.labels.forEach((el) => {
                if (el.label.toLowerCase().includes(state.inputValue.toLowerCase())) {
                  if (!newOptions.includes(element)) newOptions.push(element);
                }
              });
            });
            return newOptions;
          }}
          onChange={(event, newValue) => {
            const insightsId = newValue.map((x) => x.id);
            dispatch(
              updateCompany({
                id: company.id,
                data: { insights: insightsId as unknown as Insight[] },
              }),
            );
          }}
        />

        <Box className={classes.content}>
          {company.insights.map((insight) => (
            <Card className={classes.detailsContainerItem}>
              <CardContent>
                <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                  {insight.note}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
