import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { DEFAULT_USER_NAME } from '../constants';

interface IProfileBadgeProps {
  onClick?: () => void;
  firstName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '40px',
      height: '40px',
      background: theme.palette.gradients[300],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '2.5rem',
      padding: '2px',
      fontWeight: 'bold',
      color: theme.palette.common.white,
      borderRadius: '3px',
      cursor: 'pointer',
      transition: 'all 0.4s ease',
    },
  }),
);

export const ProfileBadge: React.FC<IProfileBadgeProps> = ({ children, firstName, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <span {...props} className={classes.wrapper}>
        {firstName?.split('')[0] || DEFAULT_USER_NAME}
      </span>
      {children}
    </>
  );
};
