import { Theme } from '@material-ui/core';

export default function ListItem(theme: Theme) {
  return {
    MuiListItem: {
      root: {},
      gutters: {
        paddingTop: '8px ',
        paddingLeft: '40px',
        paddingRight: '20px',
        paddingBottom: '8px',
      },
    },
  };
}
