import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme } from '@material-ui/core';
import lodash from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FocusAreaItem } from './FocusAreaItem';
import { FOCUS_AREAS } from 'constants/mock';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { IUserGoals } from 'types';
import { getFocusArea } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridColumn: 'auto /span 12',
      gridRow: '7',
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    cardContent: {
      flexDirection: 'row',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  }),
);

export const FocusAreaStatistics: React.FC = () => {
  const { usersGoals } = useSelector(goalsSliceSelector);
  const { t } = useTranslation(['usersDashboard', 'focusArea']);
  const classes = useStyles();

  const goalUserID = (usersGoals: IUserGoals) => usersGoals.goal.id;
  const groupedByGoals = lodash.groupBy(usersGoals, goalUserID);

  return (
    <Card className={classes.root}>
      <CardHeader title={t('focusArea')} />
      <CardContent className={classes.cardContent}>
        {FOCUS_AREAS.map((focusArea) => {
          const data = lodash.map(groupedByGoals, (x) =>
            x.filter((y) => y.goal.focusArea === focusArea),
          );
          return (
            <FocusAreaItem
              data={data.filter((x) => x.length > 0)}
              title={getFocusArea(focusArea).label}
            />
          );
        })}
      </CardContent>
    </Card>
  );
};
