import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ReactComponent as LogoImage } from 'assets/icons/logo-image.svg';
import { ReactComponent as LogoText } from 'assets/icons/logo-text.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      gap: '10px',
      position: 'relative',
    },
    icon: {
      fill: theme.palette.common.white,
    },
  }),
);

export const Logo = ({ white }: { white?: boolean }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <LogoImage />
      <LogoText
        className={clsx({
          [classes.icon]: white,
        })}
      />
    </div>
  );
};
