import { createSlice } from '@reduxjs/toolkit';

import { IUserGoals } from './../../types/index';
import { fetchUsersGoal } from './userGoalsActions';
import { RootState } from 'redux/store';

export interface IUserGoalsState {
  userGoals: IUserGoals[];
  isLoading: boolean;
  errorMessage: string;
}

const initialState: IUserGoalsState = {
  userGoals: [],
  isLoading: false,
  errorMessage: '',
};

export const userGoalsSlice = createSlice({
  name: 'userGoals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(fetchUsersGoal.fulfilled, (state, action) => {
        state.userGoals = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUsersGoal.rejected, (state, action: any) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const userGoalsSelector = (state: RootState) => state.userGoals;
export default userGoalsSlice.reducer;
