import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { default as lodash } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { USERS_FOCUS_DATA } from 'constants/mock';
import { FocusArea, IUserGoals } from 'types';
import { getFocusArea } from 'utils/helperFunctions';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gridColumn: 'auto /span 6',
      gridRow: '6',
      display: 'grid',
      width: '100%',
      height: '100%',
    },
    root: {
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('md')]: {
        height: '500px',
      },
      '@media print': {
        display: 'block',
        float: 'left',
        pageBreakInside: 'avoid',
        width: '100%',
      },
    },
    content: {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      display: 'grid',
    },
    dot: {
      borderRadius: '20px',
      backgroundColor: '#F26424',
      height: '20px',
      width: '20px',
      gridColumn: '3',
      gridRow: '2',
      alignSelf: 'center',
      justifySelf: 'center',
      zIndex: 10,
    },
    bar: () => ({
      background: 'linear-gradient(#F26424,#F26424)',
    }),
  }),
);

type Props = {
  groupedByFocusArea: lodash.Dictionary<IUserGoals[]>;
};

export const UsersFocus: React.FC<Props> = ({ groupedByFocusArea }) => {
  const classes = useStyles();
  const { t } = useTranslation(['widgets', 'focusArea']);

  const usersFocusData: [{ name: FocusArea; count: number }] = JSON.parse(
    JSON.stringify(USERS_FOCUS_DATA),
  );

  const dataBeforeMerge = lodash.map(groupedByFocusArea, (x) => {
    return { name: x[0].goal.focusArea, count: x.length };
  });

  usersFocusData.forEach((tab1) => {
    const tab2 = dataBeforeMerge.find((x) => x.name === tab1.name);
    tab1.count = tab2 ? tab2.count : 0;
  });

  const sumChosen = usersFocusData.reduce((n, { count }) => n + count, 0);

  return (
    <Box className={classes.wrapper}>
      <Card className={classes.root}>
        <CardHeader title={t('usersFocus')} />

        <CardContent className={classes.content}>
          <Box
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5,1fr)',
              gridTemplateRows: '30px 50px 30px',
              transform: 'scale(1.5) rotate(-90deg)',
            }}
          >
            <Box className={classes.dot} />
            {usersFocusData.map((value, index) => {
              const focusAreaValue = value.count > 0 ? (value.count / sumChosen) * 100 + 10 : 0;
              const labelsPosition = [
                { gridColumn: '4', gridRow: '2', transform: 'rotate(90deg) translateY(-80%)' },
                {
                  gridColumn: '3',
                  gridRow: '3',
                  transform: 'rotate(90deg) translate(150%) translateY(-250%)',
                },
                {
                  gridColumn: '2',
                  gridRow: '3',
                  transform: 'rotate(90deg) translateX(50%)',
                },
                { gridColumn: '2', gridRow: '1', transform: 'rotate(90deg) translateX(-50%)' },
                {
                  gridColumn: '3',
                  gridRow: '1',
                  transform: 'rotate(90deg) translate(-100%) translateY(-250%)',
                },
              ];
              return (
                <>
                  <BorderLinearProgress
                    classes={{ bar: classes.bar }}
                    style={{
                      height: 10,
                      width: 100,
                      gridColumn: '3',
                      alignSelf: 'center',
                      justifySelf: 'center',
                      gridRow: '2',
                      transform: `rotate(${index * 72}deg) translate(55%)`,
                      zIndex: index,
                    }}
                    variant="determinate"
                    value={focusAreaValue}
                  />
                  <Typography
                    variant="h5"
                    style={{
                      gridColumn: labelsPosition[index].gridColumn,
                      alignSelf: 'center',
                      justifySelf: 'center',
                      gridRow: labelsPosition[index].gridRow,
                      transform: labelsPosition[index].transform,
                    }}
                  >
                    {getFocusArea(value.name).label}
                  </Typography>
                </>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
