import { createStyles, makeStyles, Theme } from '@material-ui/core';
import lodash from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineTrophy } from 'react-icons/ai';
import { BiTime } from 'react-icons/bi';
import { FiDownload, FiTarget, FiUsers } from 'react-icons/fi';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
  AccessDenied,
  ActiveUsersCard,
  DailyCheckIns,
  FullPageLoader,
  RegisteredUsers,
  TotalSummaryWidget,
} from 'components';
import { FocusAreaStatistics } from 'components/Charts/FocusAreaStatistics';
import { ShowError } from 'components/ErrorBoundary';
import { GenderChart, LocationChart } from 'components/UsersDashboard';
import { AgeChart } from 'components/UsersDashboard/AgeChart';
import {
  fetchActiveUsers,
  fetchAverageTime,
  fetchBodyType,
  fetchDownloads,
  fetchTopCities,
} from 'redux/analytics/analyticsActions';
import { analyticsSelector } from 'redux/analytics/analyticsSlice';
import { fetchAllCompaniesActivity } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';
import { fetchGoals } from 'redux/goals/goalsActions';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { fetchSessions } from 'redux/sessions/sessionAction';
import { sessionSliceSelector } from 'redux/sessions/sessionSlice';
import { userAuthSelector } from 'redux/userAuth/userAuthSlice';
import { fetchUserProfiles, fetchUsers } from 'redux/users/usersActions';
import { usersSelector } from 'redux/users/usersSlice';
import { IUserSessions } from 'types';
import { filterUsers } from 'utils';
import { MainWrapper } from 'wrappers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: '0 40px 40px',
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridTemplateRows: 'auto 250px auto',
      maxWidth: '1500px',
      margin: '0 auto',
      gap: '30px',

      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      },
    },
    grid1: {
      gridColumn: 'auto /span 4',
      gridRow: '3/4',
    },
    downloads: {
      gridColumn: 'auto /span 4',
      gridRow: '3',
    },
    uninstalls: {
      gridColumn: 'auto / span 4',
      gridRow: '3',
    },
    daily: {
      gridColumn: 'auto / span 3',
      gridRow: '5',
    },
    monthly: {
      gridColumn: 'auto / span 3',
      gridRow: '5',
    },
    totalSessions: {
      gridColumn: 'auto / span 3',
      gridRow: '5',
    },
    totalGoals: {
      gridColumn: 'auto / span 3',
      gridRow: '5',
    },
    activeUsers: {
      gridColumn: 'auto /span 12',
      gridRow: '6',
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    dailyCheckIns: {
      gridColumn: 'auto/span 12',
      gridRow: '8',
      display: 'grid',
      width: '100%',
      height: '100%',
    },
  }),
);

export const DashboardPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usersGoals, isLoading: loadingUsersGoals } = useSelector(goalsSliceSelector);
  const { sessions, isLoading: loadingSession } = useSelector(sessionSliceSelector);
  const { downloads, error: analyticsError, averageTime } = useSelector(analyticsSelector);
  const { googleToken } = useSelector(userAuthSelector);
  const { users, userProfiles } = useSelector(usersSelector);
  const {
    companyActivity,
    loading: { fetchCompanyActivity },
    error: companiesError,
  } = useSelector(companiesSelector);
  const { t } = useTranslation('widgets');

  const isLoading = loadingUsersGoals || loadingSession || fetchCompanyActivity;
  const error = analyticsError || companiesError;

  const totalUsersNumber = users.results.length;
  const totalGoalsNumber = usersGoals?.filter(
    (x) => x.completedSessions === x.goal.goalLength.length,
  ).length;

  const userID = (session: IUserSessions) => session.user.id;

  const groupedSessions = lodash.groupBy(sessions, userID);
  const filteredSessions = lodash.map(groupedSessions, (x) => x.filter((y) => y.step === 999));
  const sessionsByUserID = filteredSessions.map((x) => Object.keys(x).length);
  const totalCompletedSessions = lodash.sum(sessionsByUserID);
  const dailyActiveUsers = filterUsers(userProfiles, 'activeDaily');
  const monthlyActiveUsers = filterUsers(userProfiles, 'activeMonthly');

  useEffect(() => {
    if (googleToken && !error && !isLoading) {
      batch(() => {
        dispatch(fetchUsers({}));
        dispatch(fetchGoals());
        dispatch(fetchSessions());
        dispatch(fetchDownloads());
        dispatch(fetchAverageTime());
        dispatch(fetchBodyType({ bodyType: 0 }));
        dispatch(fetchBodyType({ bodyType: 1 }));
        dispatch(fetchActiveUsers({ daily: true }));
        dispatch(fetchActiveUsers({ daily: false }));
        dispatch(fetchTopCities());
        dispatch(fetchUserProfiles({}));
        dispatch(fetchAllCompaniesActivity());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, googleToken]);

  if (error) return <ShowError error={error} />;

  if (!googleToken) return <AccessDenied />;

  return (
    <MainWrapper>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.grid1}>
            <TotalSummaryWidget
              placeholder={t('totalUsers')}
              dataLength={totalUsersNumber}
              icon={<FiUsers size={35} />}
            />
          </div>

          <div className={classes.downloads}>
            <TotalSummaryWidget
              placeholder={t('downloads')}
              dataLength={downloads}
              icon={<FiDownload size={35} />}
            />
          </div>

          <div className={classes.uninstalls}>
            <TotalSummaryWidget
              placeholder={t('avgTime')}
              dataLength={averageTime ?? 0}
              icon={<BiTime size={35} />}
            />
          </div>

          <div className={classes.daily}>
            <TotalSummaryWidget
              placeholder={t('dailyUsers')}
              dataLength={dailyActiveUsers ?? 0}
              icon={<FiUsers size={35} />}
            />
          </div>

          <div className={classes.monthly}>
            <TotalSummaryWidget
              placeholder={t('monthlyUsers')}
              dataLength={monthlyActiveUsers ?? 0}
              icon={<FiUsers size={35} />}
            />
          </div>

          <div className={classes.totalSessions}>
            <TotalSummaryWidget
              placeholder={t('totalCompletedSessions')}
              dataLength={totalCompletedSessions}
              icon={<AiOutlineTrophy size={35} />}
            />
          </div>
          <div className={classes.totalGoals}>
            <TotalSummaryWidget
              placeholder={t('totalUsersGoals')}
              dataLength={totalGoalsNumber}
              icon={<FiTarget size={35} />}
            />
          </div>
          <RegisteredUsers />
          <ActiveUsersCard
            wrapperStyle={classes.activeUsers}
            companyActivity={companyActivity}
            height={350}
          />
          <AgeChart />
          <GenderChart />
          <LocationChart />
          <FocusAreaStatistics />
          <DailyCheckIns wrapperStyle={classes.dailyCheckIns} />
        </div>
      )}
    </MainWrapper>
  );
};
