import { Box, Grid, Typography } from '@material-ui/core';
import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ErrorAlert } from './ErrorBoundary';
import { authorizeGoogle } from 'redux/userAuth/userAuthActions';
import { MainWrapper } from 'wrappers';

export const AccessDenied: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('errors');
  const [error, setError] = useState<string>('');

  const onSuccess = (data: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('code' in data) {
      dispatch(
        authorizeGoogle({
          code: data.code,
        }),
      );
      setError('');
    }
  };

  return (
    <MainWrapper>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        {error && <ErrorAlert error={error} />}
        <LockIcon style={{ color: 'grey', fontSize: '64px', margin: '20px' }} />
        <Box width="40%" textAlign="center" style={{ padding: '20px' }}>
          <Typography variant="h2" gutterBottom component="div">
            {t('accessDenied')}
          </Typography>
        </Box>
        <Box textAlign="center" style={{ padding: '20px' }}>
          <Typography variant="body1" gutterBottom>
            {t('logIn')}
          </Typography>
        </Box>
        <Box textAlign="center" style={{ padding: '20px' }}>
          <GoogleLogin
            clientId={process.env.REACT_APP_ANALYTICS_CLIENT_ID as string}
            buttonText="Login"
            onSuccess={onSuccess}
            onFailure={(r) => setError(r.error)}
            cookiePolicy={'single_host_origin'}
            scope="https://www.googleapis.com/auth/analytics.readonly"
            accessType="offline"
            responseType="code"
            prompt="consent"
          />
        </Box>
      </Grid>
    </MainWrapper>
  );
};
