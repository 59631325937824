const translations = {
  common: {
    cancel: 'Cancel',
    ok: 'OK',
    userName: 'Username',
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
    welcomeMessage: 'Welcome to UJJI Admin Panel',
    welcomeSubtitle: 'Log in below to get admin access.',
    registerSuggestion: "You don't have account?",
    registerLink: 'Request access',
    logoutWarning: 'Are you sure want to logout?',
    or: '-OR-',
    signInWith: 'Sign in with',
    forgotPassword: 'Forgot Password?',
    usersList: 'Users List',
    testUser: 'Test User',
  },
  form: {
    email: 'Email',
    password: 'Password',
    emailPlaceholder: 'mail@email.com',
    passwordPlaceholder: '•••••••',
    emailValidationMessage: 'Invalid email adress',
    requiredValidationMessage: 'Required',
    noPasswordProvidedMessage: 'No password provided.',
    passwordTooShort: 'Password is too short - should be {{MIN_PASSWORD_LENGTH}} chars minimum.',
    onlyLetters: ' Password can only contain Latin letters.',
    login: 'Login',
    noPasswordProvided: 'No password provided.',
    updatedSuccessfully: 'Updated successfully',
    createdSuccessfully: 'Created successfully',
  },
  table: {
    id: 'Id',
    email: 'Email',
    firstName: 'First Name',
    dateOfBirth: 'Date of birth',
    company: 'Company',
    status: 'Status',
    active: 'Active',
    block: 'Block',
    blocked: 'Blocked',
    unblock: 'Unblock',
    options: 'Options',
    details: 'Details',
    noDate: 'No date provided',
  },
  profileSection: {
    welcomeMessage: 'Good morning',
  },
  sessionCard: {
    activeSession: 'Active Session',
    currentSessionGoal: 'Current session pathway',
    currentSessionStep: 'Current step',
    sessionsCompleted: 'Total sessions completed',
  },
  navigation: {
    overallDashboard: 'Overall Dashboard',
    users: 'Users',
    admin: 'Admin',
    create: 'Create session',
    overallCompanies: 'Overall Companies Dashboard',
  },
  goalsCard: {
    title: 'Current Pathways',
    numberOfGoals: 'Number of pathways selected by user',
    completedGoals: 'Completed pathways',
    changedGoals: 'Changed pathways',
    allGoals: 'All pathways',
  },
  userOptions: {
    title: 'Options',
    comingSoon: 'Coming soon...',
  },
  mostActiveUsers: {
    title: 'Most active users',
    noUserNameExist: 'User',
  },
  widgets: {
    totalUsers: 'Total users',
    totalCompletedSessions: 'Total sessions completed',
    totalUsersGoals: 'Total pathways achieved',
    downloads: 'Downloads',
    avgTime: 'Average time spent',
    dailyUsers: 'Daily active users',
    monthlyUsers: 'Monthly active users',
    weeklyUsers: 'Weekly active users',
    companySize: 'Company size',
    newUsers: 'New users (month)',
    usersFocus: 'Users focus',
    activeUsers: 'Active users vs last month',
    currentAreaFocus: 'Current Area focus',
    socialChallenges: 'Social challenges',
    memberSince: 'Member since',
    lastActive: 'Last active',
    membershipPurchased: 'Memberships purchased',
    membershipInUse: 'Memberships in use',
  },
  errors: {
    unknownError: 'Unknown error',
    noUsersFound: 'No users found',
    other: 'Other Error',
    noDate: 'No date provided',
    noData: 'No data to display',
    couldntFindId: "Couldn't find specific id.",
    error: 'Error',
    wrongEmailPassword: 'Invalid email or password.',
    somethingWrong: 'Something went wrong.',
    tryAgain: 'Try again',
    accessDenied: 'Access denied',
    logIn: 'You have to log in to get access.',
    invalidAccessCode: 'Access code is already in use. Try another one.',
    invalidCompanyName: 'Company name is already in use. Try another one.',
  },

  vimeo: {
    uploadingError: 'There was an error in uploading the video. Try to upload it again.',
    transcodingError:
      'There was an error in transcoding the video. Make sure to send only mp4 files.',
    unavailable: 'The video is unavailable. Try to upload it again.',
    quotaExceeded: "The user's quota is exceeded with this video. Try again tomorrow.",
    totalCapExceeded: 'The user has exceeded their total cap with this video. Try again tomorrow.',
    transcodeStarting: 'Transcoding is starting for the video. Please wait few minutes.',
    transcoding:
      'Transcoding has started and is currently underway for the video. Please wait few minutes.',
    uploading: 'The video is being uploaded. Please wait few minutes.',
    available: 'The video is available.',
    noVideo: "Couldn't load file, converting new files can take up to 30 minutes.",
  },

  registeredUsers: {
    title: 'Total registered users',
  },

  userProfile: {
    email: 'Email:',
    birthDay: 'Date of Birth:',
    accessCode: 'Access Code',
    createdAt: 'Created At:',
    lastSeen: 'Last Seen:',
  },
  companyProfile: {
    email: 'Contact email',
    companyName: 'Company name',
  },
  trophiesCard: {
    title: 'Total trophies',
  },
  personalityChart: {
    title: 'Personality chart',
  },
  weeklyStreak: {
    weekly: 'Weekly Activity',
    daily: 'Daily Activity',
  },
  badgesCard: {
    title: 'Badges Cards',
  },
  timeSelectOptions: {
    monthAgo: '1 month ago',
    sevenDaysAgo: '7 days ago',
    sinceRelease: 'Since release',
    monthly: 'Monthly',
    daily: 'Daily',
    sinceContracted: 'Since contracted',
  },
  badgesType: {
    DAILY: 'Daily check-ins',
    TASKS: 'Completed tasks',
    WEEKLY: 'Weekly recurrences',
    COMMUNITY: 'Community connections',
    LIFE: 'Real-life achievements',
    MONTHS: 'Monthly challenges',
    NUGGETS: 'Tip collections',
    PROGRESS: 'Game progress',
    SHARED: 'Shared achievements',
  },

  userQualities: {
    selfConfidence: 'Self confidence',
    organisation: 'Organisation',
    empathy: 'Empathy',
    focusOnDetail: 'Focus on detail',
    sociability: 'Sociability',
    openMindedness: 'Open mindedness',
    energy: 'Energy',
    riskAppetite: 'Risk appetite',
  },

  focusArea: {
    COMMUNICATIONS: 'Communications',
    PRODUCTIVITY: 'Productivity',
    PROBLEM_SOLVING: 'Problem-solving',
    ADAPTABILITY: 'Adaptibility',
    LEADERSHIP: 'Leadership',
  },

  pillCategories: { video: 'Video', audio: 'Audio' },
  tips: {
    PRACTICAL_TIP: 'Practical tip',
    GUIDANCE_QUOTE: 'Guidance quote',
    INSPIRATIONAL_STORY: 'Inspirational story',
  },

  admin: {
    goBack: 'Go back',
  },

  createQuestion: {
    createQuestion: 'Create Question',
    editQuestion: 'Edit Question And Answers',
    updateQuestion: 'Update Question',
    answer: 'Answer',
    weight: 'Weight of Answer',
    explanation: 'Explanation',
    numberOfAnswers: 'Number of Answers',
  },

  createGoal: {
    createGoal: 'Create Pathway',
    editGoal: 'Edit Pathway',
    updateGoal: 'Update Pathway',
    focusArea: 'Focus Area',
  },

  createRealLifeTask: {
    createRealLifeTask: 'Create Real-life task',
    editRealLifeTask: 'Edit Real-life task',
    updateRealLifeTask: 'Update Real-life task',
    description: 'Real-life task description',
    goal: 'Pathway',
    externalLink: 'External link',
    grantReward: 'Grant Real-life reward',
    reward: 'Reward',
    title: 'Real-life task title',
    link: 'Link destination',
  },

  createReward: {
    addReward: 'Add Reward',
    editReward: 'Edit Reward',
    updateReward: 'Update Reward',
    rewardName: 'Reward name',
    rewardDescription: 'Reward description',
    link: 'Claim link',
    logo: 'Icon/logo',
    changeLogo: 'Change icon/logo',
    preview: 'Preview',
    status: 'Status',
    scale: 'Scale:',
    rotate: 'Rotate:',
  },

  createNugget: {
    createNugget: 'Create Tip',
    editNugget: 'Edit Tip',
    updateNugget: 'Update Tip',
    description: 'Description',
    type: 'Type',
  },

  createPill: {
    name: 'Name',
    duration: 'Duration (in seconds)',
    memberName: 'Member Name',
    category: 'Category',
    transcription: 'Transcription',
    currentFile: 'Current file',
    changeFile: 'Change file',
    uploadFile: 'Upload a file',
    createPill: 'Create Pill',
    editPill: 'Edit Pill',
    updatePill: 'Update Pill',
    fileRequired: 'File is required',
    logo: 'Icon/logo',
    changeLogo: 'Change icon/logo',
  },

  companies: {
    createCompany: 'Create Company',
    editCompany: 'Edit Company',
    name: 'Name',
    email: 'Email',
    accessCode: 'Access code',
    size: 'Size',
    memberships: 'Memberships',
  },

  pagination: {
    previous: 'previous',
    next: 'next',
    page: 'Page',
    of: 'of',
  },

  createTest: {
    createTest: 'Create Test',
    editTest: 'Edit Test',
    updateTest: 'Update Test',
    scoringType: 'Scoring Type',
    gameTemplate: 'Game Template',
    displayResult: 'Display Result',
    minPoints: 'Min Points',
    maxPoints: 'Max Points',
    howToPlay: 'How To Play',
    mindset: 'Mindset',
    preResults: 'Pre Results',
    userResults: 'User Results',
    info: 'Info',
  },

  createSession: {
    createSession: 'Create Session',
    editSession: 'Edit Session',
    updateSession: 'Update Session',
    isRequired: ' is required',
    goal: 'Pathway',
    test: 'Test',
    pill: 'Pill',
    game: 'Game',
    noGame: 'No game',
    tip: 'Tip',
    dailyTask: 'Daily task',
    textAfterTest: 'Learning of the test',
    textBeforeAudio: 'Text before the pill',
    textAfterAudio: 'Learning of the pill',
    textBeforeTask: 'Text before the daily task',
    WrapUpScreen: 'Wrap-up screen',
  },

  tooltip: {
    average: 'This is the average daily check-ins score among users. Min is 0 and max is 100.',
    usersHadPicked: '% of users had picked this as the cause of their mood',
    logo: 'Icon/logo has to be in png extension and the max size is 500 kb.',
    pillLogo:
      'Icon/logo has to be in png extension and the max size is 500 kb. The image should be over 250px in height and 250px in width.',
    externalLink:
      'Check this if you want to redirect user to supporting information on an external website',
    realLifeReward: 'Check this to attach a reward to this real-life task',
  },

  usersDashboard: {
    ageChart: 'Age of users',
    ageLegend: 'Age in years',
    gender: 'Gender',
    location: 'Location',
    male: 'Male',
    female: 'Female',
    activeUsers: 'Active users',
    focusArea: 'Focus Area Statistics',
  },

  dailyCheckins: {
    cardTitle: 'Daily check-ins',
    avgScore: 'Average score',
    topDrivers: 'Top drivers selected',
    topDriversEmotion: 'Top drivers per emotion',
  },

  validation: {
    specifyNumber: 'You must specify a number',
    mustBeCorrect: 'must be correct',
    answerLengthLess: 'Length of answer must be less than',
    greaterThenOrEqual: 'must be greater than or equal to',
    lessThenOrEqual: 'must be less than or equal to',
    isRequired: ' is a required field',
    invalidUrl: 'URL is not valid. Example of valid URL: https://admin.ujji.app/',
    considerChange: 'Consider changing this field too after you have changed ',
    areYouSure: 'Are you sure you want to proceed without updating',
    invalidEmail: 'You have entered an invalid e-mail address. Please try again.',
    invalidGameTemplate: 'This game template is not valid with the chosen scoring type.',
  },

  tabs: { companies: 'Companies', insights: 'Insights' },
  insights: {
    addInsight: 'Add Insight',
    editInsight: 'Edit Insight',
    insight: 'Insight',
    insights: 'Insights',
    labels: 'Labels',
    title: 'Your Monthly Insights',
    labelRequired: 'Label is required',
    noInsights: 'No insights',
  },

  dragAndDrop: {
    title: 'Drag and drop file here',
    or: 'or',
    browseFile: 'Browse for file',
    loadedSucc: 'Loaded Successfully',
  },

  alert: {
    yes: 'Yes',
    no: 'No',
  },
  trending: {
    trending: 'Trending',
    pills: 'Pills',
    goals: 'Pathways',
    games: 'Games',
    tests: 'Tests',
  },
  exclusiveContent: {
    companies: 'Companies',
    exclusive: 'Exclusive',
    companiesRequired: 'Companies field must contains at least 1 company',
  },
};

export default translations;
