import imageCompression from 'browser-image-compression';
import { format, isAfter, isToday, sub } from 'date-fns';
import { centerCrop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';

import { pillLogoOptions } from 'constants/common';
import { dailyCheckInReason } from 'constants/dailyCheckin';
import { emojis } from 'constants/emoji';
import { vimeoStatusErrors } from 'constants/errors';
import { focusAreasData } from 'constants/focusAreasData';
import {
  UserProfile,
  DailyCheckInReasonIcon,
  DailyCheckInReasonName,
  EmojiCharacter,
  EmojiName,
  FocusArea,
  FocusAreaType,
  GoalStatus,
  ITest,
  IUser,
  IUserGoals,
  TestTypeAPI,
  UsersUtilFilter,
  VideoStatus,
} from 'types';

export const today = new Date();

export const startDate = format(new Date(), 'yyyy-MM-dd');
export const endDate = format(new Date(), 'yyyy-MM-dd');

export const fiveDaysAgo = sub(new Date(), {
  days: 5,
});
export const sevenDaysAgo = sub(new Date(), {
  days: 7,
});
export const tenDaysAgo = sub(new Date(), {
  days: 10,
});
export const monthAgo = sub(new Date(), {
  months: 1,
});
export const twoMonthsAgo = sub(new Date(), {
  months: 2,
});

export const formatTime = (date: Date): string => {
  if (!date) return '';
  return format(new Date(date), 'MM/dd/yyyy');
};

export const formatBirthday = (date: string): string => {
  return format(new Date(date), 'dd-MM-yyyy');
};

export const calculateAverageMood = (avgScore: number): string => {
  switch (true) {
    case avgScore <= 20:
      return 'Awful ' + getEmoji('Awful');
    case avgScore > 20 && avgScore <= 40:
      return 'Bad ' + getEmoji('Bad');
    case avgScore > 40 && avgScore <= 60:
      return 'Ok ' + getEmoji('Ok');
    case avgScore > 60 && avgScore <= 80:
      return 'Good ' + getEmoji('Good');
    case avgScore > 80:
      return 'Amazing ' + getEmoji('Amazing');
    default:
      return '';
  }
};

export const dateRange = (
  startDate: Date | string,
  endDate: string | Date,
  steps = 1,
): string[] => {
  const dateArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(new Date(currentDate));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray.map((date) => formatTime(date)) as string[];
};

export const sortGoalsByStatus = (data: IUserGoals[]): IUserGoals[] => {
  const sortOrder: GoalStatus[] = ['ACTIVE', 'INACTIVE', 'DONE'];
  return data.sort((a, b) => {
    return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status);
  });
};

export const getScoringType = (data: ITest | undefined): TestTypeAPI => {
  if (data?.testType === 'MEAN') {
    return 'mean';
  } else {
    return 'sumavg';
  }
};

export const getFocusArea = (focusArea: FocusArea): FocusAreaType => {
  return focusAreasData[focusArea];
};

export const getVimeoStatus = (status: VideoStatus): string => {
  return vimeoStatusErrors[status].message;
};

export const getEmoji = (name: EmojiName): EmojiCharacter => {
  return emojis.find((e) => e.name === name)?.character as EmojiCharacter;
};

export const getDailyCheckInReason = (reason: DailyCheckInReasonName): DailyCheckInReasonIcon => {
  return dailyCheckInReason.find((e) => e.name === reason)?.icon as DailyCheckInReasonIcon;
};

export const secondsToHms = (totalSeconds: number): string => {
  if (totalSeconds === 0) return '-';
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  return [`${hours}h`, `${minutes}m`, `${seconds}s`].filter((item) => item[0] !== '0').join(' ');
};

export const firstLetterUpper = (string: string): string => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const calculateAge = (date: string): number => {
  const birthDate = new Date(date);
  const difference = Date.now() - birthDate.getTime();
  const age = new Date(difference);

  return Math.abs(age.getUTCFullYear() - 1970);
};

export const countDuplicates = (ageArray: number[]): { [key: string]: number } => {
  const counts: { [key: string]: number } = {};
  ageArray
    .filter((x) => x > 0)
    .forEach((x) => {
      counts[x] = (counts[x] || 0) + 1;
    });
  return counts;
};

export const convertAgesData = (users: IUser[]): number[] => {
  const iterate = (min: number, max: number, tab: { [key: string]: number }) => {
    let number = 0;
    for (let i = min; i <= max; i++) {
      if (tab[i] !== undefined) number = number + tab[i];
    }
    return number;
  };

  const ageArray = users.map((el) => {
    return calculateAge(el.birthday);
  });

  const ages = countDuplicates(ageArray);

  const data = [
    iterate(18, 24, ages),
    iterate(25, 34, ages),
    iterate(35, 44, ages),
    iterate(45, 54, ages),
    iterate(55, 99, ages),
  ];

  return data;
};

export const filterUsers = (data: (IUser | UserProfile)[], filter: UsersUtilFilter): number => {
  const getUserLoginDate = (user: IUser | UserProfile) =>
    new Date(user.loginTimestamp).setHours(0, 0, 0, 0);
  const getUserRegisterDate = (createdAt: string) => new Date(createdAt).setHours(0, 0, 0, 0);

  const firstDayPrevMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    1,
  ).setHours(0, 0, 0, 0);
  const lastDayPrevMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(
    0,
    0,
    0,
    0,
  );

  switch (filter) {
    case 'activeDaily': {
      return data.filter((user) => isToday(getUserLoginDate(user))).length;
    }
    case 'activeWeekly': {
      return data.filter((user) =>
        isAfter(getUserLoginDate(user), sevenDaysAgo.setHours(0, 0, 0, 0)),
      ).length;
    }
    case 'activeMonthly': {
      return data.filter((user) => isAfter(getUserLoginDate(user), lastDayPrevMonth)).length;
    }
    case 'newMonthly': {
      return data.filter((user) =>
        isAfter(getUserRegisterDate('createdAt' in user ? user.createdAt : ''), lastDayPrevMonth),
      ).length;
    }
    case 'activeLastMonth': {
      return data.filter(
        (user) =>
          getUserLoginDate(user) >= firstDayPrevMonth && getUserLoginDate(user) <= lastDayPrevMonth,
      ).length;
    }
    default:
      return 0;
  }
};

export const addSignToPositive = (number: number): number | string => {
  if (number > 0) return '+' + number;
  return number;
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
  } catch (e) {
    return false;
  }
};

export const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  width: number,
  height?: number,
): PixelCrop | PercentCrop => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width,
        height,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

export const removeImgExtension = (base64Image: string): string =>
  base64Image
    .replace('data:image/jpeg;base64,', '')
    .replace('data:image/jpg;base64,', '')
    .replace('data:image/png;base64,', '');

export const compressPhoto = async (canvas: HTMLCanvasElement): Promise<string> => {
  const file = await imageCompression.canvasToFile(canvas, 'image/jpeg', 'logo', Date.now(), 0.8);
  const compressedFile = await imageCompression(file, pillLogoOptions);
  const base64Image = await imageCompression.getDataUrlFromFile(compressedFile);
  return base64Image;
};
