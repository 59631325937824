import React, { useContext, useState } from 'react';
import { boolean } from 'yup';

export interface SidebarContextData {
  handleToggleSidebar: () => void;
  isSidebarOpen: boolean;
}

const SidebarContext = React.createContext<SidebarContextData>({
  isSidebarOpen: true,
  handleToggleSidebar: () => boolean,
});

export const SidebarProvider: React.FC = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <SidebarContext.Provider value={{ handleToggleSidebar, isSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const sidebar = useContext(SidebarContext);

  if (!sidebar) {
    throw Error('useSidebar needs to be used inside SidebarContext');
  }

  return sidebar;
};
