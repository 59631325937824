import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { CellProps } from 'react-table';

import { IDailyTask } from 'types';

export const DAILY_TASKS_COLUMN = [
  {
    Header: 'Id',
    accessor: 'id',
    width: '10%',
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: '20%',
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: '35%',
    Cell: ({ row }: CellProps<IDailyTask>): string =>
      _.truncate(row.original.description, { length: 100 }),
  },
  {
    Header: 'Pathway',
    accessor: 'goal.name',
    width: '15%',
  },
  {
    Header: 'Real-life task',
    accessor: 'externalReward.name',
    width: '10%',
    Cell: ({ row }: CellProps<IDailyTask>): string =>
      row.original.externalReward ? row.original.externalReward.name : '-',
  },
  {
    Header: 'Options',
    accessor: 'test',
    width: '10%',
    Cell: (props: CellProps<IDailyTask>): JSX.Element => {
      const { id } = props.row.original;

      return (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          style={{ width: '40px', height: '40px', borderRadius: 50, minWidth: '40px' }}
          onClick={() => props.onEdit(id)}
        >
          <EditIcon fontSize="large" />
        </Button>
      );
    },
  },
];
