import { Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  description: string;
  onSubmit: () => void;
  onClose: () => void;
  showModal: boolean;
};

export const AlertDialog: React.FC<Props> = ({
  title,
  description,
  onSubmit,
  showModal,
  onClose,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation(['alert']);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  React.useEffect(() => {
    showModal ? setOpen(true) : setOpen(false);
  }, [showModal]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="subtitle1">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1">{description}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Typography variant="subtitle2">{t('no')} </Typography>
          </Button>
          <Button onClick={onSubmit} autoFocus>
            <Typography variant="subtitle2">{t('yes')} </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
