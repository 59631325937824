import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import analyticsReducer from './analytics/analyticsSlice';
import badgesReducer from './badges/badgesSlice';
import companiesReducer from './companies/companiesSlice';
import createSessionReducer from './createSession/createSessionSlice';
import dailyTracking from './dailyTracking/dailyTrackingSlice';
import filtersReducer from './filters/filtersSlice';
import goalsReducer from './goals/goalsSlice';
import sessionReducer from './sessions/sessionSlice';
import trendingReducer from './trending/trendingSlice';
import userAuthReducer from './userAuth/userAuthSlice';
import usersReducer from './users/usersSlice';
import usersSessionsReducer from './userSessions/UserSessionsSlice';
import usersGoalsReducer from './usersGoals/userGoalsSlice';

export const reducers = combineReducers({
  users: usersReducer,
  userAuth: userAuthReducer,
  userGoals: usersGoalsReducer,
  userSessions: usersSessionsReducer,
  goals: goalsReducer,
  sessions: sessionReducer,
  dailyTracking: dailyTracking,
  badges: badgesReducer,
  createSession: createSessionReducer,
  analytics: analyticsReducer,
  filters: filtersReducer,
  companies: companiesReducer,
  trending: trendingReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userAuth', 'userSessions'],
};

export const persistedReducer = persistReducer(persistConfig, reducers);

export const store = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      }),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof reducers>;
export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore['dispatch'];
