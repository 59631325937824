import {
  Box,
  Card,
  CardHeader,
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { format } from 'date-fns';
import { merge } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BaseOptionsChart } from 'components';
import { UJJI_RELEASE_DATE } from 'constants/analytics';
import { timeSelectOptions } from 'constants/timeSelectOptions';
import { usersSelector } from 'redux/users/usersSlice';
import { IChartData, IChartValueState } from 'types';
import { dateRange, endDate, formatTime, monthAgo, sevenDaysAgo } from 'utils/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridColumn: 'auto /span 12',
      gridRow: '4 / 4',
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    // TODO: Remove body style class
    body: {
      padding: '20px',
    },
    flex: {
      padding: '24px 24px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    select: {
      height: '30px',
    },
  }),
);

export const RegisteredUsers: React.FC = () => {
  const { users } = useSelector(usersSelector);
  const { t } = useTranslation('registeredUsers');
  const classes = useStyles();
  const [chartValue, setChartValue] = useState<IChartValueState>('monthAgo');
  const [rangeOfDays, setRangeOfDays] = useState<string[]>(dateRange(monthAgo, endDate));

  useEffect(() => {
    const currentDate = new Date();
    switch (chartValue) {
      case 'monthAgo':
        setRangeOfDays(dateRange(monthAgo, currentDate));
        break;
      case 'sevenDaysAgo':
        setRangeOfDays(dateRange(sevenDaysAgo, currentDate));
        break;
      case 'sinceRelease':
        setRangeOfDays(dateRange(UJJI_RELEASE_DATE, currentDate));
        break;
      default:
        throw new Error('You need to choose correct date range');
    }
  }, [chartValue]);

  const usersRegisteredPerDay = useMemo(
    () =>
      rangeOfDays.map((date) => {
        return {
          x: format(new Date(date), 'yyyy-MM-dd'),
          y:
            users.results.filter((user) => formatTime(new Date(user.createdAt)) === date).length ??
            0,
        };
      }),
    [rangeOfDays, users],
  );

  const CHART_DATA: IChartData[] = [
    {
      name: 'Registered users',
      data: usersRegisteredPerDay,
    },
  ];

  const chartOptions = merge(BaseOptionsChart(), {
    xaxis: {
      type: 'datetime',
    },
  });

  return (
    <Card className={classes.root}>
      <CardHeader
        title={t('title')}
        action={
          <Select
            value={chartValue}
            onChange={({ target }) => setChartValue(target.value as IChartValueState)}
            name="dates"
            id="dates"
            className={classes.select}
            MenuProps={{ disableScrollLock: true }}
          >
            {timeSelectOptions.map((option) => (
              <MenuItem key={option.text} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Chart type="line" height={350} options={chartOptions} series={CHART_DATA} />
      </Box>
    </Card>
  );
};
