import {
  Button,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useSidebar } from 'context/SidebarContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';
import { FONT_MEDIUM } from 'theme/typography';

import { HamburgerMenu } from 'components/HamburgerMenu';
import { Logo } from 'components/Logo';
import { navigation } from 'constants/navigation';
import { clearState } from 'redux/userAuth/userAuthSlice';

type StyleProps = {
  isSidebarOpen: boolean;
  isShrunk: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    wrapper: {
      position: 'sticky',
      top: 0,
      height: ({ isShrunk }) => (isShrunk ? '60px' : ' 120px'),
      width: '100%',
      padding: '5px 30px',
      backdropFilter: 'blur(2px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'hsla(360, 100%, 100%, 0.90)',
      maxWidth: '1500px',
      margin: '0 auto 40px auto',
      zIndex: 100,
      transition: 'all 0.4s',
    },
    logoWrapper: {
      width: '15%',
    },
    menuWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: 24,
      justifyContent: 'center',
      transition: 'all 0.4s',
      height: '100px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'fixed',
        left: 0,
        top: 0,
        height: 'auto',
        opacity: ({ isSidebarOpen }) => (isSidebarOpen ? '1' : '0'),
        transform: ({ isSidebarOpen, isShrunk }) =>
          isSidebarOpen
            ? isShrunk
              ? 'translateY(40px)'
              : 'translateY(100px)'
            : 'translateY(-100px)',
        backgroundColor: 'white',
        PointerEvent: ({ isSidebarOpen }) => (isSidebarOpen ? 'auto' : 'none'),
        margin: '14px 0',
        padding: ' 0 33px',
        width: '100%',
        textAlign: 'center',
        pointerEvents: ({ isSidebarOpen }) => (isSidebarOpen ? 'initial' : 'none'),
      },
    },
    navWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    navItem: {
      textDecoration: 'none',
      color: 'black',
      fontSize: '1.6rem',
      fontFamily: FONT_MEDIUM,
      margin: 24,
      [theme.breakpoints.down('sm')]: {
        margin: '30px',
      },
    },
    active: {
      background: theme.palette.gradients[400],
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: -5,
        left: 0,
        width: '100%',
        height: 2,
        background: theme.palette.gradients[400],
      },
    },
    button: {
      backgroundColor: 'transparent',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      color: theme.palette.error.main,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        margin: '30px',
      },
    },
    logoutIcon: {
      marginRight: '10px',
      fontSize: '1.6rem',
    },
    logoutWrapper: {
      flex: 1,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 24,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
  }),
);

export const Navbar: React.FC = () => {
  const { isSidebarOpen, handleToggleSidebar } = useSidebar();
  const [isShrunk, setShrunk] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDropDown = Boolean(anchorEl);

  const dispatch = useDispatch();
  const classes = useStyles({ isSidebarOpen, isShrunk });
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('common');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120)
        ) {
          return true;
        }

        if (isShrunk && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    storage.removeItem('persist:root');
    dispatch(clearState());
    history.push('/login');
    history.go(0);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <Logo />
      </div>
      <div className={classes.menuWrapper}>
        <nav className={classes.navWrapper}>
          {navigation.map((navEl) => {
            const { name, path, activeLinks, exact, isDropDown, links } = navEl;
            return (
              <>
                {!isDropDown ? (
                  <NavLink
                    key={name}
                    exact
                    className={classes.navItem}
                    activeClassName={classes.active}
                    to={path}
                    isActive={() =>
                      exact ? activeLinks.includes(pathname) : pathname.search(activeLinks) > 0
                    }
                  >
                    {name}
                  </NavLink>
                ) : (
                  <div>
                    <Button
                      variant="text"
                      aria-controls={openDropDown ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openDropDown ? 'true' : undefined}
                      onClick={handleClick}
                      style={{ minWidth: 0, textTransform: 'none' }}
                    >
                      {name}
                      {openDropDown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openDropDown}
                      onClose={handleClose}
                      style={{ marginTop: 62 }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {links?.map((link) => {
                        const { name, path, exact, activeLinks } = link;
                        return (
                          <MenuItem onClick={handleClose}>
                            <NavLink
                              key={name}
                              exact
                              className={classes.navItem}
                              activeClassName={classes.active}
                              to={path}
                              isActive={() =>
                                exact
                                  ? activeLinks.includes(pathname)
                                  : pathname.search(activeLinks) > 0
                              }
                            >
                              {name}
                            </NavLink>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </div>
                )}
              </>
            );
          })}
        </nav>
        <div className={classes.logoutWrapper}>
          <button onClick={handleLogout} className={classes.button}>
            <FiLogIn className={classes.logoutIcon} />
            <Typography variant="body1">{t('logout')}</Typography>
          </button>
        </div>
      </div>
      <HamburgerMenu isSidebarOpen={isSidebarOpen} handleToggleSidebar={handleToggleSidebar} />
    </div>
  );
};
