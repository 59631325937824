import {
  Box,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gridColumn: 'auto / span 9',
      gridRow: '1',
      height: '50%',
    },
    styledCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      },
    },
    styledCardItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

type Props = {
  data: { label: string; value: string | number | JSX.Element }[];
};

export const TopCategories: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation(['sessionCard', 'errors', 'focusArea']);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card>
        <CardContent className={classes.styledCard}>
          {!data.length ? (
            <Typography variant="subtitle1">{t('errors:noData')}</Typography>
          ) : (
            <>
              {data.map((x) => {
                return (
                  <Box className={classes.styledCardItem}>
                    <Typography variant="h1">{x.value}</Typography>
                    <Typography variant="subtitle1">{x.label}</Typography>
                  </Box>
                );
              })}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
