import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileBadge } from './ProfileBadge';

interface IDropDownProps {
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '380px',
      height: 'auto',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      borderRadius: '5px',
      right: '15px',
      top: '80px',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: theme.shadows[24],
    },
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem',
    },
    footer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem',
    },

    navContainer: {
      flex: 1,
      height: '100%',
    },
    logoutButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    line: {
      width: '100%',
      border: `0.5px solid ${theme.palette.grey[100]}`,
    },
    content: {
      padding: '1rem',
    },
  }),
);
export const DropDownMenu: React.FC<IDropDownProps> = ({ ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation(['profileSection', 'common']);
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <ProfileBadge />
        <Typography variant="subtitle1">{t('welcomeMessage')}</Typography>
      </div>
      <nav className={classes.navContainer}>
        <div className={classes.content}>{/* //TODO: add more Dropdown settings */}</div>
      </nav>
      <hr className={classes.line} />
      <div className={classes.footer}>
        <Button {...props} className={classes.logoutButton} variant="contained" size="small">
          {t('logout')}
        </Button>
      </div>
    </div>
  );
};
