import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme } from '@material-ui/core';
import Chart from 'react-apexcharts';

import { pieBackgroundColor } from 'constants/charts';

type Props = { title: string; data: number[]; categories: string[]; colors?: string[] };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: theme.shadows[24],
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '@media print': {
        alignItems: 'center',
      },
    },
  }),
);

export const BarChart: React.FC<Props> = ({ title, data, categories, colors }) => {
  const classes = useStyles();

  const config = {
    options: {
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories,
        labels: {
          hideOverlappingLabels: false,
          rotate: 0,
          trim: true,
        },
      },
      legend: { show: false },
      colors: colors ? colors : pieBackgroundColor,
      plotOptions: {
        bar: { distributed: true },
      },
    },
    series: [
      {
        name: 'selected',
        data,
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: string) {
          return value + ' times';
        },
      },
    },
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={title} />
      <CardContent>
        <Chart options={config.options} series={config.series} type="bar" />
      </CardContent>
    </Card>
  );
};
