import {
  Box,
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ExclusiveContentChip } from './ExclusiveContentChip';
import { CustomSwitch } from 'components/CustomSwitch';
import { ExclusiveCompany } from 'types';

interface Props {
  companies: ExclusiveCompany[];
  alreadyChosenCompanies: ExclusiveCompany[];
  isAlreadyExclusive: boolean;
  goalId?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    autocomplete: {
      marginTop: '16px',
    },
    autocompleteOption: {
      fontSize: '1.4rem',
    },
  }),
);

export const ExclusiveContent: React.FC<Props> = ({
  companies,
  alreadyChosenCompanies,
  isAlreadyExclusive,
  goalId,
}) => {
  const [selectedCompanies, setSelectedCompanies] =
    useState<ExclusiveCompany[]>(alreadyChosenCompanies);

  const {
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<{
    exclusive: boolean;
    exclusiveCompanies: ExclusiveCompany[];
  }>();
  const { t } = useTranslation('exclusiveContent');
  const classes = useStyles();
  const formValue = watch();

  const handleToggleSwitch = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isAlreadyExclusive) return;
    setValue('exclusive', checked);
    if (!checked) setSelectedCompanies([]);
  };

  const handleChange = async (
    _: React.SyntheticEvent<Element, Event>,
    newValue: ExclusiveCompany[],
    reason: AutocompleteChangeReason,
  ) => {
    switch (reason) {
      case 'selectOption':
        setSelectedCompanies(newValue);
        clearErrors('exclusiveCompanies');
        break;
      case 'clear':
        setSelectedCompanies((prev) =>
          prev.filter((i) => alreadyChosenCompanies.find((x) => x.id === i.id)),
        );
        break;
    }
  };

  const handleDelete = (id: number) => {
    setSelectedCompanies((prev) => prev.filter((x) => x.id !== id));
    setValue('exclusive', true);
  };

  useEffect(() => {
    setValue('exclusiveCompanies', selectedCompanies);
  }, [selectedCompanies, setValue]);

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="goal-select-label">{t('exclusive')}</InputLabel>
        <Box style={{ flex: 1, width: '100%', marginTop: 16 }}>
          <CustomSwitch
            checked={formValue.exclusive}
            inputRef={register('exclusive').ref}
            onChange={handleToggleSwitch}
          />
        </Box>
      </FormControl>

      {formValue.exclusive && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="test-select-label">{t('companies')}</InputLabel>
          <Autocomplete
            multiple
            options={companies}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="" placeholder="" />}
            renderTags={(values) =>
              values.map((value) => (
                <ExclusiveContentChip
                  id={value.id}
                  name={value.name}
                  isDisabled={!!alreadyChosenCompanies.find((i) => i.id === value.id)}
                  handleDelete={() => handleDelete(value.id)}
                  goalId={goalId}
                />
              ))
            }
            value={selectedCompanies}
            onChange={handleChange}
            classes={{
              root: classes.autocomplete,
              option: classes.autocompleteOption,
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <FormHelperText error>
            {errors.exclusiveCompanies && t('companiesRequired')}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};
