import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSidebar } from 'context/SidebarContext';
import { FiMenu } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

import { navigation } from 'constants/navigation';
import { SIDEBAR_WIDTH } from 'constants/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebar: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: `${SIDEBAR_WIDTH}px`,
      backgroundColor: theme.palette.primary.main,
      height: '100vh',
      padding: '1.5rem 2rem 2rem 2rem',
      zIndex: 100,
      transition: 'all 0.4s ease',
      [theme.breakpoints.down('sm')]: {
        left: '-90%',
      },
    },
    nav: {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    logoWrapper: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      marginBottom: '3rem',
      padding: '1rem',
      gap: '25px',
      width: '100%',
    },
    navToggle: {
      fontSize: '3rem',
      marginRight: '10px',
      color: theme.palette.common.white,
    },

    navList: {
      listStyle: 'none',
    },
    navListItem: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      alignItems: 'center',
      marginBottom: '1rem',
      borderRadius: '0.5rem',
      gap: '20px',
      transition: 'all 0.4s ease',
      padding: '1rem',
      color: theme.palette.common.white,
      cursor: 'pointer',
      textDecoration: 'none',

      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
    },
    navLinkItemIcon: {
      fontSize: '3rem',
    },
    navLinkItemName: {},
    expander: {
      width: `calc(${SIDEBAR_WIDTH}px + 145px)`,
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
    },
    active: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  }),
);

export const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { isSidebarOpen, handleToggleSidebar } = useSidebar();

  return (
    <div
      className={clsx(classes.sidebar, {
        [classes.expander]: isSidebarOpen,
      })}
    >
      <nav className={classes.nav}>
        <div>
          <div className={classes.logoWrapper}>
            <FiMenu className={classes.navToggle} onClick={handleToggleSidebar} />
          </div>
          <ul className={classes.navList}>
            {navigation.map((navEl) => {
              const { name, path } = navEl;
              return (
                <li key={path}>
                  <NavLink
                    exact
                    className={classes.navListItem}
                    to={path || ''}
                    activeClassName={classes.active}
                  >
                    <span className={classes.navLinkItemName}>{name}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};
