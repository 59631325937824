import Menu, { MenuProps } from '@material-ui/core/Menu';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { HiDotsVertical } from 'react-icons/hi';

const useStyles = makeStyles({
  button: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
});

const StyledMenu = withStyles({})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const PopUpMenu: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        className={classes.button}
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <HiDotsVertical size={16} />
      </button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
      >
        {children}
      </StyledMenu>
    </div>
  );
};
