import { Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

import { CARD_MIN_WIDTH } from 'constants/styles';

interface IMainCardProps {
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: CARD_MIN_WIDTH,
      padding: '40px',
      boxShadow: theme.shadows[24],
      borderRadius: '16px',
    },
  }),
);

export const MainCard: React.FC<IMainCardProps> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Typography variant="body2">{title}</Typography>
      {children}
    </Card>
  );
};
