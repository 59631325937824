import { CardContent, CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomLoader } from 'components';
import { PieChart } from 'components/Charts/PieChart';
import { analyticsSelector } from 'redux/analytics/analyticsSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridColumn: 'auto / span 4',
      gridRow: '1/ 3',
      maxWidth: 460,
      maxHeight: 400,
      boxShadow: theme.shadows[24],
      padding: '1rem',
      borderRadius: '10px',
    },
    cardHeader: {
      padding: '0 2rem',
    },
    cardItem: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export const GenderChart: React.FC = () => {
  const classes = useStyles();
  const { genders, loading } = useSelector(analyticsSelector);
  const { t } = useTranslation(['usersDashboard', 'errors']);

  const data = [genders.female, genders.male];

  return (
    <Card className={classes.root}>
      <CardHeader title={t('gender')} />
      <CardContent style={{ padding: 0 }}>
        {loading.fetchBodyType ? (
          <CustomLoader />
        ) : (
          <PieChart
            chartData={data}
            labels={[t('female'), t('male')]}
            percentage
            legendTitle={t('gender')}
          />
        )}
      </CardContent>
    </Card>
  );
};
