import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileBadge } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardItem: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export const MostActiveUsersWidgetItem = ({ date, firstName }: any) => {
  const classes = useStyles();
  const { t } = useTranslation('mostActiveUsers');
  const formattedDate = format(new Date(date), 'MM/dd/yyyy');

  return (
    <div className={classes.cardItem}>
      <ProfileBadge firstName={firstName} />
      <Typography variant="body1">{firstName || t('noUserNameExist')}</Typography>
      <p>{formattedDate}</p>
    </div>
  );
};
