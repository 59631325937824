import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CustomTablePagination } from 'components';
import { INSIGHTS_COLUMNS } from 'constants/columns/insightsColumns';
import { fetchInsights } from 'redux/companies/companiesActions';
import { companiesSelector } from 'redux/companies/companiesSlice';

export const InsightsPage: React.FC = () => {
  const columns = React.useMemo(() => INSIGHTS_COLUMNS, []);
  const { insights, loading } = useSelector(companiesSelector);
  const { t } = useTranslation('insights');
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleButton = () => {
    history.push(`${path}/create`);
  };

  const onEdit = async (id: string) => {
    history.push(`${path}/${id}`);
  };

  const buttonProps = {
    title: t('addInsight'),
    onCreate: handleButton,
  };

  return (
    <CustomTablePagination
      columns={columns}
      data={insights}
      onEdit={onEdit}
      isLoading={loading.fetchInsights}
      createButtonProps={buttonProps}
      fetchData={fetchInsights}
    />
  );
};
