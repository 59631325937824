import i18n from 'translation/i18n';

import { FocusArea } from 'types';

interface FocusAreaType {
  label: string;
  icon: string;
  contentColor: string;
  backgroundColor: string;
}

export const focusAreasData: Record<FocusArea, FocusAreaType> = {
  COMMUNICATIONS: {
    label: i18n.t('focusArea:COMMUNICATIONS'),
    icon: '',
    contentColor: '#9B32D6',
    backgroundColor: '#7F23B2',
  },
  PRODUCTIVITY: {
    label: i18n.t('focusArea:PRODUCTIVITY'),
    icon: '',
    contentColor: '#44C1F9',
    backgroundColor: '#3AA6D5',
  },
  PROBLEM_SOLVING: {
    label: i18n.t('focusArea:PROBLEM_SOLVING'),
    icon: '',
    contentColor: '#48BB50',
    backgroundColor: '#3FA746',
  },
  ADAPTABILITY: {
    label: i18n.t('focusArea:ADAPTABILITY'),
    icon: '',
    contentColor: '#F26424',
    backgroundColor: '#C9450A',
  },
  LEADERSHIP: {
    label: i18n.t('focusArea:LEADERSHIP'),
    icon: '',
    contentColor: '#6064F2',
    backgroundColor: '#4246BF',
  },
};
