import { Theme } from '@material-ui/core';

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      root: {
        boxShadow: theme.shadows[24],
        borderRadius: '10px',
      },
    },
    MuiCardHeader: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
    MuiCardContent: {
      root: {
        padding: theme.spacing(3),
      },
    },
  };
}
