import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import i18n from 'translation/i18n';

import { COLUMNS } from '../constants';
import { Checkbox } from './Checkbox';
import { TableFilter } from './TableFilter';
import { toggleUserStatus } from 'redux/users/usersActions';
import { usersSelector } from 'redux/users/usersSlice';
import { IUser } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableBody: {
      position: 'relative',
    },
    paper: {
      maxWidth: 1400,
      boxShadow: theme.shadows[24],
    },
    wrapper: {
      padding: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filterWrapper: {
      padding: '10px 30px',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    positionAbsolute: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);

export const UsersTable: React.FC = () => {
  const { users, isLoading } = useSelector(usersSelector);
  const dispatch = useDispatch();
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => users, [users]);
  const classes = useStyles();
  const history = useHistory();
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: data.results,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ];
      });
    },
  );

  const handlePatientDetails = async (id: string) => {
    history.push(`/users/${id}`);
  };

  const handleTogglePatientStatus = (user: IUser) => {
    dispatch(toggleUserStatus(user));
  };

  const { globalFilter } = state;

  let tableContent;

  if (isLoading) {
    tableContent = (
      <TableRow>
        <TableCell>
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else {
    tableContent = page.map((row) => {
      prepareRow(row);
      return (
        <TableRow {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <TableCell {...cell.getCellProps()}>
              {cell.render('Cell', {
                handlePatientDetails,
                handleTogglePatientStatus,
                isLoading: true,
              })}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }
  return (
    <div className={classes.wrapper}>
      <TableContainer className={classes.paper} component={Paper}>
        <div className={classes.filterWrapper}>
          <TableFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <Table className={classes.table} aria-label="simple table" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>{column.isSorted && (column.isSortedDesc ? '<' : '>')}</span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody
            className={`${classes.tableBody} ${users.isLoading && classes.disabled}`}
            {...getTableBodyProps()}
          >
            <>
              {users.isLoading && (
                <div className={`${classes.loader} ${classes.positionAbsolute}`}>
                  <CircularProgress />
                </div>
              )}
              {tableContent}
            </>
          </TableBody>
        </Table>
        <Button color="secondary" disabled={!canPreviousPage} onClick={previousPage}>
          {i18n.t('pagination:previous')}
        </Button>
        <Button color="secondary" disabled={!canNextPage} onClick={nextPage}>
          {i18n.t('pagination:next')}
        </Button>
      </TableContainer>
    </div>
  );
};
