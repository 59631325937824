import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

interface IPrivateRouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path: string | string[];
  exact?: boolean;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({ component, path, exact }) => {
  return localStorage.getItem('token') ? (
    <Route exact={exact} component={component} path={path} />
  ) : (
    <Redirect to="/login" />
  );
};
