import lodash from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CustomTablePagination } from 'components';
import { QUESTIONS_COLUMN } from 'constants/columns/questionsColumns';
import { fetchTestQuestions, fetchTests } from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { AppDispatch } from 'redux/store';
import { PaginationOptions } from 'types';
import { getScoringType } from 'utils/helperFunctions';

export const QuestionsPage: React.FC = () => {
  const columns = React.useMemo(() => QUESTIONS_COLUMN, []);
  const { questions, loading } = useSelector(createSessionSelector);

  const { testId } = useParams<{ testId: string }>();
  const { t } = useTranslation('createQuestion');
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const fetchQuestions = async ({ page, search }: PaginationOptions) => {
    const data = await dispatch(fetchTests({ id: parseInt(testId) })).unwrap();
    const selectedTest = data.results.filter((x) => x.id === parseInt(testId));
    const type = getScoringType(selectedTest[0]);
    !lodash.isEmpty(selectedTest) &&
      dispatch(fetchTestQuestions({ type, id: parseInt(testId), page, search }));
  };

  const handleButton = () => {
    history.push({ pathname: `${testId}/create` });
  };

  const onEdit = async (id: string) => {
    history.push({ pathname: `${testId}/${id}` });
  };

  const buttonProps = {
    title: t('createQuestion'),
    onCreate: handleButton,
  };

  return (
    <div>
      <CustomTablePagination
        columns={columns}
        data={questions}
        onEdit={onEdit}
        isLoading={loading}
        createButtonProps={buttonProps}
        fetchQuestions={fetchQuestions}
      />
    </div>
  );
};
