import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CustomTablePagination } from 'components';
import { REWARDS_COLUMN } from 'constants/columns/rewardsColumns';
import { fetchExternalRewards } from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';

export const RewardsPage: React.FC = () => {
  const columns = React.useMemo(() => REWARDS_COLUMN, []);
  const { externalRewards, loading } = useSelector(createSessionSelector);
  const { t } = useTranslation('createReward');
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleButton = () => {
    history.push(`${path}/create`);
  };

  const onEdit = async (id: string) => {
    history.push(`${path}/${id}`);
  };

  const buttonProps = {
    title: t('addReward'),
    onCreate: handleButton,
  };

  return (
    <div>
      <CustomTablePagination
        columns={columns}
        data={externalRewards}
        onEdit={onEdit}
        isLoading={loading}
        createButtonProps={buttonProps}
        fetchData={fetchExternalRewards}
      />
    </div>
  );
};
