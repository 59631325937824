import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CustomLoader, CustomSwitch, HelpIcon } from 'components';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import {
  createDailyTask,
  fetchDailyTask,
  updateDailyTask,
} from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { ExternalReward, IDailyTask, IGoal } from 'types';
import { isValidUrl } from 'utils';

type CreateParams = { id: string };

export const CreateRealLifeTask: React.FC = () => {
  const { dailyTasks, loading, error, externalRewards } = useSelector(createSessionSelector);
  const { goalsTypes } = useSelector(goalsSliceSelector);
  const { id } = useParams<CreateParams>();
  const selectedTask = dailyTasks.results.find((task) => task.id === parseInt(id));
  const { t } = useTranslation('createRealLifeTask');

  const isEditMode = Number.isInteger(parseInt(id));

  useEffect(() => {
    if (!selectedTask && isEditMode) dispatch(fetchDailyTask({ id: parseInt(id) }));
  }, [id]);

  const schema = yup
    .object({
      goal: yup.object().shape({ name: yup.string().required() }).required(),
      title: yup.string().required(),
      description: yup.string().required(),
      link: yup
        .string()
        .nullable()
        .when('isExternalLink', {
          is: (value: boolean) => value,
          then: (rule) =>
            rule.required().test('is-url-valid', t('validation:invalidUrl'), (value) => {
              return value ? isValidUrl(value) : false;
            }),
        }),
      externalReward: yup
        .object()
        .nullable()
        .when('isExternalReward', {
          is: (value: boolean) => value,
          then: (rule) => rule.shape({ name: yup.string().required() }).required(),
        }),
    })
    .required();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IDailyTask & { isExternalLink: boolean; isExternalReward: boolean }>({
    defaultValues: { id: undefined, title: '', description: '', goal: { id: undefined, name: '' } },
    resolver: yupResolver(schema),
  });

  const formValue = watch();

  useEffect(() => {
    if (isEditMode) {
      if (selectedTask) {
        const { id, title, description, goal, link, externalReward } = selectedTask;
        setValue('id', id);
        setValue('title', title);
        setValue('description', description);
        setValue('goal', goal);
        if (link) {
          setValue('link', link);
          setValue('isExternalLink', true);
        }
        if (externalReward) {
          setValue('externalReward', externalReward);
          setValue('isExternalReward', true);
        }
      }
    }
  }, [dailyTasks, id, isEditMode, selectedTask, setValue]);

  const onSubmit = handleSubmit((data: IDailyTask) => {
    const { goal, description, title, externalReward, link } = data;
    const newTask = {
      goal: goal.id,
      description,
      title,
      externalReward: externalReward ? externalReward.id : null,
      link: link ? link : null,
    };
    if (isEditMode) {
      dispatch(updateDailyTask({ id, task: newTask }));
    } else {
      dispatch(createDailyTask(newTask));
    }
  });

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, loading]);

  if (loading) return <CustomLoader />;

  if (error) return <ShowError error={error} />;
  if (isEditMode && !selectedTask) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" gutterBottom>
          {isEditMode ? t('editRealLifeTask') : t('createRealLifeTask')}
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel id="goal-select-label">{t('goal')}</InputLabel>
          <Controller
            control={control}
            name="goal"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const name = e.target.value;
                    const selectedGoal = goalsTypes.results.find((goal) => goal.name === name);
                    setValue('goal', selectedGoal as IGoal);
                  }}
                  MenuProps={{ disableScrollLock: true }}
                  defaultValue={value?.name}
                  value={value?.name}
                  labelId="goal-select-label"
                  id="goal-select"
                >
                  {goalsTypes.results.map((goal) => (
                    <MenuItem key={goal.id} value={goal.name}>
                      {goal.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>
            {errors.goal && t('goal') + t('validation:isRequired')}
          </FormHelperText>
        </FormControl>

        <TextField
          margin="normal"
          fullWidth
          label={t('title')}
          {...register('title')}
          helperText={errors.title?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />

        <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
          <InputLabel id="demo-simple-select-label">{t('description')}</InputLabel>
          <TextareaAutosize
            aria-label="description"
            minRows={4}
            {...register('description')}
            placeholder={t('description')}
            style={{
              width: '100%',
              fontSize: '16px',
              padding: '6px 20px 7px 20px',
              fontFamily: 'MarkPro',
            }}
          />
          <FormHelperText error>{errors.description?.message}</FormHelperText>
        </Box>

        <FormControl fullWidth margin="normal">
          <InputLabel id="goal-select-label">
            {t('externalLink')}
            <HelpIcon title={t('tooltip:externalLink')} style={{ position: 'relative', top: 5 }} />
          </InputLabel>
          <Box style={{ flex: 1, width: '100%', marginTop: 30 }}>
            <CustomSwitch
              checked={formValue.isExternalLink}
              inputRef={register('isExternalLink').ref}
              onChange={(_, checked) => {
                setValue('isExternalLink', checked);
                if (!checked) setValue('link', null);
              }}
            />
          </Box>
        </FormControl>

        {formValue.isExternalLink && (
          <TextField
            margin="normal"
            fullWidth
            label={t('link')}
            {...register('link')}
            helperText={errors.link?.message}
            InputProps={{
              endAdornment: formValue.link && (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => window.open(formValue.link!)}
                  >
                    <Typography variant="body1">{t('preview')}</Typography>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}

        <FormControl fullWidth margin="normal">
          <InputLabel id="goal-select-label">
            {t('grantReward')}
            <HelpIcon
              title={t('tooltip:realLifeReward')}
              style={{ position: 'relative', top: 5 }}
            />
          </InputLabel>
          <Box style={{ flex: 1, width: '100%', marginTop: 30 }}>
            <CustomSwitch
              checked={formValue.isExternalReward}
              inputRef={register('isExternalReward').ref}
              onChange={(_, checked) => {
                setValue('isExternalReward', checked);
                if (!checked) setValue('externalReward', null);
              }}
            />
          </Box>
        </FormControl>

        {formValue.isExternalReward && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="reward-select-label">{t('reward')}</InputLabel>
            <Controller
              control={control}
              name="externalReward"
              render={({ field: { value } }) => {
                return (
                  <Select
                    onChange={(e) => {
                      const name = e.target.value;
                      const selectedReward = externalRewards.results.find(
                        (reward) => reward.name === name,
                      );
                      setValue('externalReward', selectedReward as ExternalReward);
                    }}
                    MenuProps={{ disableScrollLock: true }}
                    defaultValue={value?.name}
                    value={value?.name}
                    labelId="goal-select-label"
                    id="goal-select"
                  >
                    {externalRewards.results.map((reward) => (
                      <MenuItem key={reward.id} value={reward.name}>
                        {reward.name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
            <FormHelperText error>
              {errors.externalReward && t('reward') + t('validation:isRequired')}
            </FormHelperText>
          </FormControl>
        )}

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
            {t(isEditMode ? 'updateRealLifeTask' : 'createRealLifeTask')}
          </Button>
        </Box>
      </form>
    </div>
  );
};
