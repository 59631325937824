import React from 'react';

import { Navbar } from 'components';

export const MainWrapper: React.FC = ({ children }) => {
  return (
    <div>
      <Navbar />
      {children}
    </div>
  );
};
