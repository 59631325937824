import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { FONT_MEDIUM, FONT_SIZE_PRIMARY } from 'theme/typography';

const StyledChip = withStyles({
  root: {
    margin: 5,
  },
  label: {
    textTransform: 'capitalize',
    fontSize: FONT_SIZE_PRIMARY,
    fontFamily: FONT_MEDIUM,
  },
})(Chip);

type Props = {
  label: string;
};

export const Tag: React.FC<Props> = (props, { label }) => {
  return <StyledChip {...props} {...label} />;
};
