import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import PestControlIcon from '@mui/icons-material/PestControl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';

import { clearState } from 'redux/userAuth/userAuthSlice';

type Props = {
  error: string;
  onPress?: () => void;
};

export const ShowError: React.FC<Props> = ({ error, onPress }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('errors');

  const handleLogout = () => {
    localStorage.removeItem('token');
    storage.removeItem('persist:root');
    dispatch(clearState());
    history.push('/login');
    history.go(0);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      style={{ backgroundColor: '#Ececec' }}
    >
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: 20,
          borderRadius: 50,
        }}
      >
        <PestControlIcon sx={{ fontSize: 240 }} />
        <h1>{t('somethingWrong')}</h1>
        <h2>{error}</h2>
        <Box
          style={{
            padding: 10,
          }}
        >
          {onPress && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '20px', marginRight: 20 }}
              onClick={onPress}
            >
              {t('tryAgain')}
            </Button>
          )}
          <Button variant="outlined" style={{ marginTop: '20px' }} onClick={handleLogout}>
            {t('common:logout')}
          </Button>
        </Box>
      </div>
    </Box>
  );
};
