import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  createStyles,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Autocomplete, Box, CircularProgress, FormControl } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GoPrimitiveDot } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { AlertDialog, CustomLoader } from 'components';
import { CustomAlert } from 'components/CustomAlert';
import { ErrorAlert } from 'components/ErrorBoundary';
import { ERROR_CANT_FIND_VIDEO } from 'constants/errors';
import {
  createSession,
  fetchSessionsTemplates,
  getVideoStatus,
  updateSession,
} from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { AppDispatch } from 'redux/store';
import {
  ConvertedSession,
  ConvertedVideo,
  IDailyTask,
  IGame,
  IGoal,
  IPill,
  ITest,
  Nugget,
} from 'types';
import { getVimeoStatus } from 'utils/helperFunctions';

type InitialState = {
  test: ITest;
  textAfterTest: string;
  textBeforeAudio: string;
  pill: ConvertedVideo;
  textAfterAudio: string;
  game: IGame | null;
  nugget: Nugget;
  textBeforeTask: string;
  task: IDailyTask;
  goal: IGoal;
  wrapUp1: string;
  wrapUp2: string;
  wrapUp3: string;
  tasksOrder: string;
  showModal: boolean;
  showWarning: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapUp: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const CreateSession: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEditMode = Number.isInteger(parseInt(id));
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation(['createSession', 'admin']);
  const { games, tests, dailyTasks, nuggets, pills, sessions, loading, error } =
    useSelector(createSessionSelector);
  const { goalsTypes } = useSelector(goalsSliceSelector);
  const selectedSession = sessions.results.find((session) => session.id === parseInt(id));

  useEffect(() => {
    if (!selectedSession && isEditMode) dispatch(fetchSessionsTemplates({ id: parseInt(id) }));
  }, [id]);

  const schema = yup
    .object({
      test: yup.object().shape({ name: yup.string().required() }).required(),
      pill: yup.object().shape({ name: yup.string().required(), id: yup.string() }).required(),
      nugget: yup.object().shape({ description: yup.string().required() }).required(),
      task: yup.object().shape({ title: yup.string().required() }).required(),
      goal: yup.object().shape({ name: yup.string().required() }).required(),
      textAfterTest: yup.string().required(),
      textBeforeAudio: yup.string().required(),
      textAfterAudio: yup.string().required(),
      textBeforeTask: yup.string().required(),
      wrapUp1: yup.string().required(),
      wrapUp2: yup.string().required(),
      wrapUp3: yup.string().required(),
    })
    .required();

  const {
    setValue,
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors, isSubmitSuccessful, isDirty },
  } = useForm<InitialState>({
    defaultValues: {
      test: { id: 0, name: '' },
      pill: { id: undefined, name: '' },
      game: { id: undefined, name: '' },
      nugget: { id: 0, description: '' },
      task: { id: 0, title: '' },
      goal: { id: 0, name: '' },
      tasksOrder: 'SAVGR',
    },
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEditMode) {
      if (selectedSession) {
        const {
          test,
          pill,
          game,
          nugget,
          task,
          goal,
          textAfterTest,
          textBeforeAudio,
          textAfterAudio,
          textBeforeTask,
          textAfterTask,
        } = selectedSession;
        setValue('test', test);
        setValue('pill', pill);
        setValue('game', game);
        setValue('nugget', nugget);
        setValue('task', task);
        setValue('goal', goal);
        setValue('textAfterTest', textAfterTest);
        setValue('textBeforeAudio', textBeforeAudio);
        setValue('textAfterAudio', textAfterAudio);
        setValue('textBeforeTask', textBeforeTask);
        setValue('wrapUp1', textAfterTask?.[0]);
        setValue('wrapUp2', textAfterTask?.[1]);
        setValue('wrapUp3', textAfterTask?.[2]);
      }
    }
  }, [id, isEditMode, selectedSession, setValue]);

  const formattedNuggets = nuggets.results.map((el) => ({
    label: el.description,
    id: el.id,
    goalId: el.goal.id,
  }));
  const formattedDailyTask = dailyTasks.results.map((el) => ({
    label: el.title,
    id: el.id,
    goalId: el.goal.id,
  }));
  const formValue = watch();

  const isGoalChoosed = !!formValue.goal.name;

  useEffect(() => {
    const showWarning =
      !!errors.textBeforeTask ||
      !!errors.textBeforeAudio ||
      !!errors.textAfterAudio ||
      !!errors.textAfterTest;

    showWarning ? setValue('showWarning', true) : setValue('showWarning', false);
  }, [errors.textBeforeTask, errors.textBeforeAudio, errors.textAfterAudio, errors.textAfterTest]);

  useEffect(() => {
    const validatePill = async () => {
      const { pill } = formValue;
      if (pill.id) {
        const data = await dispatch(getVideoStatus({ id: pill.videoId })).unwrap();
        if (data.status === 'available') return true;
        return setError('pill', { type: 'custom', message: getVimeoStatus(data.status) });
      }
      return false;
    };

    validatePill();
  }, [formValue.pill]);

  const handleSession = (data: InitialState) => {
    const {
      test,
      pill,
      game,
      nugget,
      task,
      goal,
      textAfterTest,
      textBeforeAudio,
      textAfterAudio,
      textBeforeTask,
      wrapUp1,
      wrapUp2,
      wrapUp3,
    } = data;
    const newSession: ConvertedSession = {
      test: test?.id,
      pill: pill?.id,
      game: game ? game.id : null,
      nugget: nugget?.id,
      task: task?.id,
      goal: goal?.id,
      tasksOrder: game ? 'SAVGR' : 'SAVR',
      textAfterTest,
      textBeforeAudio,
      textAfterAudio,
      textBeforeTask,
      textAfterTask: [wrapUp1, wrapUp2, wrapUp3],
    };
    clearErrors('textAfterTest');
    clearErrors('textAfterAudio');
    clearErrors('textBeforeAudio');
    clearErrors('wrapUp1');
    clearErrors('wrapUp2');
    setValue('showWarning', false);
    setValue('showModal', false);
    if (isEditMode) {
      dispatch(updateSession({ id, newSession: newSession }));
    } else {
      dispatch(createSession(newSession));
    }
  };

  useEffect(() => {
    const { textAfterTest } = formValue;
    if (textAfterTest && isDirty) setValue('wrapUp1', textAfterTest, { shouldValidate: true });
  }, [formValue.textAfterTest]);

  useEffect(() => {
    const { textAfterAudio } = formValue;
    if (textAfterAudio && isDirty) setValue('wrapUp2', textAfterAudio, { shouldValidate: true });
  }, [formValue.textAfterAudio]);

  const onSubmit = handleSubmit((data: InitialState) => {
    handleSession(data);
  });

  useEffect(() => {
    if (isSubmitSuccessful && !loading && !error && !formValue.showWarning) {
      history.goBack();
    }
  }, [error, formValue.showWarning, history, isSubmitSuccessful, loading]);

  if (loading) return <CustomLoader />;

  if (isEditMode && !selectedSession) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      {error && (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CustomAlert description={error} severity="error" />
        </Grid>
      )}
      <form onSubmit={onSubmit}>
        <AlertDialog
          title={t('validation:areYouSure') + '?'}
          description={`${t('validation:considerChange')}
           ${errors.textAfterAudio || errors.textBeforeAudio ? t('createSession:pill') : ''} 
           ${errors.textBeforeTask ? t('createSession:dailyTask') : ''} 
             ${errors.textAfterTest ? t('createSession:test') : ''}`}
          onSubmit={() => handleSession(formValue)}
          showModal={formValue.showModal}
          onClose={() => setValue('showModal', false)}
        />

        <Typography variant="h2" gutterBottom>
          {isEditMode ? t('editSession') : t('createSession')}
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="goal-select-label">{t('goal')}</InputLabel>
          <Controller
            control={control}
            name="goal"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const id = e.target.value;
                    const selectedGoal = goalsTypes.results.find((goal) => goal.name === id);
                    setValue('goal', selectedGoal as IGoal, { shouldValidate: true });
                  }}
                  defaultValue={value?.name}
                  value={value?.name}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="goal-select-label"
                  id="goal-select"
                >
                  {goalsTypes.results.map((goal) => (
                    <MenuItem key={goal.id} value={goal.name}>
                      {goal.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>{errors.goal && t('goal') + t('isRequired')}</FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="test-select-label">{t('test')}</InputLabel>
          <Controller
            control={control}
            name="test"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const id = e.target.value;
                    const selectedTest = tests.results.find((test) => test.name === id);
                    setValue('test', selectedTest as ITest, { shouldValidate: true });
                    if (isEditMode) {
                      setError('textAfterTest', {
                        message: t('validation:considerChange') + 'the' + t('createSession:test'),
                      });
                      setError('wrapUp1', {
                        message: t('validation:considerChange') + 'the' + t('createSession:test'),
                      });
                    }
                  }}
                  defaultValue={value?.name}
                  value={value?.name}
                  disabled={!isGoalChoosed}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="test-select-label"
                  id="test-select"
                >
                  {tests.results.map((test) => (
                    <MenuItem key={test.id} value={test.name} disabled={test.isEmpty}>
                      {test.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>{errors.test && t('test') + t('isRequired')}</FormHelperText>
        </FormControl>

        <TextField
          margin="normal"
          fullWidth
          label={t('textAfterTest')}
          {...register('textAfterTest')}
          helperText={errors.textAfterTest?.message}
          error={!!errors.textAfterTest}
          onChangeCapture={() => {
            clearErrors('textAfterTest');
          }}
          disabled={!isGoalChoosed}
        />

        <TextField
          margin="normal"
          fullWidth
          label={t('textBeforeAudio')}
          {...register('textBeforeAudio')}
          helperText={errors.textBeforeAudio?.message}
          error={!!errors.textBeforeAudio}
          onChangeCapture={() => {
            clearErrors('textBeforeAudio');
          }}
          disabled={!isGoalChoosed}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="pill-select-label">{t('pill')}</InputLabel>
          <Controller
            control={control}
            name="pill"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const id = e.target.value;
                    const selectedPill = pills.results.find((pill) => pill.name === id);
                    setValue('pill', selectedPill as IPill, { shouldValidate: true });
                    if (isEditMode) {
                      setError('textBeforeAudio', {
                        message: t('validation:considerChange') + t('createSession:pill'),
                      });
                      setError('textAfterAudio', {
                        message: t('validation:considerChange') + t('createSession:pill'),
                      });
                      setError('wrapUp2', {
                        message: t('validation:considerChange') + t('createSession:pill'),
                      });
                    }
                  }}
                  value={value.name}
                  MenuProps={{ disableScrollLock: true }}
                  disabled={!isGoalChoosed}
                  labelId="pill-select-label"
                  id="pill-select"
                >
                  {pills.results.map((pill) => (
                    <MenuItem key={pill.id} value={pill.name}>
                      {pill.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>
            {errors.pill && (errors.pill as any).message}
            {error === ERROR_CANT_FIND_VIDEO && ERROR_CANT_FIND_VIDEO}
          </FormHelperText>
        </FormControl>

        <TextField
          margin="normal"
          fullWidth
          label={t('textAfterAudio')}
          {...register('textAfterAudio')}
          helperText={errors.textAfterAudio?.message}
          error={!!errors.textAfterAudio}
          onChangeCapture={() => {
            clearErrors('textAfterAudio');
          }}
          disabled={!isGoalChoosed}
        />

        <FormControl fullWidth margin="normal">
          <Controller
            control={control}
            name="nugget"
            render={({ field: { value } }) => {
              return (
                <Autocomplete
                  id="nugget-autocomplete"
                  ListboxProps={{ style: { fontSize: '1.6rem' } }}
                  options={
                    isGoalChoosed
                      ? formattedNuggets.filter((x) => x.goalId === formValue.goal.id)
                      : formattedNuggets
                  }
                  value={value && { label: value.description, id: value.id, goalId: 0 }}
                  disabled={!isGoalChoosed}
                  onChange={(event, newValue) => {
                    const selectedNugget = nuggets.results.find(
                      (nugget) => nugget.id === newValue?.id,
                    );
                    setValue('nugget', selectedNugget as Nugget, { shouldValidate: true });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('tip')}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  fullWidth
                  loading={loading}
                />
              );
            }}
          />
          <FormHelperText error>{errors.nugget && t('tip') + t('isRequired')}</FormHelperText>
        </FormControl>

        <TextField
          margin="normal"
          fullWidth
          label={t('textBeforeTask')}
          {...register('textBeforeTask')}
          helperText={errors.textBeforeTask?.message}
          error={!!errors.textBeforeTask}
          onChangeCapture={() => {
            clearErrors('textBeforeTask');
          }}
          disabled={!isGoalChoosed}
        />

        <FormControl fullWidth margin="normal">
          <Controller
            control={control}
            name="task"
            render={({ field: { value } }) => {
              return (
                <Autocomplete
                  id="task-autocomplete"
                  options={
                    isGoalChoosed
                      ? formattedDailyTask.filter((x) => x.goalId === formValue.goal.id)
                      : formattedDailyTask
                  }
                  ListboxProps={{ style: { fontSize: '1.6rem' } }}
                  value={value && { label: value.title, id: value.id, goalId: 0 }}
                  disabled={!isGoalChoosed}
                  onChange={(event, newValue) => {
                    const selectedTask = dailyTasks.results.find(
                      (task) => task.id === newValue?.id,
                    );
                    setValue('task', selectedTask as IDailyTask, { shouldValidate: true });
                    isEditMode &&
                      setError('textBeforeTask', {
                        message: t('validation:considerChange') + t('dailyTask'),
                      });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('dailyTask')}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  fullWidth
                  loading={loading}
                />
              );
            }}
          />
          <FormHelperText error>{errors.task && t('dailyTask') + t('isRequired')}</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="wrap-up-select-label">{t('WrapUpScreen')}</InputLabel>

          <Box className={classes.wrapUp}>
            <GoPrimitiveDot />
            <TextField
              margin="normal"
              fullWidth
              defaultValue={!isEditMode && formValue.textAfterTest}
              {...register('wrapUp1')}
              helperText={errors.wrapUp1?.message}
              disabled={!isGoalChoosed}
              error={!!errors.wrapUp1}
            />
          </Box>

          <Box className={classes.wrapUp}>
            <GoPrimitiveDot />
            <TextField
              margin="normal"
              fullWidth
              defaultValue={formValue.textAfterAudio}
              {...register('wrapUp2')}
              helperText={errors.wrapUp2?.message}
              disabled={!isGoalChoosed}
              error={!!errors.wrapUp2}
            />
          </Box>

          <Box className={classes.wrapUp}>
            <GoPrimitiveDot />
            <TextField
              margin="normal"
              fullWidth
              {...register('wrapUp3')}
              helperText={errors.wrapUp3?.message}
              disabled={!isGoalChoosed}
              error={!!errors.wrapUp3}
            />
          </Box>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="game-select-label">{t('game')}</InputLabel>
          <Controller
            control={control}
            name="game"
            render={({ field: { value } }) => {
              return (
                <Select
                  onChange={(e) => {
                    const id = e.target.value;
                    const selectedGame = games.results.find((g) => g.name === id);
                    setValue('game', selectedGame as IGame, { shouldValidate: true });
                  }}
                  value={value?.name}
                  MenuProps={{ disableScrollLock: true }}
                  disabled={!isGoalChoosed}
                  labelId="game-select-label"
                  id="game-select"
                >
                  <MenuItem key={null} value={''}>
                    {t('noGame')}
                  </MenuItem>
                  {games.results.map((g) => (
                    <MenuItem key={g.id} value={g.name}>
                      {g.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText error>{errors.game && t('game') + t('isRequired')}</FormHelperText>
        </FormControl>

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type={formValue.showWarning ? 'button' : 'submit'}
            style={{ marginLeft: '20px' }}
            onClick={
              formValue.showWarning && isEditMode ? () => setValue('showModal', true) : undefined
            }
          >
            {t(isEditMode ? 'updateSession' : 'createSession')}
          </Button>
        </Box>
      </form>
    </div>
  );
};
