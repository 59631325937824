/* eslint-disable prefer-const */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CustomLoader } from 'components';
import { ErrorAlert, ShowError } from 'components/ErrorBoundary';
import { GAME_TEMPLATE_SETTINGS } from 'constants/mock';
import {
  createQuestionAndAnswers,
  fetchTestQuestions,
  fetchTests,
  updateQuestionAndAnswers,
} from 'redux/createSession/createSessionAction';
import { createSessionSelector } from 'redux/createSession/createSessionSlice';
import { goalsSliceSelector } from 'redux/goals/goalsSlice';
import { AppDispatch } from 'redux/store';
import { Question, QuestionAnswers } from 'types';
import { getScoringType } from 'utils/helperFunctions';

export const CreateQuestion: React.FC = () => {
  const { goalsTypes } = useSelector(goalsSliceSelector);
  const { tests, questions, loading, error } = useSelector(createSessionSelector);
  const { questionId, testId } = useParams<{ questionId: string; testId: string }>();
  const { t } = useTranslation('createQuestion');
  const history = useHistory();
  const isEditMode = Number.isInteger(parseInt(questionId));
  const selectedTest = tests.results.find((x) => x.id === parseInt(testId));
  const selectedQuestion = questions.results.find((x) => x.id === parseInt(questionId));
  const numberOfAnswersList = GAME_TEMPLATE_SETTINGS.find(
    (x) => x.name === selectedTest?.gameTemplate,
  )?.answers;
  const maxLength = GAME_TEMPLATE_SETTINGS.find(
    (x) => x.name === selectedTest?.gameTemplate,
  )?.maxLength;
  const minPoints = 0;
  const maxPoints = 100;

  const isPercentage = selectedTest?.testType === 'PERCENTAGE';
  const answersProperty = selectedTest?.testType === 'MEAN' ? 'meaning' : 'weight';

  useEffect(() => {
    if (!selectedTest && isEditMode) dispatch(fetchTests({ id: parseInt(testId) }));
  }, [testId]);

  const schema = yup
    .object({
      question: yup.string().required(),
      showWeight: yup.boolean(),
      explanation: yup.string().when('showExplanation', {
        is: true,
        then: yup.string().required(),
      }),
      numberOfAnswers: yup
        .number()
        .required()
        .min(1)
        .test('valid', `${t('numberOfAnswers')} ${t('validation:mustBeCorrect')}`, (val) => {
          return !!(val && numberOfAnswersList && numberOfAnswersList.some((x) => val === x));
        }),
      answers: yup.array().of(
        yup.object().shape({
          answer: yup
            .string()
            .test('len', `${t('validation:answerLengthLess')} ${maxLength}`, (val) => {
              return !!(val && maxLength && val.length <= maxLength);
            })
            .required('Answer is required'),
          weight: yup
            .number()
            .min(minPoints, `${t('weight')} ${t('validation:greaterThenOrEqual')} ${minPoints}`)
            .max(maxPoints, `${t('weight')} ${t('validation:lessThenOrEqual')} ${maxPoints}`)
            .typeError(`${t('validation:specifyNumber')}`)
            .when('showWeight', {
              is: true,
              then: yup.number().required(`${t('validation:isRequired')}`),
            }),
        }),
      ),
    })
    .required();

  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<
    Question & { showWeight: boolean; numberOfAnswers: number; showExplanation: boolean }
  >({
    defaultValues: { id: 0, question: '', answers: [], showWeight: true, numberOfAnswers: 0 },
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  });

  const formValue = watch();

  useEffect(() => {
    setValue('showWeight', answersProperty === 'weight');
    setValue('showExplanation', isPercentage);
    if (isEditMode) {
      if (selectedQuestion) {
        const { id, question, answers, explanation } = selectedQuestion;
        setValue('id', id);
        setValue('question', question, { shouldValidate: true });
        setValue('answers', answers, { shouldValidate: true });
        setValue('numberOfAnswers', answers.length);
        isPercentage && setValue('explanation', explanation);
      }
    }
  }, [
    goalsTypes,
    questionId,
    isEditMode,
    questions,
    setValue,
    tests,
    selectedQuestion,
    answersProperty,
  ]);

  useEffect(() => {
    const type = getScoringType(selectedTest);
    if (questions.results.length === 0 && selectedTest && isEditMode)
      dispatch(fetchTestQuestions({ type, id: parseInt(testId) }));
  }, [dispatch, questions.results.length, selectedTest, testId, tests]);

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      history.goBack();
    }
  }, [history, isSubmitSuccessful, loading]);

  const onSubmit = handleSubmit(async (data: Question) => {
    const testType = getScoringType(selectedTest);
    if (isEditMode) {
      const answersWithMeaning = {
        ...data,
        answers: formValue.answers.map((x: QuestionAnswers) => {
          return { ...x, meaning: x.answer };
        }),
      };

      await dispatch(
        updateQuestionAndAnswers({
          test: parseInt(testId),
          data: testType === 'mean' ? answersWithMeaning : data,
          type: testType,
        }),
      );
    } else {
      const answersWithMeaning = {
        ...data,
        answers: data.answers.map((x) => {
          return { ...x, meaning: x.answer };
        }),
      };
      await dispatch(
        createQuestionAndAnswers({
          test: parseInt(testId),
          data: testType === 'mean' ? answersWithMeaning : data,
          type: testType,
        }),
      );
    }
  });

  const showAnswers = () => {
    return fields.map((field, index) => (
      <>
        <TextField
          key={field.id}
          margin="normal"
          fullWidth
          inputProps={{ maxlength: (maxLength as number) + 1 }}
          label={`${t('answer')} ${index + 1}`}
          {...register(`answers.${index}.answer`)}
          helperText={errors.answers?.[index]?.answer?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />
        {answersProperty === 'weight' && (
          <TextField
            key={field.id + index}
            type="number"
            margin="normal"
            fullWidth
            label={`${t('weight')} ${index + 1}`}
            {...register(`answers.${index}.weight`, {
              valueAsNumber: true,
            })}
            helperText={errors.answers?.[index]?.weight?.message}
            FormHelperTextProps={{ style: { color: 'red' } }}
          />
        )}
      </>
    ));
  };

  if (loading) return <CustomLoader />;

  if (error) return <ShowError error={error} />;
  if (isEditMode && !selectedQuestion) return <ErrorAlert error={t('errors:couldntFindId')} />;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" gutterBottom>
          {isEditMode ? t('editQuestion') : t('createQuestion')}
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="Question"
          {...register('question')}
          helperText={errors.question?.message}
          FormHelperTextProps={{ style: { color: 'red' } }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="numberOfAnswers-label">{t('numberOfAnswers')}</InputLabel>
          <Controller
            control={control}
            name="numberOfAnswers"
            render={({ field: { value } }) => {
              return (
                <Select
                  labelId="numberOfAnswers"
                  id="numberOfAnswers"
                  label="numberOfAnswers"
                  value={value}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => {
                    const number = e.target.value as number;
                    setValue('numberOfAnswers', number);
                    const difference = fields.length - number;
                    if (difference < 0) {
                      for (let i = 0; i < Math.abs(difference); i++) {
                        append({ answer: '' });
                      }
                    } else if (difference > 0) {
                      for (let i = fields.length; i > number; i--) {
                        remove(i - 1);
                      }
                    }
                  }}
                >
                  {numberOfAnswersList?.map((number) => {
                    return (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            }}
          />
          <FormHelperText error>{errors.numberOfAnswers?.message}</FormHelperText>
        </FormControl>

        {showAnswers()}

        {isPercentage && (
          <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
            <InputLabel id="explanation-label">{t('explanation')}</InputLabel>
            <TextareaAutosize
              aria-label="explanation"
              minRows={4}
              {...register('explanation')}
              placeholder={t('explanation')}
              style={{
                width: '100%',
                fontSize: '16px',
                padding: '6px 20px 7px 20px',
                fontFamily: 'MarkPro',
              }}
            />
            <FormHelperText error>{errors.explanation?.message}</FormHelperText>
          </Box>
        )}

        <Box sx={{ marginTop: '32px' }}>
          <Button variant="outlined" type="button" onClick={history.goBack}>
            {t('admin:goBack')}
          </Button>
          <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
            {t(isEditMode ? 'updateQuestion' : 'createQuestion')}
          </Button>
        </Box>
      </form>
    </div>
  );
};
