import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userGoalsSelector } from 'redux/usersGoals/userGoalsSlice';

const useStyles = makeStyles({
  wrapper: {
    gridColumn: '2 / -1',
    gridRow: '4 /5',
  },
  styledCard: {
    display: 'flex',
  },
  grid: {
    display: 'flex',
    gap: '90px',
  },
  cardActive: {
    width: '300px',
    margin: '20px',
  },
  cardInActive: {
    width: '300px',
    margin: '20px',
    backgroundColor: '#e1e1e1',
  },
  flex: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const CurrentGoalsCard: React.FC = () => {
  const { userGoals, errorMessage } = useSelector(userGoalsSelector);
  const { t } = useTranslation('goalsCard');
  const classes = useStyles();

  const numberOfGoals = userGoals.length;

  if (errorMessage) {
    return (
      <div>
        <Alert severity="error">{errorMessage}</Alert>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Card>
        <CardHeader title={t('title')} />
        <CardContent className={classes.grid}>
          {/* //TODO: Add data to display inactive goals*/}

          <div>
            {userGoals.map((el) => (
              <Card
                key={el.id}
                className={el.status === 'ACTIVE' ? classes.cardActive : classes.cardInActive}
              >
                {/* <CardContent>{el.goal.name}</CardContent> */}
              </Card>
            ))}
          </div>

          <div className={classes.flex}>
            <div>
              <Typography variant="subtitle1">{t('completedGoals')}</Typography>
              <Typography variant="h1">{numberOfGoals}</Typography>
            </div>
            <div>
              <Typography variant="subtitle1">{t('allGoals')}</Typography>
              <Typography variant="h1">8</Typography>
            </div>
            <div>
              <Typography variant="subtitle1">{t('changedGoals')}</Typography>
              <Typography variant="h1">0</Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
