import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

import { WIDGET_MIN_HEIGHT } from '../constants';

interface ITotalSummaryWidget {
  dataLength: number | string;
  placeholder: string;
  icon?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minHeight: WIDGET_MIN_HEIGHT,
      borderRadius: '10px',
      background: theme.palette.common.white,
      maxHeight: '400px',
      '@media print': {
        float: 'left',
        pageBreakInside: 'avoid',
        margin: 18,
        marginBottom: 100,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    title: {
      fontSize: 14,
      color: theme.palette.common.black,
    },
    text: {
      fontSize: 39,
      fontWeight: 700,
      color: theme.palette.common.black,
      flex: 1,
      textTransform: 'capitalize',
    },
    footerWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export const TotalSummaryWidget: React.FC<ITotalSummaryWidget> = ({
  dataLength = 0,
  placeholder,
  icon,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.wrapper}>
      <CardHeader title={placeholder} />
      <CardContent className={classes.content}>
        <div className={classes.footerWrapper}>
          <Typography variant="h1" className={classes.text} component="p">
            {dataLength}
          </Typography>
          {icon}
        </div>
      </CardContent>
    </Card>
  );
};
